import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap, mergeMap } from 'rxjs/operators';
import { StorageService } from '@shared/services/storage/storage.service';
import { AlertService } from '@shared/services/alert/alert.service';
import { Router } from '@angular/router';
import { HomeActions } from './home.actions';
import { HomeFacade } from './home.facade';
import { HomeService } from '@shared/services/home/home.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HomeEffects {

  readonly homeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.homeRequest),
      tap(() => this.homeFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const homeData = await lastValueFrom(
            this.homeService.homeRequest()
          );
          if(homeData.status === 'success'){
            return HomeActions.homeRequestSuccess({ homeData });
          }else{
            // const dataMessage = {
            //   header:homeData.status,
            //   message:homeData.status,
            // };
            // this.alertService.presentToast(dataMessage);
            return HomeActions.homeRequestFail();
          }
        } catch (e) {
          console.error(e);
          // const dataMessage = {
          //   header: e.error.message,
          //   subHeader:`Error ${e.status}`,
          //   message: 'Ha ocurrido un error, y no podemos conectarnos con el servidor.'
          // };
          // this.alertService.presentAlert(dataMessage);
          return HomeActions.homeRequestFail();
        }
      }),
      tap(() => this.homeFacade.setLoading(false))
    )
  );

  readonly myContactsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.myContactsRequest),
      tap(() => this.homeFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const myContactList = await lastValueFrom(
            this.homeService.myContactsRequest()
          );
          if(myContactList.status === 'success'){
            return HomeActions.myContactsRequestSuccess({ myContactList });
          }else{
            return HomeActions.myContactsRequestFail();
          }
        } catch (e) {
          console.error(e);
          return HomeActions.myContactsRequestFail();
        }
      }),
      tap(() => this.homeFacade.setLoading(false))
    )
  );

  readonly getNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.getNotifications),
      tap(() => this.homeFacade.setLoadingNotifications(true)),
      switchMap(async () => {
        try {
          const notificationsList = await lastValueFrom(
            this.homeService.getNotifications()
          );
          if(notificationsList.status === 'success'){
            return HomeActions.getNotificationsSuccess({ notificationsList });
          }else{
            return HomeActions.getNotificationsFail();
          }
        } catch (e) {
          console.error(e);
          return HomeActions.getNotificationsFail();
        }
      }),
      tap(() => this.homeFacade.setLoadingNotifications(false))
    )
  );
  readonly deleteNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(HomeActions.deleteNotification),
      tap(() => this.homeFacade.setLoadingNotifications(true)),
      switchMap(async ({idNotification}) => {
        try {
          const deleteNotification = await lastValueFrom(
            this.homeService.deleteNotification(idNotification)
          );
          if(deleteNotification.status === 'success'){
            // this.homeFacade.getNotifications();
            return HomeActions.deleteNotificationSuccess({ deleteNotification });
          }else{
            return HomeActions.deleteNotificationFail();
          }
        } catch (e) {
          console.error(e);
          return HomeActions.deleteNotificationFail();
        }
      }),
      tap(() => this.homeFacade.setLoadingNotifications(false))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly homeFacade: HomeFacade,
    private readonly homeService: HomeService,
    private readonly storageService: StorageService,
    private readonly alertService: AlertService,
    private readonly router: Router,
  ) {}
}

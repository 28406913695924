/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import {
  CurrentProcessList,
  IResponsePropertyProcess,
  PropertiesDetailResponse,
  PropertiesList,
  PropertyFAQResponse,
  PropertyItem,
  ResponseDeliveryHome,
  ResponseFAQDelivery,
  ResponseGuideDelivery,
  ResponseLearnMoreDelivery,
  ResponseLinksDelivery,
  ResponseLocationDelivery,
  ResponseStepsDelivery,
  ResultCurrentPropertyOne,
  ResultCurrentPropertyTwo,
  SelectedProperty } from '@shared/models/properties';
import { PropertiesActions } from './properties.actions';


export interface PropertiesState {
  loading: boolean;
  propertiesList: PropertiesList| null;
  propertiesDetail: PropertiesDetailResponse | null;
  currentProcessList: IResponsePropertyProcess| null;
  resultCurrentPropertyOne: ResultCurrentPropertyOne| null;
  resultCurrentPropertyTwo: ResultCurrentPropertyTwo| null;
  selectedProperty: SelectedProperty | null;
  deliveryHomeResponse: ResponseDeliveryHome | null;
  locationDeliveryResponse: ResponseLocationDelivery | null;
  guideDeliveryResponse: ResponseGuideDelivery | null;
  stepsDeliveryResponse: ResponseStepsDelivery | null;
  learnMoreDeliveryResponse: ResponseLearnMoreDelivery | null;
  faqDeliveryResponse: ResponseFAQDelivery | null;
  linkInterestResponse: ResponseLinksDelivery | null;
  propertyfaqResponse: PropertyFAQResponse | null;



}

const initialState: PropertiesState = {
  loading: false,
  propertiesList:null,
  propertiesDetail:null,
  currentProcessList:null,
  resultCurrentPropertyOne:null,
  resultCurrentPropertyTwo:null,
  selectedProperty:null,
  deliveryHomeResponse:null,
  locationDeliveryResponse:null,
  guideDeliveryResponse:null,
  stepsDeliveryResponse:null,
  learnMoreDeliveryResponse:null,
  faqDeliveryResponse: null,
  linkInterestResponse: null,
  propertyfaqResponse: null

};

export const PropertiesReducer = createReducer(
  initialState,
  on(PropertiesActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(PropertiesActions.getPropertiesSuccess, (state, { propertiesList }) => ({
    ...state,
    propertiesList
  })),
  on(PropertiesActions.getDetailPropertiesSuccess, (state, { propertiesDetail }) => ({
    ...state,
    propertiesDetail
  })),
  on(PropertiesActions.getCurrentProcessSuccess, (state, { currentProcessList }) => ({
    ...state,
    currentProcessList
  })),
  on(PropertiesActions.getCurrentPropertyOneSuccess, (state, { resultCurrentPropertyOne }) => ({
    ...state,
    resultCurrentPropertyOne
  })),
  on(PropertiesActions.getCurrentPropertyTwoSuccess, (state, { resultCurrentPropertyTwo }) => ({
    ...state,
    resultCurrentPropertyTwo
  })),
  on(PropertiesActions.setupSelectedProperty, (state, { selectedProperty }) => ({
    ...state,
    selectedProperty
  })),
  on(PropertiesActions.cleanPropertyData, () => ({
    ...initialState,
  })),
  on(PropertiesActions.getHomeDeliverySuccess, (state, { deliveryHomeResponse }) => ({
    ...state,
    deliveryHomeResponse
  })),
  on(PropertiesActions.getLocationDeliverySuccess, (state, { locationDeliveryResponse }) => ({
    ...state,
    locationDeliveryResponse
  })),
  on(PropertiesActions.getGuideDeliverySuccess, (state, { guideDeliveryResponse }) => ({
    ...state,
    guideDeliveryResponse
  })),
  on(PropertiesActions.getStepsDeliverySuccess, (state, { stepsDeliveryResponse }) => ({
    ...state,
    stepsDeliveryResponse
  })),
  on(PropertiesActions.getLearnMoreDeliverySuccess, (state, { learnMoreDeliveryResponse }) => ({
    ...state,
    learnMoreDeliveryResponse
  })),
  on(PropertiesActions.getFaqDeliverySuccess, (state, { faqDeliveryResponse }) => ({
    ...state,
    faqDeliveryResponse
  })),
  on(PropertiesActions.getLinksInterestSuccess, (state, { linkInterestResponse }) => ({
    ...state,
    linkInterestResponse
  })),
  on(PropertiesActions.getPropertyFaqRequestSuccess, (state, { propertyfaqResponse }) => ({
    ...state,
    propertyfaqResponse
  })),
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  TUTORIAL } from '@shared/static/store.static';
import { TutorialState } from './tutorial.reducer';


export const selectTutorial = createFeatureSelector<TutorialState>(TUTORIAL);

export const loading = createSelector(
  selectTutorial,
  (state: TutorialState) => state.loading
);

export const onboardingData = createSelector(
  selectTutorial,
  (state: TutorialState) => state.onboarding
);


export const legalesData = createSelector(
  selectTutorial,
  (state: TutorialState) => state.legalesData
);


export const homeTutorialData = createSelector(
  selectTutorial,
  (state: TutorialState) => state.homeTutorialData
);

export const detailTutorial = createSelector(
  selectTutorial,
  (state: TutorialState) => state.detailTutorial
);

export const tutorialFaqData = createSelector(
  selectTutorial,
  (state: TutorialState) => state.tutorialFaqData
);






import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap} from 'rxjs/operators';
import { BlogFacade } from './blog.facade';
import { BlogActions } from './blog.actions';
import { BlogService } from '@shared/services/blog/blog.service';

@Injectable()
export class BlogEffects {

  readonly getBlogDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.getBlogDetail),
      tap(() => this.blogFacade.setLoading(true)),
      switchMap(async ({idPost}) => {
        try {
          const blogDetail = await lastValueFrom(
            this.blogService.getBlogDetail(idPost)
          );
          if(blogDetail.status === 'success'){
            return BlogActions.getBlogDetailSuccess({ blogDetail });
          }else{
            return BlogActions.getBlogDetailFail();
          }
        } catch (e) {
          console.error(e);
          return BlogActions.getBlogDetailFail();
        }
      }),
      tap(() => this.blogFacade.setLoading(false))
    )
  );

  readonly getBlogList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.getBlogList),
      tap(() => this.blogFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const blogList = await lastValueFrom(
            this.blogService.getBlogList(params)
          );
          if(blogList.status === 'success'){
            return BlogActions.getBlogListSuccess({ blogList });
          }else{
            return BlogActions.getBlogListFail();
          }
        } catch (e) {
          console.error(e);
          return BlogActions.getBlogListFail();
        }
      }),
      tap(() => this.blogFacade.setLoading(false))
    )
  );

  readonly getBlogCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogActions.getBlogCategories),
      tap(() => this.blogFacade.setLoadingCategory(true)),
      switchMap(async () => {
        try {
          const blogCategories = await lastValueFrom(
            this.blogService.getBlogCategories()
          );
          if(blogCategories.status === 'success'){
            return BlogActions.getBlogCategoriesSuccess({ blogCategories });
          }else{
            return BlogActions.getBlogCategoriesFail();
          }
        } catch (e) {
          console.error(e);
          return BlogActions.getBlogCategoriesFail();
        }
      }),
      tap(() => this.blogFacade.setLoadingCategory(false))
    )
  );



  constructor(
    private readonly actions$: Actions,
    private readonly blogFacade: BlogFacade,
    private readonly blogService: BlogService,

  ) {}
}

/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import {
  CallsList,
  GoodNewsDetail,
  GoodNewsItem,
  GoodNewsList,
  NewsLetterList,
  NewsLetterListOld,
  ResponseImageB64,
  SelectedPropertyWasi,
  TalksList,
  WasiHome
} from '@shared/models/wasi';
import { WasiActions } from './wasi.actions';


export interface WasiState {
  loading: boolean;
  imageB64: ResponseImageB64 | null;
  wasiHomeData: WasiHome | null;
  goodNewsList: GoodNewsList | null;
  goodNewsDetail: GoodNewsDetail | null;
  newsLetterList: NewsLetterList | null;
  newsLetterListOld: NewsLetterListOld | null;
  callsList: CallsList | null;
  talksList: TalksList | null;
  selectedPropertyWasi: SelectedPropertyWasi | null;

}

const initialState: WasiState = {
  loading: false,
  imageB64: null,
  wasiHomeData:null,
  goodNewsList:null,
  goodNewsDetail:null,
  newsLetterList:null,
  newsLetterListOld:null,
  callsList:null,
  talksList:null,
  selectedPropertyWasi:null,

};

export const WasiReducer = createReducer(
  initialState,
  on(WasiActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(WasiActions.getWasiHomeSuccess, (state, { wasiHomeData }) => ({
    ...state,
    wasiHomeData
  })),
  on(WasiActions.getGoodNewsSuccess, (state, { goodNewsList }) => ({
    ...state,
    goodNewsList
  })),
  on(WasiActions.getGoodNewsDetailSuccess, (state, { goodNewsDetail }) => ({
    ...state,
    goodNewsDetail
  })),
  on(WasiActions.getNewsLettersSuccess, (state, { newsLetterList }) => ({
    ...state,
    newsLetterList
  })),
  on(WasiActions.getCallsSuccess, (state, { callsList }) => ({
    ...state,
    callsList
  })),
  on(WasiActions.getTalksSuccess, (state, { talksList }) => ({
    ...state,
    talksList
  })),

  on(WasiActions.getNewsLettersOldSuccess, (state, { newsLetterListOld }) => ({
    ...state,
    newsLetterListOld
  })),
  on(WasiActions.getImageB64Success, (state, { imageB64 }) => ({
    ...state,
    imageB64
  })),
  on(WasiActions.selectedPropertyWasi, (state, { selectedPropertyWasi }) => ({
    ...state,
    selectedPropertyWasi
  })),
);

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ONBOARDING } from '@shared/static/store.static';
import { OnboardingEffects } from './onboarding.effects';
import { OnboardingFacade } from './onboarding.facade';
import { OnboardingReducer } from './onboarding.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(ONBOARDING, OnboardingReducer),
    EffectsModule.forFeature([OnboardingEffects]),
  ],
  providers: [OnboardingFacade]
})
export class OnboardingModule { }

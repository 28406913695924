/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input } from '@angular/core';
import { AvatarMenu } from '@shared/models/auth';
import { DataOnboarding } from '@shared/models/onboarding';
import { AuthFacade } from '@store/auth/auth.facade';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})


export class MainMenuComponent implements OnInit {
  @Input() idMenu = 'main-content';
  avatarMenu: AvatarMenu | null = null;
  avatarDefault = '/assets/default-avatar.png';


  onboardingData: DataOnboarding | null = null;
  readonly #subscriptions$ = new Subscription();
  constructor(
    private authFacade: AuthFacade,
    private readonly onboardingFacade: OnboardingFacade,
  ) { }

  ngOnInit() {
    this.authFacade.setDataAvatar$.subscribe(result => {
      if(result){
        this.avatarMenu = result;
      }
    });
    this.#subscriptions$.add(
      this.onboardingFacade.onboardingData$.subscribe(result => {
        if (result) {
          this.onboardingData = result.data;
        }else{
          // this.onboardingFacade.onboardingRequest();
        }
      })
    );

  }
  signout(){
    const logOutData ={
      all_devices: true
    };
    this.authFacade.getLogOut(logOutData);
  }

}

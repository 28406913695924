import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectBlog from './blog.selectors';
import { BlogState } from './blog.reducer';
import { BlogActions } from './blog.actions';
import { BlogParams } from '@shared/models/blog';




@Injectable()
export class BlogFacade {
  readonly loading$ = this.store.select(selectBlog.loading);
  readonly loadingCategory$ = this.store.select(selectBlog.loadingCategory);
  readonly blogDetail$ = this.store.select(selectBlog.blogDetail);
  readonly blogList$ = this.store.select(selectBlog.blogList);
  readonly blogCategories$ = this.store.select(selectBlog.blogCategories);

  constructor(
    private readonly store: Store<BlogState>,
  ) { }

  setLoading(loading: BlogState['loading']): void {
    this.store.dispatch(BlogActions.setLoading({ loading }));
  };
  setLoadingCategory(loadingCategory: BlogState['loadingCategory']): void {
    this.store.dispatch(BlogActions.setLoadingCategory({ loadingCategory }));
  };


  getBlogList(params: BlogParams) {
    this.store.dispatch(BlogActions.getBlogList({params}) );
  }
  getBlogDetail(idPost: string) {
    this.store.dispatch(BlogActions.getBlogDetail({idPost}) );
  }
  getBlogCategories() {
    this.store.dispatch(BlogActions.getBlogCategories() );
  }
}

import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
@Component({
  selector: 'app-my-information',
  templateUrl: './my-information.page.html',
  styleUrls: ['./my-information.page.scss'],
})
export class MyInformationPage implements OnInit {

  constructor(private menuCtrl: MenuController) { }

  ngOnInit() {}
  ionViewWillEnter() {
    this.menuCtrl.close();
    // console.log('ionViewWillEnter - 1');
  }
  // ionViewDidLoad() {
  //   console.log('ionViewDidLoad in appcomponent 2');
  // }
  // ionViewWillLeave() {
  //   console.log('ionViewWillLeave in appcomponent 3');
  // }
  // ionViewDidLeave() {
  //   console.log('ionViewDidLeave in appcomponent 4');
  // }
  // ionViewWillUnload() {
  //   console.log('ionViewWillUnload in appcomponent 5');
  // }
  // ionViewDidEnter() {
  //   console.log('ionViewDidEnter in appcomponent 6');
  // }
}

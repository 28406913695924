/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { CallTalkParams, GoodNewsDetailParams, NewsOldParams, RequestWasi, WasiGoodNewsParams, WasiParams } from '@shared/models/wasi';
import { WasiState } from './wasi.reducer';

enum WasiActionTypes {
    SET_LOADING = '[WASI] Set loading',
    GET_WASI_HOME = '[WASI] Get Wasi Home',
    GET_WASI_HOME_SUCCESS = '[WASI] Get Wasi Home success',
    GET_WASI_HOME_FAIL = '[WASI] Get Wasi Home fail',

    GET_GOOD_NEWS = '[WASI] Get good news',
    GET_GOOD_NEWS_SUCCESS = '[WASI] Get good news success',
    GET_GOOD_NEWS_FAIL = '[WASI] Get good news fail',

    GET_NEWSLETTERS = '[WASI] Get newsletters',
    GET_NEWSLETTERS_SUCCESS = '[WASI] Get newsletters success',
    GET_NEWSLETTERS_FAIL = '[WASI] Get newsletters fail',

    GET_CALLS = '[WASI] Get Calls',
    GET_CALLS_SUCCESS = '[WASI] Get calls success',
    GET_CALLS_FAIL = '[WASI] Get calls fail',

    GET_TALKS = '[WASI] Get talks',
    GET_TALKS_SUCCESS = '[WASI] Get talks success',
    GET_TALKS_FAIL = '[WASI] Get talks fail',

    GET_NEWSLETTERS_OLD = '[WASI] Get newsletters old',
    GET_NEWSLETTERS_OLD_SUCCESS = '[WASI] Get newsletters old success',
    GET_NEWSLETTERS_OLD_FAIL = '[WASI] Get newsletters old fail',

    SELECTED_PROPERTY_WASI = '[WASI] Selected property wasi',


    GET_IMAGE_B64 = '[WASI] Get image b64 news',
    GET_IMAGE_B64_SUCCESS = '[WASI] Get image b64 success',
    GET_IMAGE_B64_FAIL = '[WASI] Get image b64 fail',


    GET_GOOD_NEWS_DETAIL = '[WASI] Get good news detail',
    GET_GOOD_NEWS_DETAIL_SUCCESS = '[WASI] Get good detail success',
    GET_GOOD_NEWS_DETAIL_FAIL = '[WASI] Get good news detail fail',

}
export const WasiActions = {
    setLoading: createAction(
        WasiActionTypes.SET_LOADING,
        props<{ loading: WasiState['loading'] }>()
      ),
    getWasiHome: createAction(WasiActionTypes.GET_WASI_HOME),
    getWasiHomeSuccess: createAction(
        WasiActionTypes.GET_WASI_HOME_SUCCESS,
        props<{ wasiHomeData: WasiState['wasiHomeData']}>()
    ),
    getWasiHomeFail: createAction(WasiActionTypes.GET_WASI_HOME_FAIL),

    getGoodNews: createAction(WasiActionTypes.GET_GOOD_NEWS,
        props<{ params: WasiGoodNewsParams}>()
        ),

    getGoodNewsSuccess: createAction(
    WasiActionTypes.GET_GOOD_NEWS_SUCCESS,
    props<{ goodNewsList: WasiState['goodNewsList']}>()
    ),
    getGoodNewsFail: createAction(WasiActionTypes.GET_GOOD_NEWS_FAIL),


    getGoodNewsDetail: createAction(WasiActionTypes.GET_GOOD_NEWS_DETAIL,
        props<{ paramsDetail: GoodNewsDetailParams}>()
    ),
    getGoodNewsDetailSuccess: createAction(
    WasiActionTypes.GET_GOOD_NEWS_DETAIL_SUCCESS,
    props<{ goodNewsDetail: WasiState['goodNewsDetail']}>()
    ),
    getGoodNewsDetailFail: createAction(WasiActionTypes.GET_GOOD_NEWS_DETAIL_FAIL),


    getNewsLetters: createAction(WasiActionTypes.GET_NEWSLETTERS,
        props<{ params: RequestWasi}>()
    ),
    getNewsLettersSuccess: createAction(
        WasiActionTypes.GET_NEWSLETTERS_SUCCESS,
        props<{ newsLetterList: WasiState['newsLetterList']}>()
        ),
    getNewsLettersFail: createAction(WasiActionTypes.GET_NEWSLETTERS_FAIL),

    getCalls: createAction(WasiActionTypes.GET_CALLS,
        props<{ params: CallTalkParams}>()
    ),
    getCallsSuccess: createAction(
        WasiActionTypes.GET_CALLS_SUCCESS,
        props<{ callsList: WasiState['callsList']}>()
    ),
    getCallsFail: createAction(WasiActionTypes.GET_CALLS_FAIL),

    getTalks: createAction(WasiActionTypes.GET_TALKS,
        props<{ params: CallTalkParams}>()
    ),
    getTalksSuccess: createAction(
        WasiActionTypes.GET_TALKS_SUCCESS,
        props<{ talksList: WasiState['talksList']}>()
    ),
    getTalksFail: createAction(WasiActionTypes.GET_TALKS_FAIL),

    getNewsLettersOld: createAction(WasiActionTypes.GET_NEWSLETTERS_OLD,
        props<{ newsOldParams: NewsOldParams}>()
    ),
    getNewsLettersOldSuccess: createAction(
        WasiActionTypes.GET_NEWSLETTERS_OLD_SUCCESS,
        props<{ newsLetterListOld: WasiState['newsLetterListOld']}>()
    ),
    getNewsLettersOldFail: createAction(WasiActionTypes.GET_NEWSLETTERS_OLD_FAIL),


    selectedPropertyWasi: createAction(WasiActionTypes.SELECTED_PROPERTY_WASI,
        props<{ selectedPropertyWasi: WasiState['selectedPropertyWasi']}>()
    ),

    getImageB64: createAction(WasiActionTypes.GET_IMAGE_B64,
        props<{ urlImage: string}>()
    ),
    getImageB64Success: createAction(
        WasiActionTypes.GET_IMAGE_B64_SUCCESS,
        props<{ imageB64: WasiState['imageB64']}>()
    ),
    getImageB64Fail: createAction(WasiActionTypes.GET_IMAGE_B64_FAIL),



};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';



import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';
import { PropertiesActions } from './properties.actions';
import { PropertiesFacade } from './properties.facade';
import { PropertiesService } from '@shared/services/onboarding/properties.service';

@Injectable()
export class PropertiesEffects {
  // _logAll$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       //* si se necesita filtrar solo las acciones de un tipo en particular
  //       //* descomenta la linea de abajo y reeplaza WorkFlowsActions por el que necesites
  //       //ofType(...Object.values(WorkFlowsActions)),
  //       tap(act => {
  //         console.log(act);
  //       })
  //     ),
  //   { dispatch: false }
  // );
  readonly getProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.getProperties),
      tap(() => this.propertiesFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const propertiesList = await lastValueFrom(
            this.propertiesService.getProperties()
          );
          if(propertiesList.status === 'success'){
            return PropertiesActions.getPropertiesSuccess({ propertiesList });
          }else{
            return PropertiesActions.getPropertiesFail();
          }
        } catch (e) {
          console.error(e);
          return PropertiesActions.getPropertiesFail();
        }
      }),
      tap(() => this.propertiesFacade.setLoading(false))
    )
  );

  readonly getDetailProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.getDetailProperties),
      tap(() => this.propertiesFacade.setLoading(true)),
      switchMap(async ({proformaCode}) => {
        try {
          const propertiesDetail = await lastValueFrom(
            this.propertiesService.getDetailProperties(proformaCode)
          );
          if(propertiesDetail.status === 'success'){
            return PropertiesActions.getDetailPropertiesSuccess({ propertiesDetail });
          }else{
            return PropertiesActions.getPropertiesFail();
          }
        } catch (e) {
          console.error(e);
          return PropertiesActions.getPropertiesFail();
        }
      }),
      tap(() => this.propertiesFacade.setLoading(false))
    )
  );

  readonly getCurrentProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PropertiesActions.getCurrentProcess),
      tap(() => this.propertiesFacade.setLoading(true)),
      switchMap(async ({paramsProcess}) => {
        try {
          const currentProcessList = await lastValueFrom(
            this.propertiesService.getCurrentProcess(paramsProcess)
          );
          if(currentProcessList.status === 'success'){
            return PropertiesActions.getCurrentProcessSuccess({ currentProcessList });
          }else{
            return PropertiesActions.getCurrentProcessFail();
          }
        } catch (e) {
          console.error(e);
          return PropertiesActions.getCurrentProcessFail();
        }
      }),
      tap(() => this.propertiesFacade.setLoading(false))
    )
  );

  readonly getCurrentPropertyOne$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PropertiesActions.getCurrentPropertyOne),
    tap(() => this.propertiesFacade.setLoading(true)),
    switchMap(async ({paramsCurrentProperty}) => {
      try {
        const resultCurrentPropertyOne = await lastValueFrom(
          this.propertiesService.getCurrentPropertyOne(paramsCurrentProperty)
        );
        if(resultCurrentPropertyOne.status === 'success'){
          return PropertiesActions.getCurrentPropertyOneSuccess({ resultCurrentPropertyOne });
        }else{
          return PropertiesActions.getCurrentPropertyOneFail();
        }
      } catch (e) {
        console.error(e);
        return PropertiesActions.getCurrentPropertyOneFail();
      }
    }),
    tap(() => this.propertiesFacade.setLoading(false))
    )
  );

  readonly getCurrentPropertyTwo$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PropertiesActions.getCurrentPropertyTwo),
    tap(() => this.propertiesFacade.setLoading(true)),
    switchMap(async ({paramsCurrentProperty}) => {
      try {
        const resultCurrentPropertyTwo = await lastValueFrom(
          this.propertiesService.getCurrentPropertyTwo(paramsCurrentProperty)
        );
        if(resultCurrentPropertyTwo.status === 'success'){
          return PropertiesActions.getCurrentPropertyTwoSuccess({ resultCurrentPropertyTwo });
        }else{
          return PropertiesActions.getCurrentPropertyTwoFail();
        }
      } catch (e) {
        console.error(e);
        return PropertiesActions.getCurrentPropertyTwoFail();
      }
    }),
    tap(() => this.propertiesFacade.setLoading(false))
    )
  );

// Delivery Process Effects

readonly getHomeDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getHomeDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestDeliveryHome}) => {
    try {
      const deliveryHomeResponse = await lastValueFrom(
        this.propertiesService.getHomeDelivery(requestDeliveryHome)
      );
      if(deliveryHomeResponse.status === 'success'){
        return PropertiesActions.getHomeDeliverySuccess({ deliveryHomeResponse  });
      }else{
        return PropertiesActions.getHomeDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getHomeDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getLocationDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getLocationDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestLocationDelivery}) => {
    try {
      const locationDeliveryResponse = await lastValueFrom(
        this.propertiesService.getLocationDelivery(requestLocationDelivery)
      );
      if(locationDeliveryResponse.status === 'success'){
        return PropertiesActions.getLocationDeliverySuccess({ locationDeliveryResponse  });
      }else{
        return PropertiesActions.getLocationDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getLocationDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getGuideDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getGuideDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestGuideDelivery}) => {
    try {
      const guideDeliveryResponse = await lastValueFrom(
        this.propertiesService.getGuideDelivery(requestGuideDelivery)
      );
      if(guideDeliveryResponse.status === 'success'){
        return PropertiesActions.getGuideDeliverySuccess({ guideDeliveryResponse  });
      }else{
        return PropertiesActions.getGuideDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getGuideDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getStepsDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getStepsDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestStepsDelivery}) => {
    try {
      const stepsDeliveryResponse = await lastValueFrom(
        this.propertiesService.getStepsDelivery(requestStepsDelivery)
      );
      if(stepsDeliveryResponse.status === 'success'){
        return PropertiesActions.getStepsDeliverySuccess({ stepsDeliveryResponse  });
      }else{
        return PropertiesActions.getStepsDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getStepsDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getLearnMoreDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getLearnMoreDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestLearnMoreDelivery}) => {
    try {
      const learnMoreDeliveryResponse = await lastValueFrom(
        this.propertiesService.getLearnMoreDelivery(requestLearnMoreDelivery)
      );
      if(learnMoreDeliveryResponse.status === 'success'){
        return PropertiesActions.getLearnMoreDeliverySuccess({ learnMoreDeliveryResponse  });
      }else{
        return PropertiesActions.getLearnMoreDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getLearnMoreDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getFaqDelivery$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getFaqDelivery),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestFAQDelivery}) => {
    try {
      const faqDeliveryResponse = await lastValueFrom(
        this.propertiesService.getFAQDelivery(requestFAQDelivery)
      );
      if(faqDeliveryResponse.status === 'success'){
        return PropertiesActions.getFaqDeliverySuccess({ faqDeliveryResponse  });
      }else{
        return PropertiesActions.getFaqDeliveryFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getFaqDeliveryFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);

readonly getLinksInterest$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getLinksInterest),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async ({requestLinkDelivery}) => {
    try {
      const linkInterestResponse = await lastValueFrom(
        this.propertiesService.getLinksInterest(requestLinkDelivery)
      );
      if(linkInterestResponse.status === 'success'){
        return PropertiesActions.getLinksInterestSuccess({ linkInterestResponse  });
      }else{
        return PropertiesActions.getLinksInterestFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getLinksInterestFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);


readonly getPropertyFaqRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(PropertiesActions.getPropertyFaqRequest),
  tap(() => this.propertiesFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const propertyfaqResponse = await lastValueFrom(
        this.propertiesService.getPropertyFaqRequest()
      );
      if(propertyfaqResponse.status === 'success'){
        return PropertiesActions.getPropertyFaqRequestSuccess({ propertyfaqResponse });
      }else{
        return PropertiesActions.getPropertyFaqRequestFail();
      }
    } catch (e) {
      console.error(e);
      return PropertiesActions.getPropertyFaqRequestFail();
    }
  }),
  tap(() => this.propertiesFacade.setLoading(false))
)
);


  constructor(
    private readonly actions$: Actions,
    private readonly propertiesFacade: PropertiesFacade,
    private readonly propertiesService: PropertiesService,
  ) {}
}

/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { ResponseFormReferral, ResponseReferralPartner } from '@shared/models/referral-partner';
import { ReferralPartnerActions } from './referral-partner.actions';
import { ResponseProjectsOnSale } from '../../shared/models/referral-partner';


export interface ReferralPartnerState {
  loading: boolean;
  infoReferralPartner: ResponseReferralPartner | null;
  projectsOnSaleList: ResponseProjectsOnSale | null;
  responseFormReferral: ResponseFormReferral | null;
}

const initialState: ReferralPartnerState = {
  loading: false,
  infoReferralPartner:null,
  projectsOnSaleList:null,
  responseFormReferral: null,
};

export const ReferralPartnerReducer = createReducer(
  initialState,
  on(ReferralPartnerActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(ReferralPartnerActions.getReferralPartnerSuccess, (state, { infoReferralPartner }) => ({
    ...state,
    infoReferralPartner
  })),
  on(ReferralPartnerActions.getProjectsOnSaleSuccess, (state, { projectsOnSaleList }) => ({
    ...state,
    projectsOnSaleList
  })),
  on(ReferralPartnerActions.getProjectsOnSaleSuccess, (state, { projectsOnSaleList }) => ({
    ...state,
    projectsOnSaleList
  })),
  on(ReferralPartnerActions.sentFormReferralSuccess, (state, { responseFormReferral }) => ({
    ...state,
    responseFormReferral
  })),
);

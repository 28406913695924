import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap, mergeMap } from 'rxjs/operators';
import { AuthFacade } from './auth.facade';
import { AuthService } from '@shared/services/auth/auth.service';
import { AuthActions } from './auth.actions';
import { StorageService } from '@shared/services/storage/storage.service';
import { AUTH_TOKEN_KEY, AUTH_USER_INFO, NOTIFICATION_MESSAGE, REDIRECT_TO_NOTIFICATION } from '@shared/static/storage.static';
import { AlertService } from '@shared/services/alert/alert.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';


@Injectable()
export class AuthEffects {

  readonly signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({signIn}) => {
        try {
          const user = await lastValueFrom(
            this.authService.signIn(signIn)
          );
          if(user.status === 'success'){
            const userData ={
              avatar: user.data.avatar,
              name: user.data.name
            };
            this.storageService.set(AUTH_TOKEN_KEY, user.data.token);
            this.storageService.set(AUTH_USER_INFO, userData);

            return AuthActions.signInSuccess({ user });
          }else{
            const dataMessage = {
              header:user.status,
              message:user.message,
            };
            this.alertService.presentAlert(dataMessage);
            return AuthActions.signInFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.signInFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly signinSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.signInSuccess),
        tap(() => {
          const redirectToNotification = this.storageService.get(REDIRECT_TO_NOTIFICATION);
          const notificationsPush = this.storageService.get(NOTIFICATION_MESSAGE);
          if(redirectToNotification){
            const converterData = Array(notificationsPush);
            const lengthData = converterData.length;
            const lastPushNotification = converterData[lengthData-1];
            this.storageService.set(REDIRECT_TO_NOTIFICATION,false);
            const buildUrl = lastPushNotification[0].additionalData.urlPath;
            if(buildUrl){
              this.router.navigate([buildUrl]);
            }else{
              this.router.navigate(['/home']);
            }
          }else{
            this.router.navigate(['/home']);
          }
        })
      ),
    { dispatch: false }
  );

  readonly myInfoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.myInfoRequest),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const myInfoData = await lastValueFrom(
            this.authService.myInfoRequest()
          );
          if(myInfoData.status === 'success'){
            const userData ={
              avatar: myInfoData.data.titular.avatar,
              name: myInfoData.data.titular.name
            };
            this.storageService.set(AUTH_USER_INFO, userData);
            return AuthActions.myInfoSuccess({ myInfoData });
          }else{
            return AuthActions.myInfoFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.myInfoFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly getChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getChangePassword),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({newPassword}) => {
        try {
          const confirmNewPwd = await lastValueFrom(
            this.authService.getChangePassword(newPassword)
          );
          if(confirmNewPwd.status === 'success'){
            return AuthActions.getChangePasswordSuccess({ confirmNewPwd });
          }else{
            const dataMessage = {
              header:confirmNewPwd.status,
              message:confirmNewPwd.message,
            };
            this.alertService.presentAlert(dataMessage);
            return AuthActions.getChangePasswordFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.getChangePasswordFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly getChangePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getChangePasswordSuccess),
        tap(({confirmNewPwd}) => {
          const dataMessage = {
            header:confirmNewPwd.status,
            message:confirmNewPwd.message,
          };
          this.alertService.presentAlert(dataMessage);
          this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  readonly getLogOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getLogOut),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({logOut}) => {
        try {
          const confirmLogOut = await lastValueFrom(
            this.authService.getLogOut(logOut)
          );
          if(confirmLogOut.status === 'success'){
            return AuthActions.getLogOutSuccess({ confirmLogOut });
          }else{
            return AuthActions.getLogOutFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.getLogOutFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly getLogOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getLogOutSuccess),
        tap(({confirmLogOut}) => {
          const dataMessage = {
            header:confirmLogOut.status,
            message:confirmLogOut.message,
          };
          this.menuCtrl.close();
          this.storageService.removeItem(AUTH_TOKEN_KEY);
          this.storageService.removeItem(AUTH_USER_INFO);
          this.alertService.presentToast(dataMessage);
          setTimeout(()=>{
            this.router.navigate(['/auth/login'],{replaceUrl:true});
          },500);
        })
      ),
    { dispatch: false }
  );

  readonly getAvatarList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getAvatarList),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const avatarList = await lastValueFrom(
            this.authService.getAvatarList()
          );
          if(avatarList.status === 'success'){
            return AuthActions.getAvatarListSuccess({ avatarList });
          }else{
            return AuthActions.getAvatarListFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.getAvatarListFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly changeAvatar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.changeAvatar),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({newAvatar}) => {
        try {
          const resultNewAvatar = await lastValueFrom(
            this.authService.changeAvatar(newAvatar)
          );
          if(resultNewAvatar.status === 'success'){
            this.authFacade.myInfoRequest();
            return AuthActions.changeAvatarSuccess({ resultNewAvatar });
          }else{
            return AuthActions.changeAvatarFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.changeAvatarFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly getCodeReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getCodeReset),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({email}) => {
        try {
          const responseResetPwd = await lastValueFrom(
            this.authService.getCodeReset(email)
          );
          if(responseResetPwd.status === 'success'){
            return AuthActions.getCodeResetSuccess({ responseResetPwd });
          }else{

            if(responseResetPwd.status === 'warning'){
              const dataMessage = {
                header:responseResetPwd.status,
                message:responseResetPwd.message,
              };
              this.alertService.presentAlertErrorCode(dataMessage);
              return AuthActions.getCodeResetSuccess({ responseResetPwd });
            }else{
              const dataMessage = {
                header:responseResetPwd.status,
                message:responseResetPwd.message,
              };
              this.alertService.presentAlert(dataMessage);
              const errorGetCode = {
                ...responseResetPwd,
                errorType:'datos'
              };
              return AuthActions.getCodeResetFail({errorGetCode});
            }
          }
        } catch (e) {
          const errorGetCode = {
            ...e,
            errorType:'error'
          };
          console.error(e);
          return AuthActions.getCodeResetFail({errorGetCode});
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );

  readonly getCodeResetSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.getCodeResetSuccess),
        tap(({responseResetPwd}) => {
          if(responseResetPwd.status === 'success'){
            this.router.navigate(['/reset-forgotten-password'],{replaceUrl:true});
          }
        })
      ),
    { dispatch: false }
  );

  readonly getResetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getResetPassword),
      tap(() => this.authFacade.setLoading(true)),
      switchMap(async ({infoUserReset}) => {
        try {
          const confirmResetPwd = await lastValueFrom(
            this.authService.getResetPassword(infoUserReset)
          );
          if(confirmResetPwd.status === 'success'){
            return AuthActions.getResetPasswordSuccess({ confirmResetPwd });
          }else{
            const dataMessage = {
              header:confirmResetPwd.status,
              message:confirmResetPwd.message,
            };
            this.alertService.presentAlert(dataMessage);
            return AuthActions.getResetPasswordFail();
          }
        } catch (e) {
          console.error(e);
          return AuthActions.getResetPasswordFail();
        }
      }),
      tap(() => this.authFacade.setLoading(false))
    )
  );


  constructor(
    private readonly actions$: Actions,
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private menuCtrl: MenuController,
    private zone: NgZone,
  ) {}
}

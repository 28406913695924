import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH } from '@shared/static/store.static';
import type { AuthState } from './auth.reducer';

export const selectAuth = createFeatureSelector<AuthState>(AUTH);

export const loading = createSelector(
    selectAuth,
  (state: AuthState) => state.loading
);
export const user = createSelector(
    selectAuth,
  (state: AuthState) => state.user
);

export const myInfoData = createSelector(
  selectAuth,
(state: AuthState) => state.myInfoData
);
export const confirmNewPwd = createSelector(
  selectAuth,
(state: AuthState) => state.confirmNewPwd
);

export const avatarList = createSelector(
  selectAuth,
(state: AuthState) => state.avatarList
);

export const resultNewAvatar = createSelector(
  selectAuth,
(state: AuthState) => state.resultNewAvatar
);

export const setDataAvatar = createSelector(
  selectAuth,
(state: AuthState) => state.avatarData
);
export const deviceId = createSelector(
  selectAuth,
(state: AuthState) => state.deviceId
);

export const responseResetPwd = createSelector(
  selectAuth,
(state: AuthState) => state.responseResetPwd
);
export const confirmResetPwd = createSelector(
  selectAuth,
(state: AuthState) => state.confirmResetPwd
);
export const errorGetCode = createSelector(
  selectAuth,
(state: AuthState) => state.errorGetCode
);

/* eslint-disable max-len */
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { AuthFacade } from '@store/auth/auth.facade';
// import { Storage } from '@ionic/storage';
import { StorageService } from '@shared/services/storage/storage.service';
import { AUTH_TOKEN_KEY, NOTIFICATION_MESSAGE, REDIRECT_TO_NOTIFICATION } from '@shared/static/storage.static';
import { Router } from '@angular/router';
import { DataPush } from '@shared/models/push-notification';
import { HomeFacade } from '@store/home/home.facade';
@Injectable({
  providedIn: 'root'
})

export class PushService {
  userId: string;
  // pushListener = new EventEmitter<OSNotificationPayload>();
  pushListener = new EventEmitter<any>();
  // mensajes: OSNotificationPayload[] = [];
  mensajes: any[] = [];
  constructor(
    // private oneSignal: OneSignal,
    // private storage: Storage,
    private readonly storageService: StorageService,
    private platform: Platform,
    private readonly router: Router,
    private homeFacade: HomeFacade,
    private zone: NgZone,

  ) {
    // this.cargarMensajes();
   }
  // async getMensajes() {
  //   await this.cargarMensajes();
  //   return [...this.mensajes];
  // }

  settingPushInit(){
    const isIOS = this.platform.is('ios');
    const isAndroid = this.platform.is('android');
    const isDesktop = this.platform.is('desktop');

    if(isIOS || isAndroid){
      OneSignal.setAppId('cd1bff47-d447-4a62-b084-0dd07c50a5c0');
      OneSignal.setNotificationOpenedHandler((jsonData: DataPush)=> {
          console.log('notificationOpenedCallback ---1:', jsonData);
          if(jsonData && jsonData.notification && jsonData.notification.additionalData && jsonData.notification.additionalData.urlPath){
            // const dataPush = jsonData.notification.additionalData;
            const dataPush = jsonData.notification.additionalData.urlPath;
            this.zone.run(()=>{
              this.router.navigate([dataPush],{replaceUrl:true});
              if(jsonData.notification.additionalData.customId){
                this.homeFacade.deleteNotification(jsonData.notification.additionalData.customId);
              }
            });
            this.notificacionRecibida(jsonData.notification);
            const token: string | null = this.storageService.get(AUTH_TOKEN_KEY);
            if(!token){
              this.storageService.set(REDIRECT_TO_NOTIFICATION,true);
            }
          }
      });
      OneSignal.setNotificationWillShowInForegroundHandler((jsonData)=> {
        console.log('jsonData receiver getNotification ---2', jsonData.getNotification());
        // this.homeFacade.getNotifications();
        this.notificacionRecibida( jsonData.getNotification());
      });
      // Prompts the user for notification permissions.
      // Since this shows a generic native prompt,
      //we recommend instead using an In-App Message to prompt for notification permission
      // (See step 7) to better communicate to your users what notifications they will get.
      OneSignal.promptForPushNotificationsWithUserResponse((accepted)=> {
          console.log('User accepted notifications ---2:' + accepted);
      });
      // OneSignal.getDeviceState((stateChanges)=> {
      //       console.log('stateChanges',stateChanges);

      //       this.userId = stateChanges.userId;
      //       this.authFacade.setDeviceId(this.userId);
      //       console.log('this.userId in SERVICE PUSH',this.userId);
      // });
    }

    // const isIOS = this.platform.is('ios');
    // const isAndroid = this.platform.is('android');
    // if(isIOS || isAndroid){
    //   this.oneSignal.startInit('cd1bff47-d447-4a62-b084-0dd07c50a5c0','152194391924');
    //   this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

    //   this.oneSignal.handleNotificationReceived().subscribe((noti)=>{
    //     console.log('handleNotificationReceived', noti);
    //     this.notificacionRecibida( noti );
    //   });
    //   this.oneSignal.handleNotificationOpened().subscribe(async (noti)=>{
    //     console.log('handleNotificationOpened', noti);
    //     if(noti && noti.notification.payload && noti.notification.payload.additionalData.urlPath){
    //       const dataPush = noti.notification.payload.additionalData.urlPath;
    //       console.log('dataPush',dataPush);
    //       this.zone.run(()=>{
    //         this.router.navigate([dataPush],{replaceUrl:true});
    //       });
    //     }
    //     const token: string | null = this.storageService.get(AUTH_TOKEN_KEY);
    //     if(!token){
    //       this.storageService.set(REDIRECT_TO_NOTIFICATION,true);
    //     }
    //     await this.notificacionRecibida( noti.notification );

    //   });
    //   this.oneSignal.getIds().then(info=>{
    //     this.userId = info.userId;
    //     this.authFacade.setDeviceId(this.userId);
    //     console.log('this.userId',this.userId);
    //   });
    //   this.oneSignal.endInit();
    // }else{
    //   console.log('no estas en ambiente mobile');
    // }
  }

// get notification, only test

async notificacionRecibida( noti: any ) {
  console.log('notixx1', noti);
  await this.cargarMensajes();
  const payload = noti;
  const existePush = this.mensajes.find( mensaje => mensaje.notificationId === payload.notificationId );
  if ( existePush ) {
    return;
  }
  this.mensajes.unshift( payload );
  this.pushListener.emit( payload );
  await this.guardarMensajes();
}

guardarMensajes() {
  this.storageService.set(NOTIFICATION_MESSAGE,this.mensajes);
}
async cargarMensajes() {
  this.mensajes = await this.storageService.get(NOTIFICATION_MESSAGE) || [];
  return this.mensajes;
}
async borrarMensajes() {
  this.storageService.removeItem(NOTIFICATION_MESSAGE);
  this.mensajes = [];
  this.guardarMensajes();
}
// get notification, only test




}

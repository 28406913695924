/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { DocumentsActions } from './documents.actions';
import { ResponseCategory, ResponseDocumentCategory } from '@shared/models/documents';




export interface DocumentsState {
  loading: boolean;
  filesByCategory: ResponseDocumentCategory | null;
  responseCategory: ResponseCategory | null;

}


const initialState: DocumentsState = {
  loading: false,
  filesByCategory: null,
  responseCategory: null,

};

export const DocumentsReducer = createReducer(
  initialState,
  on(DocumentsActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),


  on(DocumentsActions.getFilesByCategoryRequestSuccess, (state, { filesByCategory }) => ({
    ...state,
    filesByCategory
  })),

  on(DocumentsActions.getCategoryRequestSuccess, (state, { responseCategory }) => ({
    ...state,
    responseCategory
  })),


);

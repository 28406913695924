import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BLOG } from '@shared/static/store.static';
import { BlogState } from './blog.reducer';



export const selectBlog = createFeatureSelector<BlogState>(BLOG);

export const loading = createSelector(
  selectBlog,
  (state: BlogState) => state.loading
);
export const loadingCategory = createSelector(
  selectBlog,
  (state: BlogState) => state.loadingCategory
);

export const blogList = createSelector(
  selectBlog,
  (state: BlogState) => state.blogList
);

export const blogDetail = createSelector(
  selectBlog,
  (state: BlogState) => state.blogDetail
);

export const blogCategories = createSelector(
  selectBlog,
  (state: BlogState) => state.blogCategories
);




/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ClaimComponent } from './pages/claim/claim.component';
import { NotificationDetailComponent } from './pages/notification-detail/notification-detail.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { AuthGuard } from './guards/auth.guard';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { SetHeaderGuard } from './guards/set-header.guard';
import { ServerErrorComponent } from './pages/server-error/server-error.component';

const routes: Routes = [
  {
    path: 'claim',
    component:ClaimComponent
  },
  {
    path: 'terms-and-conditions',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Legales',
        bgColor: '#ffffff',
        textColor:'#000000',
        urlBack:'/home'
      }
    },
    component:TermsAndConditionsComponent,
  },
  {
    path: '',
    redirectTo: 'onboarding',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'my-information',
    canActivate:[AuthGuard],
    loadChildren: () => import('./pages/my-information/my-information.module').then( m => m.MyInformationPageModule)
  },
  {
    path: 'notifications',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        urlBack:'/home',
        titlePage: 'Notificaciones',
        bgColor: '#ffffff',
        textColor:'#000000',
      }
    },
    component:NotificationsComponent
  },
  {
    path: 'notification-detail',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Notificaciones',
        bgColor: '#ffffff',
        textColor:'#000000',
        urlBack:'/notifications'
      }
    },
    component:NotificationDetailComponent
  },
  {
    path: 'blog',
    canActivate:[AuthGuard],

    loadChildren: () => import('./pages/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'server-error',
    component:ServerErrorComponent
  },
  {
    path: 'my-documents',
    loadChildren: () => import('./pages/my-documents/my-documents.module').then( m => m.MyDocumentsPageModule)
  },
  // {
  //   path: 'referral-partner',
  //   loadChildren: () => import('./pages/referral-partner/referral-partner.module').then( m => m.ReferralPartnerPageModule)
  // },
  {
    path: 'my-contacts',
    loadChildren: () => import('./pages/my-contacts/my-contacts.module').then( m => m.MyContactsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./pages/tutorials/tutorials.module').then( m => m.TutorialsPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./pages/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'benefits',
    loadChildren: () => import('./pages/benefits/benefits-list/benefits-list.module').then( m => m.BenefitsListPageModule)
  },
  {
    path: 'benefits-detail',
    loadChildren: () => import('./pages/benefits/benefits-detail/benefits-detail.module').then( m => m.BenefitsDetailPageModule)
  },
  {
    path: 'sac-intro',
    loadChildren: () => import('./pages/sac/sac-intro/sac-intro.module').then( m => m.SacIntroPageModule)
  },
  {
    path: 'sac-gracias',
    loadChildren: () => import('./pages/sac/sac-gracias/sac-gracias.module').then( m => m.SacGraciasPageModule)
  },
  {
    path: 'sac-historial',
    loadChildren: () => import('./pages/sac/sac-historial/sac-historial.module').then( m => m.SacHistorialPageModule)
  },
  {
    path: 'sac-formulario',
    loadChildren: () => import('./pages/sac/sac-formulario/sac-formulario.module').then( m => m.SacFormularioPageModule)
  },
  {
    path: 'sac-form-response',
    loadChildren: () => import('./pages/sac/sac-form-response/sac-form-response.module').then( m => m.SacFormResponsePageModule)
  },
  {
    path: 'benefits',
    loadChildren: () => import('./pages/benefits/benefits-list/benefits-list.module').then( m => m.BenefitsListPageModule)
  },
  {
    path: 'benefits-detail/:id',
    loadChildren: () => import('./pages/benefits/benefits-detail/benefits-detail.module').then( m => m.BenefitsDetailPageModule)
  },
  {
    path: 'proceso-entrega/proforma-code/:proforma_code',
    loadChildren: () => import('./pages/proyectos-entregados/antes-de-la-entrega.module').then( m => m.AntesDeLaEntregaPageModule)
  },
  {
    path: 'sac-historial-detalle-solicitud/:id',
    loadChildren: () => import('./pages/sac/history-detail/history-detail.module').then( m => m.SacHistorialDetalleSolicitudPageModule)
  },
  {
    path: 'sac-historial-detalle-solicitud',
    loadChildren: () => import('./pages/sac/history-detail/history-detail.module').then( m => m.SacHistorialDetalleSolicitudPageModule)
  },
  {
    path: 'wasi-know',
    loadChildren: () => import('./pages/wasi/know/know.module').then( m => m.KnowPageModule)
  },
  {
    path: 'wasi-good-news/proforma-code/:proforma_code',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Wasi',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-know'
      }
    },
    loadChildren: () => import('./pages/wasi/good-news/wasi-good-news.module').then( m => m.WasiGoodNewsPageModule)
  },
  {
    path:'wasi-good-news/proforma-code/:proforma_code/pagina/:page',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Nogales',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-know'
      }
    },
    loadChildren: () => import('./pages/wasi/good-news/wasi-good-news.module').then( m => m.WasiGoodNewsPageModule)
  },
  {
    path: 'wasi-good-news-detail/proforma-code/:proforma_code/:id',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Buenas Noticias',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'wasi-good-news'
      }
    },
    loadChildren: () => import('./pages/wasi/good-news-detail/good-news-detail.module').then( m => m.WasiGoodNewsDetailPageModule)
  },
  {
    path: 'wasi-good-news-detail',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Buenas Noticias',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'wasi-good-news'
      }
    },
    loadChildren: () => import('./pages/wasi/good-news-detail/good-news-detail.module').then( m => m.WasiGoodNewsDetailPageModule)
  },
  {
    path: 'wasi-newsletters/proforma-code/:proforma_code',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Wasi',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-know'
      }
    },
    loadChildren: () => import('./pages/wasi/newsletters/newsletters.module').then( m => m.WasiNewslettersPageModule)
  },
  {
    path: 'wasi-old-newsletters/proforma-code/:proforma_code',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Boletines',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-newsletters'
      }
    },
    loadChildren: () => import('./pages/wasi/old-newsletters/old-newsletters.module').then( m => m.OldNewslettersPageModule)
  },
  {
    path: 'wasi-old-newsletters/proforma-code/:proforma_code/pagina/:page/month/:month/year/:year',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Boletines',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-newsletters'
      }
    },
    loadChildren: () => import('./pages/wasi/old-newsletters/old-newsletters.module').then( m => m.OldNewslettersPageModule)
  },
  {
    path: 'wasi-calls-and-talks/proforma-code/:proforma_code',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Wasi',
        bgColor: '#00CEC5',
        textColor:'#ffffff',
        urlBack:'/wasi-good-news'
      }
    },
    loadChildren: () => import('./pages/wasi/call-and-talks/call-and-talks.module').then( m => m.WasiCallAndTalksPageModule)
  },
  {
    path: 'password-recovery',
    canActivate:[SetHeaderGuard],
    data:{
      header:{
        setHeader:false,
        hideMainMenu:true
      }
    },
    loadChildren: () => import('./pages/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'reset-forgotten-password',
    canActivate:[SetHeaderGuard],
    data:{
      header:{
        setHeader:false,
        hideMainMenu:true
      }
    },
    loadChildren: () => import('./pages/reset-password/reset-forgotten-password.module').then( m => m.ResetForgottenPasswordPageModule)
  },
  {
    path: 'properties-list',
    canActivate:[SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        titlePage: 'Mis Propiedades',
        bgColor: '#643CBE',
        textColor:'#ffffff',
      }
    },
    loadChildren: () => import('./pages/properties/properties-list/properties-list.module').then( m => m.PropertiesListPageModule)
  },
  {
    path: 'property-detail/proforma-code/:proforma_code',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Detalle de Proyecto',
        bgColor: '#643CBE',
        textColor:'#ffffff',
        urlBack:'properties-list'
      }
    },
    loadChildren: () => import('./pages/properties/property-detail/property-detail.module').then( m => m.PropertyDetailPageModule)
  },
  {
    path: 'referral-partner',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        headerTransparente:true
      }
    },
    loadChildren: () => import('./pages/referral-partner/description/description.module').then( m => m.DescriptionPageModule)
  },
  {
    path: 'referral-participate',
    canActivate:[SetHeaderGuard],
        data: {
          header: {
            setHeader: true,
            backButton: true,
            titlePage: 'Socio Referido',
            bgColor: '#002E82',
            textColor: '#ffffff',
            urlBack:'referral-partner'
          }
        },
    loadChildren: () => import('./pages/referral-partner/participate/participate.module').then( m => m.ParticipatePageModule)
  },
  {
    path: 'test-notifications-list',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        urlBack:'/home',
        titlePage: 'Notificaciones',
        bgColor: '#ffffff',
        textColor:'#000000',
      }
    },
    loadChildren: () => import('./pages/test-notifications-list/test-notifications-list.module').then( m => m.TestNotificationsListPageModule)
  },
  {
    path: 'terms-conditions-login',
    canActivate:[SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Iniciar Sesión',
        bgColor: '#ffffff',
        textColor:'#000000',
        urlBack:'/auth/login',
        hideMainMenu:true,
        hideFooter:true
      }
    },
    loadChildren: () => import('./pages/terms-conditions-login/terms-conditions-login.module').then( m => m.TermsConditionsLoginPageModule)
  },
  {
    path: 'privacy-policies',
    canActivate:[SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        titlePage: 'Iniciar Sesión',
        bgColor: '#ffffff',
        textColor:'#000000',
        urlBack:'/auth/login',
        hideMainMenu:true,
        hideFooter:true
      }
    },
    loadChildren: () => import('./pages/privacy-policies/privacy-policies.module').then( m => m.PrivacyPoliciesPageModule)
  },
  {
    path: 'notifications-list',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        backButton: true,
        urlBack:'/home',
        titlePage: 'Notificaciones',
        bgColor: '#ffffff',
        textColor:'#000000',
      }
    },
    loadChildren: () => import('./pages/notifications-list/notifications-list.module').then( m => m.NotificationsListPageModule)
  },
  {
    path: 'property-faq',
    canActivate:[AuthGuard, SetHeaderGuard],
    data:{
      header:{
        setHeader:true,
        titlePage: 'Preguntas Frecuentes',
        bgColor: '#002E82',
        textColor:'#ffffff'
      }
    },
    loadChildren: () => import('./pages/property-faq/property-faq.module').then( m => m.PropertyFaqPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

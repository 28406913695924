/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { BenefitsCategories, BenefitsDetail, BenefitsList, FavoriteRequest } from '@shared/models/benefits';
import { BenefitsActions } from './benefits.actions';




export interface BenefitsState {
  loading: boolean;
  loadingCategory: boolean;
  benefitsList: BenefitsList | null;
  benefitsCategories: BenefitsCategories | null;
  benefitsDetail: BenefitsDetail | null;
  favoriteRequest: FavoriteRequest | null;

}

const initialState: BenefitsState = {
  loading: false,
  loadingCategory: false,
  benefitsDetail:null,
  benefitsList:null,
  benefitsCategories:null,
  favoriteRequest:null,


};

export const BenefitsReducer = createReducer(
  initialState,
  on(BenefitsActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(BenefitsActions.setLoadingCategory, (state, { loadingCategory }) => ({
    ...state,
    loadingCategory,
  })),
  on(BenefitsActions.getBenefitsListSuccess, (state, { benefitsList }) => ({
    ...state,
    benefitsList
  })),
  on(BenefitsActions.getBenefitsDetailSuccess, (state, { benefitsDetail }) => ({
    ...state,
    benefitsDetail
  })),
  on(BenefitsActions.setBenefitsFavoriteSuccess, (state, { favoriteRequest }) => ({
    ...state,
    favoriteRequest
  })),
  on(BenefitsActions.getBenefitsCategoriesSuccess, (state, { benefitsCategories }) => ({
    ...state,
    benefitsCategories
  })),
  on(BenefitsActions.cleanBenefitsData, () => ({
    ...initialState,
  }))
);

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IdsRequestFiles, ResponseCategory, ResponseDocumentCategory } from '@shared/models/documents';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
  ) { }


  getFilesByCategoryRequest(idsRequestFiles: IdsRequestFiles) {
    return this.http.get<ResponseDocumentCategory>(`${API_URL}/api/documents/files?proforma=${idsRequestFiles.proforma}&category=${idsRequestFiles.category}`);
  }

  getCategoryRequest(){
    return this.http.get<ResponseCategory>(`${API_URL}/api/documents/categories`);
  }

}

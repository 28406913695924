import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PinchZoomComponent } from './pinch-zoom.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [
        PinchZoomComponent
    ],
    imports: [
        CommonModule,
        IonicModule
    ],
    exports: [
        PinchZoomComponent
    ],
    providers: [

    ],
    bootstrap: [
    ],
    entryComponents: [
        PinchZoomComponent
    ]
})
export class PinchZoomModule { }

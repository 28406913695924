import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { HomeFacade } from '@store/home/home.facade';


@Injectable()
export class SetHeaderGuard implements CanActivate {
  constructor(
    private homeFacade: HomeFacade,
  ) { }
  canActivate(setHeader): boolean {
    if(!setHeader.data.header.hideMainMenu){
      this.homeFacade.getNotifications();
      }
      this.homeFacade.setDataHeader(setHeader.data.header);
    return true;
  }
}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BENEFITS } from '@shared/static/store.static';
import { BenefitsEffects } from './benefits.effects';
import { BenefitsFacade } from './benefits.facade';
import { BenefitsReducer } from './benefits.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(BENEFITS, BenefitsReducer),
    EffectsModule.forFeature([BenefitsEffects]),
  ],
  providers: [BenefitsFacade]
})
export class BenefitsModule { }

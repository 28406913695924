import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SAC } from '@shared/static/store.static';
import { SacState } from './sac.reducer';

export const selectSac = createFeatureSelector<SacState>(SAC);

export const loading = createSelector(
  selectSac,
  (state: SacState) => state.loading
);
export const loadingState = createSelector(
  selectSac,
  (state: SacState) => state.loadingState
);

export const sacInfo = createSelector(
  selectSac,
  (state: SacState) => state.sacInfo
);

export const sacFormList = createSelector(
  selectSac,
  (state: SacState) => state.sacFormList
);

export const sacStates = createSelector(
  selectSac,
  (state: SacState) => state.sacStates
);

export const sacAttetions = createSelector(
  selectSac,
  (state: SacState) => state.sacAttetions
);
export const newAttention = createSelector(
  selectSac,
  (state: SacState) => state.newAttention
);

export const detailAttetion = createSelector(
  selectSac,
  (state: SacState) => state.detailAttetion
);

export const notesFiles = createSelector(
  selectSac,
  (state: SacState) => state.notesFiles
);

export const newNote = createSelector(
  selectSac,
  (state: SacState) => state.newNote
);


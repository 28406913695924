import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from '@shared/services/storage/storage.service';
import { AUTH_USER_INFO } from '@shared/static/storage.static';
import { MenuController } from '@ionic/angular';
import { AvatarMenu } from '@shared/models/auth';
import { AuthFacade } from '@store/auth/auth.facade';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { DataOnboarding } from '@shared/models/onboarding';
import { IdataHeader } from '@shared/models/home';
import { HomeFacade } from '@store/home/home.facade';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
})
export class HeaderMainComponent implements OnInit {
  @Input() urlBack: string;
  @Input() backButton: boolean;
  @Input() titlePage: string;
  @Input() textColor: string;
  @Input() bgColor: string;
  @Input() setWing: boolean;
  @Input() idMenu: string;
  @Input() setHeader: boolean;
  @Input() isHome: boolean;
  @Input() headerTransparente: boolean;
  @Input() dataHeader: IdataHeader;
  onboardingData: DataOnboarding | null = null;
  countNotification=0;
  constructor(
    private menuCtrl: MenuController,
    private readonly storageService: StorageService,
    private authFacade: AuthFacade,
    private homeFacade: HomeFacade,
    private readonly onboardingFacade: OnboardingFacade,
    private readonly router: Router,
    ) { }

  ngOnInit() {
    this.onboardingFacade.onboardingData$.subscribe(result => {
      if( result ){
        this.onboardingData = result.data;
      }else{
        this.onboardingFacade.onboardingRequest();
      }
    });
    this.homeFacade.notificationsList$.subscribe(result=>{
      if(result){
        this.countNotification = result.data.length;
      }
    });
  }
  showMenu(){
    const userData: AvatarMenu = this.storageService.get(AUTH_USER_INFO);
    this.authFacade.setAvatarData(userData);
    this.menuCtrl.open();
  }
  checkNotifications(){
    this.homeFacade.getNotifications();
    this.router.navigate(['/notifications-list']);

  }
}

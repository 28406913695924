import { Component, OnInit } from '@angular/core';
import { IdataHeader } from '@shared/models/home';
import { PushService } from '@shared/services/push/push.service';
import { HomeFacade } from '@store/home/home.facade';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  dataHeader: IdataHeader | null = null;
  constructor(
    private homeFacade: HomeFacade,
    private pushService: PushService
  ) {
    this.pushService.settingPushInit();
  }

  ngOnInit() {
    this.homeFacade.dataHeader$.subscribe(result => {
      if(result){
        this.dataHeader = result;
      }
    });
  }
}

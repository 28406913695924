/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidatorService } from '@shared/utils/validator.util';
import { AuthFacade } from '@store/auth/auth.facade';
import { HomeFacade } from '@store/home/home.facade';

@Component({
  selector: 'app-change-my-password',
  templateUrl: './change-my-password.component.html',
  styleUrls: ['./change-my-password.component.scss'],
})
export class ChangeMyPasswordComponent implements OnInit {

  readonly form = this.fb.group({
    current_password: ['', Validators.required],
    new_password: ['',Validators.required],
    new_password_confirmation: ['',Validators.required],
  },{
    validators: [this.validatorService.validateNewPassword('new_password', 'new_password_confirmation') ]
  });


  setHiddenPwd2= false;
  constructor(
    private fb: FormBuilder,
    private homeFacade: HomeFacade,
    private validatorService: ValidatorService,
    private authFacade: AuthFacade
  ) { }

  ngOnInit() {
    // const dataHeader = {
    //   setHeader:true,
    //   backButton: true,
    //   titlePage: 'Mis Datos',
    //   bgColor: '#643CBE',
    //   setWing: true,
    //   textColor:'#ffffff',
    //   urlBack:'/my-information/information-list'
    // };
    // this.homeFacade.setDataHeader(dataHeader);

  }

  showPassword2(){
    this.setHiddenPwd2 = !this.setHiddenPwd2;

  }
  showError(campo: string){
    return  this.form.get(campo)?.invalid && this.form.get(campo)?.touched;
  }
  onSave() {
    if (this.form.pristine) {
        console.log('no ha llenado datos-1');
    } else {
      if (this.form.valid) {
          const dataPassword = {
            current_password:this.form.value.current_password,
            new_password:this.form.value.new_password,
            new_password_confirmation:this.form.value.new_password_confirmation,
          };
          this.authFacade.getChangePassword(dataPassword);

      }
      else {
        this.form.markAllAsTouched();
      }
    }

  }
    ionViewDidLeave() {
      this.form.reset();
      this.authFacade.clearDataMyPwd();
  }
}

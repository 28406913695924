import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HOME } from '@shared/static/store.static';
import { HomeState } from './home.reducer';


export const selectHome = createFeatureSelector<HomeState>(HOME);

export const loading = createSelector(
  selectHome,
  (state: HomeState) => state.loading
);

export const homeData = createSelector(
  selectHome,
  (state: HomeState) => state.homeData
);
export const myContactList = createSelector(
  selectHome,
  (state: HomeState) => state.myContactList
);

export const dataHeader = createSelector(
  selectHome,
  (state: HomeState) => state.dataHeader
);

export const notificationsList = createSelector(
  selectHome,
  (state: HomeState) => state.notificationsList
);

export const loadingNotifications = createSelector(
  selectHome,
  (state: HomeState) => state.loadingNotifications
);
export const deleteNotification = createSelector(
  selectHome,
  (state: HomeState) => state.deleteNotification
);


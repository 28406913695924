import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination-list',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.scss']
})
export class PaginationListComponent {
  @Input() totalPages = 1;
  @Input() currentPage = 1;

  @Output() setCurrentPage = new EventEmitter<number>();

  get showAllNumber() { return this.totalPages < 5; }

  get pageList() {
    return Array.from(Array(this.totalPages).keys());
  }

}

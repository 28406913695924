import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectReferralpartner from './referral-partner.selectors';
import { ReferralPartnerState } from './referral-partner.reducer';
import { ReferralPartnerActions } from './referral-partner.actions';
import { RequestFormReferral } from '@shared/models/referral-partner';



@Injectable()
export class ReferralPartnerFacade {
  readonly loading$ = this.store.select(selectReferralpartner.loading);
  readonly infoReferralPartner$ = this.store.select(selectReferralpartner.infoReferralPartner);
  readonly projectsOnSaleList$ = this.store.select(selectReferralpartner.projectsOnSaleList);
  readonly responseFormReferral$ = this.store.select(selectReferralpartner.responseFormReferral);

  constructor(
    private readonly store: Store<ReferralPartnerState>,

  ) { }

  setLoading(loading: ReferralPartnerState['loading']): void {
    this.store.dispatch(ReferralPartnerActions.setLoading({ loading }));
  };
  getReferralPartner() {
    this.store.dispatch(ReferralPartnerActions.getReferralPartner());
  }
  getProjectsOnSale(){
    this.store.dispatch(ReferralPartnerActions.getProjectsOnSale());
  }
  sentFormReferral(requestFormReferral: RequestFormReferral){
    this.store.dispatch(ReferralPartnerActions.sentFormReferral({requestFormReferral}));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderMainComponent } from './components/header-main/header-main.component';
import { IonicModule } from '@ionic/angular';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import {RouterModule} from '@angular/router';
import { FooterNavComponent } from './components/footer-nav/footer-nav.component';
import { PaginationListComponent } from './components/pagination-list/pagination-list.component';
import { ButtonsWasiComponent } from './components/buttons-wasi/buttons-wasi.component';
import { StepsPropertiesComponent } from './components/steps-properties/steps-properties.component';
import { PropertyDetailTwoComponent } from './components/property-detail-two/property-detail-two.component';
import { PropertyDetailOneComponent } from './components/property-detail-one/property-detail-one.component';
import { PlayerYoutubeModalComponent } from './components/player-youtube-modal/player-youtube-modal.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';
import { PinchZoomModule } from './libs/pinch-zoom/pinch-zoom.module';
import { StepsDeliveryComponent } from './components/steps-delivery/steps-delivery.component';
import { LearnMoreFaqComponent } from './components/learn-more-faq/learn-more-faq.component';
import { PopupTermsAndConditionsComponent } from './components/popup-terms-and-conditions/popup-terms-and-conditions.component';



@NgModule({
  declarations: [
    HeaderMainComponent,
    MainMenuComponent,
    FooterNavComponent,
    PaginationListComponent,
    ButtonsWasiComponent,
    StepsPropertiesComponent,
    PropertyDetailTwoComponent,
    PropertyDetailOneComponent,
    PlayerYoutubeModalComponent,
    ImageZoomComponent,
    StepsDeliveryComponent,
    LearnMoreFaqComponent,
    PopupTermsAndConditionsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    PinchZoomModule
  ],
  exports:[
    PaginationListComponent,
    HeaderMainComponent,
    MainMenuComponent,
    FooterNavComponent,
    ButtonsWasiComponent,
    StepsPropertiesComponent,
    PropertyDetailTwoComponent,
    PropertyDetailOneComponent,
    PlayerYoutubeModalComponent,
    ImageZoomComponent,
    StepsDeliveryComponent,
    LearnMoreFaqComponent,
    PopupTermsAndConditionsComponent
  ]
})
export class SharedModule { }

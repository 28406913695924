import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SplashScreen } from '@capacitor/splash-screen';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';


import { TutorialActions } from './tutorial.actions';
import { TutorialFacade } from './tutorial.facade';
import { OnboardingService } from '@shared/services/onboarding/onboarding.service';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';



@Injectable()
export class TutorialEffects {


readonly getHomeTutorialRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(TutorialActions.getHomeTutorialRequest),
  tap(() => this.tutorialFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const homeTutorialData = await lastValueFrom(
        this.tutorialService.getHomeTutorialRequest()
      );
      if(homeTutorialData.status === 'success'){
        return TutorialActions.getHomeTutorialRequestSuccess({ homeTutorialData });
      }else{
        return TutorialActions.getHomeTutorialRequestFail();
      }
    } catch (e) {
      console.error(e);
      return TutorialActions.getHomeTutorialRequestFail();
    }
  }),
  tap(() => this.tutorialFacade.setLoading(false))
)
);


readonly getDetailTutorial$ = createEffect(() =>
this.actions$.pipe(
  ofType(TutorialActions.getDetailTutorialRequest),
  tap(() => this.tutorialFacade.setLoading(true)),
  switchMap(async ({idDetailTuto}) => {
    try {
      const detailTutorial = await lastValueFrom(
        this.tutorialService.getDetailTutorialRequest(idDetailTuto)
      );
      if(detailTutorial.status === 'success'){
        return TutorialActions.getDetailTutorialRequestSuccess({ detailTutorial  });
      }else{
        return TutorialActions.getDetailTutorialRequestFail();
      }
    } catch (e) {
      console.error(e);
      return TutorialActions.getDetailTutorialRequestFail();
    }
  }),
  tap(() => this.tutorialFacade.setLoading(false))
)
);

readonly getTutorialFaqRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(TutorialActions.getTutorialFaqRequest),
  tap(() => this.tutorialFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const tutorialFaqData = await lastValueFrom(
        this.tutorialService.getTutorialFaqRequest()
      );
      if(tutorialFaqData.status === 'success'){
        return TutorialActions.getTutorialFaqRequestSuccess({ tutorialFaqData });
      }else{
        return TutorialActions.getTutorialFaqRequestFail();
      }
    } catch (e) {
      console.error(e);
      return TutorialActions.getTutorialFaqRequestFail();
    }
  }),
  tap(() => this.tutorialFacade.setLoading(false))
)
);

  constructor(
    private readonly actions$: Actions,
    private readonly tutorialFacade: TutorialFacade,
    private readonly tutorialService: TutorialService
  ) {}
}

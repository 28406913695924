/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { IlogOut, InfoUserReset, IrequestChangePwd, ISignin } from '@shared/models/auth';
import { AuthState } from './auth.reducer';


enum AuthActionTypes {
    SET_LOADING = '[AUTH] Set loading',
    SIGNIN = '[AUTH] Signin',
    SIGNIN_SUCCESS = '[AUTH] Signin success',
    SIGNIN_FAIL = '[AUTH] Signin fail',
    MY_INFO_REQUEST = '[AUTH] My info request',
    MY_INFO_REQUEST_SUCCESS = '[AUTH] My info request success',
    MY_INFO_REQUEST_FAIL = '[AUTH] My info request fail',

    GET_CHANGE_PASSWORD = '[AUTH] Get change password',
    GET_CHANGE_PASSWORD_SUCCESS = '[AUTH] Get change password success',
    GET_CHANGE_PASSWORD_FAIL = '[AUTH] Get change password fail',

    GET_CODE_RESET = '[AUTH] Get code reset',
    GET_CODE_RESET_SUCCESS = '[AUTH] Get code reset success',
    GET_CODE_RESET_FAIL = '[AUTH] Get code reset fail',

    GET_RESET_PASSWORD = '[AUTH] Get Reset password',
    GET_RESET_PASSWORD_SUCCESS = '[AUTH] Get Reset password success',
    GET_RESET_PASSWORD_FAIL = '[AUTH] Get Reset password fail',

    CLEAR_CHANGE_PASSWORD_DATA = '[AUTH] Clear change password data',
    CLEAR_AUTH_DATA = '[AUTH] Clear auth data',
    GET_LOG_OUT = '[AUTH] Get log out',
    GET_LOG_OUT_SUCCESS = '[AUTH] Get log out success',
    GET_LOG_OUT_FAIL = '[AUTH] Get log out fail',

    GET_AVATAR_LIST = '[AUTH] Get avatar list',
    GET_AVATAR_LIST_SUCCESS = '[AUTH] Get avatar list success',
    GET_AVATAR_LIST_FAIL = '[AUTH] Get avatar list fail',

    CHANGE_AVATAR = '[AUTH] Change Avatar',
    CHANGE_AVATAR_SUCCESS = '[AUTH] Change avatar success',
    CHANGE_AVATAR_FAIL = '[AUTH] Change avatar fail',

    SET_AVATAR_DATA = '[AUTH] Set avatar data',
    SET_DEVICE_ID = '[AUTH] Set device ID',
}

export const AuthActions = {
    setLoading: createAction(
        AuthActionTypes.SET_LOADING,
        props<{ loading: AuthState['loading'] }>()
      ),
    signIn: createAction(AuthActionTypes.SIGNIN, props<{ signIn: ISignin }>()),
    signInSuccess: createAction(
        AuthActionTypes.SIGNIN_SUCCESS,
        props<{ user: AuthState['user']}>()
    ),
    signInFail: createAction(AuthActionTypes.SIGNIN_FAIL),

    myInfoRequest: createAction(AuthActionTypes.MY_INFO_REQUEST),
    myInfoSuccess: createAction(
        AuthActionTypes.MY_INFO_REQUEST_SUCCESS,
        props<{ myInfoData: AuthState['myInfoData']}>()
    ),
    myInfoFail: createAction(AuthActionTypes.MY_INFO_REQUEST_FAIL),

    getChangePassword: createAction(AuthActionTypes.GET_CHANGE_PASSWORD,
        props<{ newPassword: IrequestChangePwd }>()),

    getChangePasswordSuccess: createAction(
        AuthActionTypes.GET_CHANGE_PASSWORD_SUCCESS,
        props<{ confirmNewPwd: AuthState['confirmNewPwd']}>()
    ),
    getChangePasswordFail: createAction(AuthActionTypes.GET_CHANGE_PASSWORD_FAIL),

    getLogOut: createAction(AuthActionTypes.GET_LOG_OUT,
        props<{ logOut: IlogOut }>()),

    getLogOutSuccess: createAction(
        AuthActionTypes.GET_LOG_OUT_SUCCESS,
        props<{ confirmLogOut: AuthState['confirmLogOut']}>()
    ),
    getLogOutFail: createAction(AuthActionTypes.GET_LOG_OUT_FAIL),

    clearDataMyPwd: createAction(AuthActionTypes.CLEAR_CHANGE_PASSWORD_DATA),
    clearAuthData: createAction(AuthActionTypes.CLEAR_AUTH_DATA),
    getAvatarList: createAction(AuthActionTypes.GET_AVATAR_LIST),
    getAvatarListSuccess: createAction(
        AuthActionTypes.GET_AVATAR_LIST_SUCCESS,
        props<{ avatarList: AuthState['avatarList']}>()
    ),
    getAvatarListFail: createAction(AuthActionTypes.GET_AVATAR_LIST_FAIL),


    changeAvatar: createAction(AuthActionTypes.CHANGE_AVATAR,
        props<{ newAvatar: string }>()),
    changeAvatarSuccess: createAction(
        AuthActionTypes.CHANGE_AVATAR_SUCCESS,
        props<{ resultNewAvatar: AuthState['resultNewAvatar']}>()
    ),
    changeAvatarFail: createAction(AuthActionTypes.CHANGE_AVATAR_FAIL),

    setAvatarData: createAction(
        AuthActionTypes.SET_AVATAR_DATA,
        props<{ avatarData: AuthState['avatarData'] }>()
    ),
    setDeviceId: createAction(
        AuthActionTypes.SET_DEVICE_ID,
        props<{ deviceId: AuthState['deviceId'] }>()
    ),


    getCodeReset: createAction(AuthActionTypes.GET_CODE_RESET,
        props<{ email: string }>()),

    getCodeResetSuccess: createAction(
        AuthActionTypes.GET_CODE_RESET_SUCCESS,
        props<{ responseResetPwd: AuthState['responseResetPwd']}>()
    ),
    getCodeResetFail: createAction(AuthActionTypes.GET_CODE_RESET_FAIL,
        props<{ errorGetCode: any  }>()
    ),

    getResetPassword: createAction(AuthActionTypes.GET_RESET_PASSWORD,
        props<{ infoUserReset: InfoUserReset }>()),

    getResetPasswordSuccess: createAction(
        AuthActionTypes.GET_RESET_PASSWORD_SUCCESS,
        props<{ confirmResetPwd: AuthState['confirmResetPwd']}>()
    ),
    getResetPasswordFail: createAction(AuthActionTypes.GET_RESET_PASSWORD_FAIL),


};

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AvatarList,
  IChangeAvatar,
  IlogOut,
  ImyInfo,
  InfoUserReset,
  IrequestChangePwd,
  ISignin,
  LoginResult,
  ResponseResetPwd,
  ResultChangePwd,
  ResultLogOut } from '@shared/models/auth';
import { environment } from 'src/environments/environment';
// import { StorageService } from '../storage/storage.service';
// import { AUTH_TOKEN_KEY } from '@shared/static/storage.static';


const API_URL = environment.apiUrl;
// const API_URL = `http://157.230.190.228/api-neosgp/wp-json`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    // private readonly storageService: StorageService,
  ) { }

  // getToken(){
  //   const authToken = this.storageService.get(AUTH_TOKEN_KEY);
  //   const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${authToken}`
  //     });

  //   return { headers };
  // }

  signIn(signIn: ISignin) {
    return this.http.post<LoginResult>(`${API_URL}/api/login`,signIn);
  }
  myInfoRequest() {
    return this.http.get<ImyInfo>(`${API_URL}/api/user`);
  }
  getAvatarList() {
    return this.http.get<AvatarList>(`${API_URL}/api/avatar`);
  }
  getChangePassword(newPassword: IrequestChangePwd) {
    return this.http.post<ResultChangePwd>(`${API_URL}/api/user/change-password`,newPassword);
  }
  changeAvatar(newAvatar: string) {
    const dataAvatar = {
      avatar:newAvatar
    };
    return this.http.put<IChangeAvatar>(`${API_URL}/api/user/change-avatar`,dataAvatar);
  }
  getLogOut(logOut: IlogOut) {
    return this.http.post<ResultLogOut>(`${API_URL}/api/user/logout`,logOut);
  }

  getCodeReset(data: string) {
    const dataRecoveryPassword = {
      email:data
    };
    return this.http.post<ResponseResetPwd>(`${API_URL}/api/forgot-password`, dataRecoveryPassword);
  }
  getResetPassword(infoUserReset: InfoUserReset) {
    return this.http.post<ResultChangePwd>(`${API_URL}/api/reset-password`,infoUserReset);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { HomeState } from './home.reducer';


enum HomeActionTypes {
    SET_LOADING = '[HOME] Set loading',

    SET_LOADING_NOTIFICATIONS = '[HOME] Set loading notifications',
    HOME_REQUEST = '[HOME] Home request',
    HOME_RECEIVE_SUCCESS = '[HOME] Home receive success',
    HOME_REQUEST_FAIL = '[HOME] Home request fail',
    SET_DATA_HEADER = '[HOME] Data Header',
    CLEAR_DATA_HEADER = '[HOME] Clear Data Header',
    CLEAR_DATA_MY_INFORMATION = '[HOME] Clear Data Header',

    MY_CONTACTS_REQUEST = '[HOME] My_Contacts request',
    MY_CONTACTS_RECEIVE_SUCCESS = '[HOME] My_Contacts receive success',
    MY_CONTACTS_REQUEST_FAIL = '[HOME] My_Contacts request fail',

    GET_NOTIFICATIONS = '[HOME] Get notifications',
    GET_NOTIFICATIONS_SUCCESS = '[HOME] Get notifications success',
    GET_NOTIFICATIONS_FAIL = '[HOME] Get notifications fail',

    DELETE_NOTIFICATIONS = '[HOME] Delete notifications',
    DELETE_NOTIFICATIONS_SUCCESS = '[HOME] Delete notifications success',
    DELETE_NOTIFICATIONS_FAIL = '[HOME] Delete notifications fail',

}
export const HomeActions = {
    setLoading: createAction(
        HomeActionTypes.SET_LOADING,
        props<{ loading: HomeState['loading'] }>()
      ),
    setLoadingNotifications: createAction(
        HomeActionTypes.SET_LOADING_NOTIFICATIONS,
        props<{ loadingNotifications: HomeState['loadingNotifications'] }>()
      ),
    homeRequest: createAction(HomeActionTypes.HOME_REQUEST),
    homeRequestSuccess: createAction(
        HomeActionTypes.HOME_RECEIVE_SUCCESS,
        props<{ homeData: HomeState['homeData']}>()
    ),
    homeRequestFail: createAction(HomeActionTypes.HOME_REQUEST_FAIL),

    getNotifications: createAction(HomeActionTypes.GET_NOTIFICATIONS),
    getNotificationsSuccess: createAction(
        HomeActionTypes.GET_NOTIFICATIONS_SUCCESS,
        props<{ notificationsList: HomeState['notificationsList']}>()
    ),
    getNotificationsFail: createAction(HomeActionTypes.GET_NOTIFICATIONS_FAIL),

    deleteNotification: createAction(HomeActionTypes.DELETE_NOTIFICATIONS,
        props<{ idNotification: string }>()
    ),
    deleteNotificationSuccess: createAction(
        HomeActionTypes.DELETE_NOTIFICATIONS_SUCCESS,
        props<{ deleteNotification: HomeState['deleteNotification']}>()
    ),
    deleteNotificationFail: createAction(HomeActionTypes.DELETE_NOTIFICATIONS_FAIL),


    setDataHeader: createAction(
        HomeActionTypes.SET_DATA_HEADER,
        props<{ dataHeader: HomeState['dataHeader'] }>()
      ),
    clearDataHeader: createAction(HomeActionTypes.CLEAR_DATA_HEADER),
    clearDataMyInformation: createAction(HomeActionTypes.CLEAR_DATA_MY_INFORMATION),

    myContactsRequest: createAction(HomeActionTypes.MY_CONTACTS_REQUEST),
    myContactsRequestSuccess: createAction(
        HomeActionTypes.MY_CONTACTS_RECEIVE_SUCCESS,
        props<{ myContactList: HomeState['myContactList']}>()
    ),
    myContactsRequestFail: createAction(HomeActionTypes.MY_CONTACTS_REQUEST_FAIL),
};

/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { IlegalesResult, Ionboarding } from '@shared/models/onboarding';
import { TutorialActions } from './tutorial.actions';
import { DetailTutorialResult, HomeTutorialRequest, TutorialFaqResponse } from '@shared/models/tutorial';



export interface TutorialState {
  loading: boolean;
  onboarding: Ionboarding | null;
  legalesData: IlegalesResult | null;
  homeTutorialData: HomeTutorialRequest | null;
  detailTutorial: DetailTutorialResult | null;
  tutorialFaqData: TutorialFaqResponse | null;
}

const initialState: TutorialState = {
  loading: false,
  onboarding:null,
  legalesData:null,
  homeTutorialData:null,
  detailTutorial: null,
  tutorialFaqData:null
};

export const TutorialReducer = createReducer(
  initialState,
  on(TutorialActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(TutorialActions.onboardingRequestSuccess, (state, { onboarding }) => ({
    ...state,
    onboarding
  })),
  on(TutorialActions.getLegalesRequestSuccess, (state, { legalesData }) => ({
    ...state,
    legalesData
  })),

  on(TutorialActions.getHomeTutorialRequestSuccess, (state, { homeTutorialData }) => ({
    ...state,
    homeTutorialData
  })),

  on(TutorialActions.getDetailTutorialRequestSuccess, (state, { detailTutorial }) => ({
    ...state,
    detailTutorial
  })),

  on(TutorialActions.getTutorialFaqRequestSuccess, (state, { tutorialFaqData }) => ({
    ...state,
    tutorialFaqData
  }))
);

import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataMyInfo } from '@shared/models/auth';
import { AuthFacade } from '@store/auth/auth.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-information-list',
  templateUrl: './information-list.component.html',
  styleUrls: ['./information-list.component.scss'],
})
export class InformationListComponent implements OnInit, OnDestroy {
  readonly #subscriptions$ = new Subscription();

  myInfoData: DataMyInfo | null = null;
  avatarDefault = '/assets/default-avatar.png';
  constructor(
    private authFacade: AuthFacade
  ) { }

  ngOnInit() {

    this.authFacade.myInfoRequest();
    this.#subscriptions$.add(
      this.authFacade.myInfoData$.subscribe(result => {
        if(result){
          this.myInfoData = result.data;
        }
      })
    );

  }
  ngOnDestroy(): void {
    this.#subscriptions$.unsubscribe();
  }
}

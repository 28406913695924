/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BlogCategories, BlogDetail, BlogList, BlogParams } from '@shared/models/blog';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  constructor(
    private http: HttpClient,
  ) { }


  getBlogDetail(idPost: string) {
    return this.http.get<BlogDetail>(`${API_URL}/api/blog/posts/${idPost}`);
  }
  getBlogCategories() {
    return this.http.get<BlogCategories>(`${API_URL}/api/blog/categories`);
  }
  getBlogList(paramsData: BlogParams) {
    let pageParams = 'page=1';
    let categoryParams = '';
    if(paramsData.page){
      pageParams = `page=${paramsData.page}`;
    }
    if(paramsData.category){
      categoryParams = `&category=${paramsData.category}`;
    }
    return this.http.get<BlogList>(`${API_URL}/api/blog/posts/?${pageParams}${categoryParams}`);
  }

}

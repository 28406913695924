/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { RequestFormReferral, ResponseFormReferral } from '@shared/models/referral-partner';
import { ReferralPartnerState } from './referral-partner.reducer';

enum ReferralPartnerActionTypes {
    SET_LOADING = '[REFERRAL_PARTNER] Set loading',
    GET_REFERRAL_PARTNER = '[REFERRAL_PARTNER] Get Referral Partner',
    GET_REFERRAL_PARTNER_SUCCESS = '[REFERRAL_PARTNER] Get Referral Partner success',
    GET_REFERRAL_PARTNER_FAIL = '[REFERRAL_PARTNER] Get Referral Partner fail',

    GET_PROJECTS_ON_SALE = '[PROJECTS_ON_SALE] Get Projects On Sale',
    GET_PROJECTS_ON_SALE_SUCCESS = '[PROJECTS_ON_SALE] Get Projects On Sale success',
    GET_PROJECTS_ON_SALE_FAIL = '[PROJECTS_ON_SALE] Get Projects On Sale fail',

    SENT_REFERRAL_FORM = '[REFERRAL_PARTNER] Sent Referral Form',
    SENT_REFERRAL_FORM_SUCCESS = '[REFERRAL_PARTNER] Sent Referral Form success',
    SENT_REFERRAL_FORM_FAIL = '[REFERRAL_PARTNER] Sent Referral Form fail',

}
export const ReferralPartnerActions = {
    setLoading: createAction(
      ReferralPartnerActionTypes.SET_LOADING,
        props<{ loading: ReferralPartnerState['loading'] }>()
      ),
    getReferralPartner: createAction(ReferralPartnerActionTypes.GET_REFERRAL_PARTNER),
    getReferralPartnerSuccess: createAction(
    ReferralPartnerActionTypes.GET_REFERRAL_PARTNER_SUCCESS,
      props<{ infoReferralPartner: ReferralPartnerState['infoReferralPartner']}>()
  ),
  getReferralPartnerFail: createAction(ReferralPartnerActionTypes.GET_REFERRAL_PARTNER_FAIL),

  getProjectsOnSale: createAction(ReferralPartnerActionTypes.GET_PROJECTS_ON_SALE),
  getProjectsOnSaleSuccess: createAction(
    ReferralPartnerActionTypes.GET_PROJECTS_ON_SALE_SUCCESS,
      props<{ projectsOnSaleList: ReferralPartnerState['projectsOnSaleList']}>()
  ),
  getProjectsOnSaleFail: createAction(ReferralPartnerActionTypes.GET_PROJECTS_ON_SALE_FAIL),


  sentFormReferral: createAction(ReferralPartnerActionTypes.SENT_REFERRAL_FORM,
    props<{ requestFormReferral: RequestFormReferral }>()
  ),
  sentFormReferralSuccess: createAction(
    ReferralPartnerActionTypes.SENT_REFERRAL_FORM_SUCCESS,
    props<{ responseFormReferral: ReferralPartnerState['responseFormReferral']}>()
 ),
 sentFormReferralFail: createAction(ReferralPartnerActionTypes.SENT_REFERRAL_FORM_FAIL),
};

import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  public patternpwd = '((?=.*[a-zA-Z])(?=.*\\d)(?=.*\\W)(.{6,20})$)';
  validateNewPassword(password: string, confirmPassword: string){
    return ( formGroup: AbstractControl): ValidationErrors | null =>{
      const pass1 = formGroup.get(password)?.value;
      const pass2 = formGroup.get(confirmPassword)?.value;
      if(pass1 !== pass2){
        formGroup.get(confirmPassword)?.setErrors({noMatch:true});
        return { noMatch: true};
      }

      formGroup.get(confirmPassword)?.setErrors(null);
      return null;
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { StepDelivery } from '@shared/models/properties';

@Component({
  selector: 'app-steps-delivery',
  templateUrl: './steps-delivery.component.html',
  styleUrls: ['./steps-delivery.component.scss'],
})
export class StepsDeliveryComponent implements OnInit {
  @Input() dataSteps: StepDelivery[] | null = null;
  constructor() { }

  ngOnInit() {}

}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PROPERTIES } from '@shared/static/store.static';
import { PropertiesEffects } from './properties.effects';
import { PropertiesFacade } from './properties.facade';
import { PropertiesReducer } from './properties.reducer';


@NgModule({
  imports: [
    StoreModule.forFeature(PROPERTIES, PropertiesReducer),
    EffectsModule.forFeature([PropertiesEffects]),
  ],
  providers: [PropertiesFacade]
})
export class PropertiesModule { }

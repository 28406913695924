/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BenefitsCategories,
  BenefitsDetail,
  BenefitsList,
  BenefitsParams,
  DataIdVisit,
  FavoriteRequest, SettingFavorite } from '@shared/models/benefits';
import { environment } from 'src/environments/environment';


  const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  constructor(
    private http: HttpClient,
  ) { }

  getBenefitsList(paramsData: BenefitsParams) {
    let pageParams = 'page=1';
    let categoryParams = '';
    let favoriteParams = '';
    if(paramsData.page){
      pageParams = `page=${paramsData.page}`;
    }
    if(Number(paramsData.category)){
      categoryParams = `&category=${paramsData.category}`;
    }
    if(Number(paramsData.favorite)){
      favoriteParams = `&favorite=${paramsData.favorite}`;
    }
    return this.http.get<BenefitsList>(`${API_URL}/api/benefits/coupons/?${pageParams}${categoryParams}${favoriteParams}`);
  }


  getBenefitsDetail(idPost: string) {
    return this.http.get<BenefitsDetail>(`${API_URL}/api/benefits/coupon/${idPost}`);
  }

  setBenefitsFavorite(settingFavorite: SettingFavorite) {
    return this.http.get<FavoriteRequest>(`${API_URL}/api/benefits/favorite/${settingFavorite.idBenefit}/${settingFavorite.state}`);
  }
  getBenefitsCategories() {
    return this.http.get<BenefitsCategories>(`${API_URL}/api/benefits/categories`);
  }

  getDownloadVisit(visitDetail: DataIdVisit) {
    const dataVisit = {
      visit_id: visitDetail.visit_id

    };

    return this.http.post<BenefitsDetail>(`${API_URL}/api/benefits/coupon/${visitDetail.id}`, dataVisit);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';



import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';
import { WasiActions } from './wasi.actions';
import { WasiFacade } from './wasi.facade';
import { WasiService } from '@shared/services/onboarding/wasi.service';

@Injectable()
export class WasiEffects {

  readonly getWasiHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getWasiHome),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const wasiHomeData = await lastValueFrom(
            this.wasiService.getWasiHome()
          );
          if(wasiHomeData.status === 'success'){
            return WasiActions.getWasiHomeSuccess({ wasiHomeData });
          }else{
            return WasiActions.getWasiHomeFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getWasiHomeFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );
  readonly getGoodNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getGoodNews),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const goodNewsList = await lastValueFrom(
            this.wasiService.getGoodNews(params)
          );
          if(goodNewsList.status === 'success'){
            return WasiActions.getGoodNewsSuccess({ goodNewsList });
          }else{
            return WasiActions.getGoodNewsFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getGoodNewsFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );
  readonly getGoodNewsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getGoodNewsDetail),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({paramsDetail}) => {
        try {
          const goodNewsDetail = await lastValueFrom(
            this.wasiService.getGoodNewsDetail(paramsDetail)
          );
          if(goodNewsDetail.status === 'success'){
            return WasiActions.getGoodNewsDetailSuccess({ goodNewsDetail });
          }else{
            return WasiActions.getGoodNewsFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getGoodNewsFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );


  readonly getNewsLetters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getNewsLetters),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const newsLetterList = await lastValueFrom(
            this.wasiService.getNewsLetters(params)
          );
          if(newsLetterList.status === 'success'){
            return WasiActions.getNewsLettersSuccess({ newsLetterList });
          }else{
            return WasiActions.getNewsLettersFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getNewsLettersFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );

   // News Letters List Old
   readonly getNewsLettersOld$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getNewsLettersOld),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({newsOldParams}) => {
        try {
          const newsLetterListOld = await lastValueFrom(
            this.wasiService.getNewsLettersOld(newsOldParams)
          );
          if(newsLetterListOld.status === 'success'){
            return WasiActions.getNewsLettersOldSuccess({ newsLetterListOld });
          }else{
            return WasiActions.getNewsLettersOldFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getNewsLettersOldFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );

  readonly getCalls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getCalls),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const callsList = await lastValueFrom(
            this.wasiService.getCalls(params)
          );
          if(callsList.status === 'success'){
            return WasiActions.getCallsSuccess({ callsList });
          }else{
            return WasiActions.getCallsFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getCallsFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );

  readonly getTalks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getTalks),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const talksList = await lastValueFrom(
            this.wasiService.getTalks(params)
          );
          if(talksList.status === 'success'){
            return WasiActions.getTalksSuccess({ talksList });
          }else{
            return WasiActions.getTalksFail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getTalksFail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );

  readonly getImageB64$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WasiActions.getImageB64),
      tap(() => this.wasiFacade.setLoading(true)),
      switchMap(async ({urlImage}) => {
        try {
          const imageB64 = await lastValueFrom(
            this.wasiService.getImageB64(urlImage)
          );
          if(imageB64.status === 'success'){
            return WasiActions.getImageB64Success({ imageB64 });
          }else{
            return WasiActions.getImageB64Fail();
          }
        } catch (e) {
          console.error(e);
          return WasiActions.getImageB64Fail();
        }
      }),
      tap(() => this.wasiFacade.setLoading(false))
    )
  );




  constructor(
    private readonly actions$: Actions,
    private readonly wasiFacade: WasiFacade,
    private readonly wasiService: WasiService,
  ) {}
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PROPERTIES } from '@shared/static/store.static';
import { PropertiesState } from './properties.reducer';

export const selectProperties = createFeatureSelector<PropertiesState>(PROPERTIES);

export const loading = createSelector(
  selectProperties,
  (state: PropertiesState) => state.loading
);

export const propertiesList = createSelector(
  selectProperties,
  (state: PropertiesState) => state.propertiesList
);

export const resultCurrentPropertyOne = createSelector(
  selectProperties,
  (state: PropertiesState) => state.resultCurrentPropertyOne
);

export const resultCurrentPropertyTwo = createSelector(
  selectProperties,
  (state: PropertiesState) => state.resultCurrentPropertyTwo
);
export const currentProcessList = createSelector(
  selectProperties,
  (state: PropertiesState) => state.currentProcessList
);
export const selectedProperty = createSelector(
  selectProperties,
  (state: PropertiesState) => state.selectedProperty
);

export const deliveryHomeResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.deliveryHomeResponse
);

export const locationDeliveryResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.locationDeliveryResponse
);

export const guideDeliveryResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.guideDeliveryResponse
);

export const stepsDeliveryResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.stepsDeliveryResponse
);

export const learnMoreDeliveryResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.learnMoreDeliveryResponse
);

export const faqDeliveryResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.faqDeliveryResponse
);

export const linkInterestResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.linkInterestResponse
);
export const propertiesDetail = createSelector(
  selectProperties,
  (state: PropertiesState) => state.propertiesDetail
);

export const propertyfaqResponse = createSelector(
  selectProperties,
  (state: PropertiesState) => state.propertyfaqResponse
);

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectWasi from './wasi.selectors';
import { WasiState } from './wasi.reducer';
import { WasiActions } from './wasi.actions';
import {
  NewsOldParams,
  SelectedPropertyWasi,
  CallTalkParams,
  WasiGoodNewsParams,
  RequestWasi,
  GoodNewsDetailParams } from '@shared/models/wasi';



@Injectable()
export class WasiFacade {
  readonly loading$ = this.store.select(selectWasi.loading);
  readonly wasiHomeData$ = this.store.select(selectWasi.wasiHomeData);
  readonly goodNewsList$ = this.store.select(selectWasi.goodNewsList);
  readonly goodNewsDetail$ = this.store.select(selectWasi.goodNewsDetail);
  readonly newsLetterList$ = this.store.select(selectWasi.newsLetterList);
  readonly newsLetterListOld$ = this.store.select(selectWasi.newsLetterListOld);
  readonly callsList$ = this.store.select(selectWasi.callsList);
  readonly talksList$ = this.store.select(selectWasi.talksList);
  readonly selectedPropertyWasi$ = this.store.select(selectWasi.selectedPropertyWasi);
  readonly imageB64$ = this.store.select(selectWasi.imageB64);

  constructor(
    private readonly store: Store<WasiState>,
  ) { }

  setLoading(loading: WasiState['loading']): void {
    this.store.dispatch(WasiActions.setLoading({ loading }));
  };


  getWasiHome() {
    this.store.dispatch(WasiActions.getWasiHome());
  }
  getGoodNews(params: WasiGoodNewsParams) {
    this.store.dispatch(WasiActions.getGoodNews({params}));
  }
  getGoodNewsDetail(paramsDetail: GoodNewsDetailParams) {
    this.store.dispatch(WasiActions.getGoodNewsDetail({paramsDetail}));
  }
  getNewsLetters(params: RequestWasi) {
    this.store.dispatch(WasiActions.getNewsLetters({params}));
  }
  getNewsLettersOld(newsOldParams: NewsOldParams) {
    this.store.dispatch(WasiActions.getNewsLettersOld({newsOldParams}));
  }
  selectedPropertyWasi(selectedPropertyWasi: SelectedPropertyWasi) {
    this.store.dispatch(WasiActions.selectedPropertyWasi({selectedPropertyWasi}));
  }
  getCalls(params: CallTalkParams) {
    this.store.dispatch(WasiActions.getCalls({params}));
  }
  getTalks(params: CallTalkParams) {
    this.store.dispatch(WasiActions.getTalks({params}));
  }
  getImageB64(urlImage: string) {
    this.store.dispatch(WasiActions.getImageB64({urlImage}));
  }
  cleanImageB64() {
    this.store.dispatch(WasiActions.getImageB64Success({imageB64:null}));
  }

}

import { Component, OnInit,EventEmitter,Output,OnDestroy } from '@angular/core';
import { DataLegales } from '@shared/models/onboarding';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-popup-terms-and-conditions',
  templateUrl: './popup-terms-and-conditions.component.html',
  styleUrls: ['./popup-terms-and-conditions.component.scss'],
})
export class PopupTermsAndConditionsComponent implements OnInit, OnDestroy {
  @Output() setAgreeTerms = new EventEmitter();
  readonly #subscriptions$ = new Subscription();
  legalesData: DataLegales | null = null;
  animateClass = false;
  isIOS = false;
  constructor(
    private readonly onboardingFacade: OnboardingFacade,
    private platform: Platform,
  ) { }

  ngOnInit() {
    this.isIOS = this.platform.is('ios');
    this.onboardingFacade.getLegalesRequest();
    this.#subscriptions$.add(
      this.onboardingFacade.legalesData$.subscribe(result => {
        if( result ){
          this.legalesData = result.data;
          setTimeout(()=>{
            this.animateClass = true;
          },1000);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.#subscriptions$.unsubscribe();
    this.animateClass = false;
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-buttons-wasi',
  templateUrl: './buttons-wasi.component.html',
  styleUrls: ['./buttons-wasi.component.scss'],
})
export class ButtonsWasiComponent implements OnInit {
@Input() proformaCode: string;
  constructor() { }

  ngOnInit() {}

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';
import { ReferralPartnerFacade } from './referral-partner.facade';
import { ReferralPartnerActions } from './referral-partner.actions';
import { ReferralPartnerService } from '@shared/services/referral-partner/referral-partner.service';
import { AlertService } from '@shared/services/alert/alert.service';
import { Router } from '@angular/router';

@Injectable()
export class ReferralPartnerEffects {

  readonly getReferralPartner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralPartnerActions.getReferralPartner),
      tap(() => this.referralPartnerFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const infoReferralPartner = await lastValueFrom(
            this.referralPartnerService.getReferralPartner()
          );
          if(infoReferralPartner.status === 'success'){
            return ReferralPartnerActions.getReferralPartnerSuccess({ infoReferralPartner });
          }else{
            return ReferralPartnerActions.getReferralPartnerFail();
          }
        } catch (e) {
          console.error(e);
          return ReferralPartnerActions.getReferralPartnerFail();
        }
      }),
      tap(() => this.referralPartnerFacade.setLoading(false))
    )
  );
  readonly getProjectsOnSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ReferralPartnerActions.getProjectsOnSale),
      tap(() => this.referralPartnerFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const projectsOnSaleList = await lastValueFrom(
            this.referralPartnerService.getProjectsOnSale()
          );
          if(projectsOnSaleList.status === 'success'){
            return ReferralPartnerActions.getProjectsOnSaleSuccess({ projectsOnSaleList });
          }else{
            return ReferralPartnerActions.getProjectsOnSaleFail();
          }
        } catch (e) {
          console.error(e);
          return ReferralPartnerActions.getProjectsOnSaleFail();
        }
      }),
      tap(() => this.referralPartnerFacade.setLoading(false))
    )
  );

  readonly sentFormReferral$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ReferralPartnerActions.sentFormReferral),
    tap(() => this.referralPartnerFacade.setLoading(true)),
    switchMap(async ({requestFormReferral}) => {
      try {
        const responseFormReferral = await lastValueFrom(
          this.referralPartnerService.sentFormReferral(requestFormReferral)
        );
        if(responseFormReferral.status === 'success'){
          return ReferralPartnerActions.sentFormReferralSuccess({ responseFormReferral });
        }else{
          const dataMessage = {
            header:responseFormReferral.status,
            message:responseFormReferral.message,
          };
          this.alertService.presentAlert(dataMessage);
          return ReferralPartnerActions.sentFormReferralFail();
        }
      } catch (e) {
        console.error(e);
        return ReferralPartnerActions.sentFormReferralFail();
      }
    }),
    tap(() => this.referralPartnerFacade.setLoading(false))
  )
  );

  readonly sentFormReferralSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReferralPartnerActions.sentFormReferralSuccess),
        tap(({responseFormReferral}) => {
          const dataMessage = {
            header:responseFormReferral.status,
            message:responseFormReferral.message,
          };
          this.router.navigate(['/referral-partner'],{replaceUrl:true});
          this.alertService.presentToast(dataMessage);
        })
      ),
    { dispatch: false }
  );



  constructor(
    private readonly actions$: Actions,
    private readonly referralPartnerFacade: ReferralPartnerFacade,
    private readonly referralPartnerService: ReferralPartnerService,
    private readonly alertService: AlertService,
    private readonly router: Router,
  ) {}
}

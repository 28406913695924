/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CreateNoteForm,
  DataFormSac,
  ResponseAttetionsDetail,
  ResponseAttetionsSac,
  ResponseCreateAttention,
  ResponseNotesFiles,
  ResponseSac,
  ResponseSacForm,
  ResponseStatesSac } from '@shared/models/sac';
import { environment } from 'src/environments/environment';


  const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class SacService {
  constructor(
    private http: HttpClient,
  ) { }
  getSac() {
    return this.http.get<ResponseSac>(`${API_URL}/api/sac/home`);
  }

  getProjectUnit() {
    return this.http.get<ResponseSacForm>(`${API_URL}/api/sac/units`);
  }

  getStatesSac() {
    return this.http.get<ResponseStatesSac>(`${API_URL}/api/sac/states`);
  }
  getAttetionsSac() {
    return this.http.get<ResponseAttetionsSac>(`${API_URL}/api/sac/attentions`);
  }

  getdetailAttetion(idAttetion: string) {
    return this.http.get<ResponseAttetionsDetail>(`${API_URL}/api/sac/detail?id=${idAttetion}`);
  }
  getNotesFiles(idNotesFiles: string) {
    return this.http.get<ResponseNotesFiles>(`${API_URL}/api/sac/files?id=${idNotesFiles}`);
  }


   createAttetions(dataFormSac: DataFormSac) {
    const formData = new FormData();
    formData.append('unit', dataFormSac.unit);
    formData.append('message', dataFormSac.message);
    formData.append('proforma', dataFormSac.proforma);
    for (let i = 0; i < dataFormSac.files.length; i++) {
      if(dataFormSac.files[i].type === 'blob' ){

        formData.append('files[]', dataFormSac.files[i].dataFile, dataFormSac.files[i].name);
      }else{
        formData.append('files[]', dataFormSac.files[i].file);
      }
    }
    formData.forEach((value,key) => {
      console.log(key+'-inService-'+value);
    });
    return this.http.post<ResponseCreateAttention>(`${API_URL}/api/sac/attention`,formData);
  }
  createNote(createNote: CreateNoteForm) {
    const formData = new FormData();
    formData.append('attention', createNote.attention);
    formData.append('message', createNote.message);
    for (let i = 0; i < createNote.files.length; i++) {
      if(createNote.files[i].type === 'blob' ){

        formData.append('files[]', createNote.files[i].dataFile, createNote.files[i].name);
      }else{
        formData.append('files[]', createNote.files[i].file);
      }
    }
    formData.forEach((value,key) => {
      console.log(key+'-inService-'+value);
    });
    return this.http.post<ResponseCreateAttention>(`${API_URL}/api/sac/note`,formData);
  };

}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { IResponsePropertyProcess, PropertiesDetailResponse, PropertyFAQResponse, PropertyItem, ResponseDeliveryHome, ResponseFAQDelivery, ResponseGuideDelivery, ResponseLearnMoreDelivery, ResponseLinksDelivery, ResponseLocationDelivery, ResponseStepsDelivery } from '@shared/models/properties';
import {
  CurrentProcessList,
  ParamsCurrentProperty,
  ParamsProcess,
  PropertiesList,
  ResultCurrentPropertyOne,
  ResultCurrentPropertyTwo } from '@shared/models/properties';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  constructor(
    private http: HttpClient,
  ) { }


  getProperties() {
    return this.http.get<PropertiesList>(`${API_URL}/api/properties`);
  }
  getDetailProperties(proformaCode) {
    return this.http.get<PropertiesDetailResponse>(`${API_URL}/api/properties/detail?proforma_code=${proformaCode}`);
  }

  getCurrentProcess(paramsProcess: ParamsProcess) {
    return this.http.get<IResponsePropertyProcess>(`${API_URL}/api/properties/processes?proforma_code=${paramsProcess.proforma_code}&type_of_financing=${paramsProcess.type_of_financing}`);
  }

  getCurrentPropertyOne(paramsCurrentProperty: ParamsCurrentProperty) {
    const params = new HttpParams()
      .set('number',paramsCurrentProperty.number)
      .set('process',paramsCurrentProperty.process)
      .set('proforma_code',paramsCurrentProperty.proforma_code)
      .set('type_of_financing',paramsCurrentProperty.type_of_financing)
      .set('current',paramsCurrentProperty.current);


    return this.http.get<ResultCurrentPropertyOne>(`${API_URL}/api/properties/steps`,{params});
  }
  getCurrentPropertyTwo(paramsCurrentProperty: ParamsCurrentProperty) {
    const params = new HttpParams()
      .set('number',paramsCurrentProperty.number)
      .set('process',paramsCurrentProperty.process)
      .set('proforma_code',paramsCurrentProperty.proforma_code)
      .set('type_of_financing',paramsCurrentProperty.type_of_financing)
      .set('current',paramsCurrentProperty.current);


    return this.http.get<ResultCurrentPropertyTwo>(`${API_URL}/api/properties/steps`,{params});
  }

  getHomeDelivery(requestDeliveryHome) {
    let params = new HttpParams();
    params = params.set('project_code' , requestDeliveryHome.project_code);
    params = params.set('subdivision' , requestDeliveryHome.subdivision);
    params = params.set('proforma' , requestDeliveryHome.proforma);
    return this.http.get<ResponseDeliveryHome>(`${API_URL}/api/deliveries/home`, {params});
  }

  getLocationDelivery(requestLocationDelivery) {
    let params = new HttpParams();
    params = params.set('project_code' , requestLocationDelivery.project_code);
    params = params.set('subdivision' , requestLocationDelivery.subdivision);
    return this.http.get<ResponseLocationDelivery>(`${API_URL}/api/deliveries/location?`,{params});
  }

  getGuideDelivery(requestGuideDelivery) {
    let params = new HttpParams();
    params = params.set('subdivision' , requestGuideDelivery.subdivision);
    params = params.set('project_code' , requestGuideDelivery.project_code);
    return this.http.get<ResponseGuideDelivery>(`${API_URL}/api/deliveries/guide`,{params});
  }

  getStepsDelivery(requestStepsDelivery) {
    let params = new HttpParams();
    params = params.set('subdivision' , requestStepsDelivery.subdivision);
    params = params.set('project_code' , requestStepsDelivery.project_code);
    params = params.set('type' , requestStepsDelivery.type);
    return this.http.get<ResponseStepsDelivery>(`${API_URL}/api/deliveries/steps`,{params});
  }

  getLearnMoreDelivery(requestLearnMoreDelivery) {
    let params = new HttpParams();
    params = params.set('subdivision' , requestLearnMoreDelivery.subdivision);
    params = params.set('project_code' , requestLearnMoreDelivery.project_code);
    params = params.set('type' , requestLearnMoreDelivery.type);
    return this.http.get<ResponseLearnMoreDelivery>(`${API_URL}/api/deliveries/learn-more`,{params});
  }

  getFAQDelivery(requestFAQDelivery) {
    let params = new HttpParams();
    params = params.set('subdivision' , requestFAQDelivery.subdivision);
    params = params.set('project_code' , requestFAQDelivery.project_code);
    params = params.set('type' , requestFAQDelivery.type);
    return this.http.get<ResponseFAQDelivery>(`${API_URL}/api/deliveries/faqs`,{params});
  }

  getLinksInterest(requestLinkDelivery) {
    let params = new HttpParams();
    params = params.set('subdivision' , requestLinkDelivery.subdivision);
    params = params.set('project_code' , requestLinkDelivery.project_code);
    return this.http.get<ResponseLinksDelivery>(`${API_URL}/api/deliveries/links`,{params});
  }

  getPropertyFaqRequest() {
    return this.http.get<PropertyFAQResponse>(`${API_URL}/api/properties/faqs`);
  }

}

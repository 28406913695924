/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { AvatarList,
  AvatarMenu,
  IChangeAvatar,
  ImyInfo,
  LoginResult,
  ResponseResetPwd,
  ResultChangePwd,
  ResultLogOut } from '@shared/models/auth';
import { AuthActions } from './auth.actions';



export interface AuthState {
  user: LoginResult;
  loading: boolean;
  myInfoData: ImyInfo | null;
  confirmNewPwd: ResultChangePwd | null;
  responseResetPwd: ResponseResetPwd | null;
  erroType: string | null;
  confirmResetPwd: ResultChangePwd | null;
  confirmLogOut: ResultLogOut | null;
  avatarList: AvatarList | null;
  resultNewAvatar: IChangeAvatar | null;
  avatarData: AvatarMenu | null;
  deviceId: string | null;
  errorGetCode: any;
}

const initialState: AuthState = {
  loading: false,
  user: null,
  myInfoData:null,
  confirmNewPwd: null,
  confirmLogOut: null,
  avatarList: null,
  resultNewAvatar: null,
  avatarData: null,
  responseResetPwd: null,
  erroType:null,
  confirmResetPwd: null,
  deviceId: null,
  errorGetCode: null,
};

export const AuthReducer = createReducer(
  initialState,
  on(AuthActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(AuthActions.signInSuccess, (state, { user }) => ({
    ...state,
    user
  })),
  on(AuthActions.myInfoSuccess, (state, { myInfoData }) => ({
    ...state,
    myInfoData
  })),
  on(AuthActions.getChangePasswordSuccess, (state, { confirmNewPwd }) => ({
    ...state,
    confirmNewPwd
  })),
  on(AuthActions.getAvatarListSuccess, (state, { avatarList }) => ({
    ...state,
    avatarList
  })),
  on(AuthActions.changeAvatarSuccess, (state, { resultNewAvatar }) => ({
    ...state,
    resultNewAvatar
  })),
  on(AuthActions.getCodeResetSuccess, (state, { responseResetPwd }) => ({
    ...state,
    responseResetPwd
  })),
  on(AuthActions.getCodeResetFail, (state, { errorGetCode }) => ({
    ...state,
    errorGetCode,
  })),
  on(AuthActions.getResetPasswordSuccess, (state, { confirmResetPwd }) => ({
    ...state,
    confirmResetPwd
  })),
  on(AuthActions.setAvatarData, (state, { avatarData }) => ({
    ...state,
    avatarData
  })),
  on(AuthActions.setDeviceId, (state, { deviceId }) => ({
    ...state,
    deviceId
  })),
  on(AuthActions.getLogOutSuccess, (state, { confirmLogOut }) => ({
    ...state,
    confirmLogOut
  })),
  on(AuthActions.clearAuthData, () => ({
    ...initialState,
  }))
);

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CallsList,
  GoodNewsDetail,
  GoodNewsDetailParams,
  GoodNewsItem,
  GoodNewsList,
  NewsLetterList,
  NewsLetterListOld,
  NewsOldParams,
  RequestWasi,
  ResponseImageB64,
  TalksList, WasiGoodNewsParams, WasiHome, WasiParams } from '@shared/models/wasi';
import { environment } from 'src/environments/environment';


  const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class WasiService {
  constructor(
    private http: HttpClient,
  ) { }


  getWasiHome() {
    return this.http.get<WasiHome>(`${API_URL}/api/wasi/home`);
  }
  getGoodNews(paramsData: WasiGoodNewsParams) {
    let params = new HttpParams();
    params = params.set('page' , 1);
    params = params.set('project_code' , paramsData.project_code);
    params = params.set('subdivision' , paramsData.subdivision);
    if(paramsData.page){
      params = params.set('page' , paramsData.page);
    };
    return this.http.get<GoodNewsList>(`${API_URL}/api/wasi/good-news`,{params});
  }
  getNewsLetters(paramsData: RequestWasi) {
    let params = new HttpParams();
    params = params.set('project_code' , paramsData.project_code);
    params = params.set('subdivision' , paramsData.subdivision);
    return this.http.get<NewsLetterList>(`${API_URL}/api/wasi/newsletters`,{params});
  }
  getGoodNewsDetail(paramsDetail: GoodNewsDetailParams) {
    let params = new HttpParams();
    params = params.set('project_code' , paramsDetail.project_code);
    params = params.set('subdivision' , paramsDetail.subdivision);
    return this.http.get<GoodNewsDetail>(`${API_URL}/api/wasi/good-news/${paramsDetail.id}`,{params});
  }
  getNewsLettersOld(newsOldParams:  NewsOldParams ) {
    let params = new HttpParams();
    if (newsOldParams.page) {
        params = params.set('page',newsOldParams.page);
    }else{
      params = params.set('page' , 1);
    }
    if (newsOldParams.month && newsOldParams.year ) {
      params = params.set('month' , newsOldParams.month);
      params = params.set('year' , newsOldParams.year);
    }
    params = params.set('subdivision' , newsOldParams.subdivision);
    params = params.set('project_code' , newsOldParams.project_code);
   return this.http.get<NewsLetterListOld>(`${API_URL}/api/wasi/newsletters/past-editions`, {params});
  }
  getCalls(paramsData) {
    let params = new HttpParams();
    if (paramsData.page) {
        params = params.set('page',paramsData.page);
    }else{
      params = params.set('page' , 1);
    }
    params = params.set('subdivision' , paramsData.subdivision);
    params = params.set('project_code' , paramsData.project_code);

    return this.http.get<CallsList>(`${API_URL}/api/wasi/calls`,{params});
  }
  getTalks(paramsData) {
    let params = new HttpParams();
    if (paramsData.page) {
        params = params.set('page',paramsData.page);
    }else{
      params = params.set('page' , 1);
    }
    params = params.set('subdivision' , paramsData.subdivision);
    params = params.set('project_code' , paramsData.project_code);
    return this.http.get<TalksList>(`${API_URL}/api/wasi/talks`,{params});
  }
  getImageB64(urlImage: string) {
    return this.http.get<ResponseImageB64>(`${API_URL}/api/utils/base64-from-url?url=${urlImage}`);
  }
}


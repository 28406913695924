import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth-store.module';
import { OnboardingModule } from './onboarding/onboarding-store.module';
import { HomeModule } from './home/home-store.module';
import { BlogModule } from './blog/blog-store.module';
import { BenefitsModule } from './benefits/benefits-store.module';
import { WasiModule } from './wasi/wasi-store.module';
import { PropertiesModule } from './properties/properties-store.module';
import { ReferralPartnerModule } from './referral-partner/referral-partner-store.module';
import { SacModule } from './sac/sac-store.module';
import { TutorialModule } from './tutorials/tutorial-store.module';
import { DocumentsModule } from './documents/documents.module';


@NgModule({
  declarations: [],
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    AuthModule,
    OnboardingModule,
    HomeModule,
    BlogModule,
    BenefitsModule,
    WasiModule,
    PropertiesModule,
    ReferralPartnerModule,
    SacModule,
    TutorialModule,
    DocumentsModule
  ]
})
export class AppStoreModule { }

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HOME } from '@shared/static/store.static';
import { HomeEffects } from './home.effects';
import { HomeFacade } from './home.facade';
import { HomeReducer } from './home.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(HOME, HomeReducer),
    EffectsModule.forFeature([HomeEffects]),
  ],
  providers: [HomeFacade]
})
export class HomeModule { }

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectOnboarding from './onboarding.selectors';
import { OnboardingState } from './onboarding.reducer';
import { OnboardingActions } from './onboarding.actions';



@Injectable()
export class OnboardingFacade {
  readonly loading$ = this.store.select(selectOnboarding.loading);
  readonly onboardingData$ = this.store.select(selectOnboarding.onboardingData);
  readonly legalesData$ = this.store.select(selectOnboarding.legalesData);
  constructor(
    private readonly store: Store<OnboardingState>,
  ) { }

  setLoading(loading: OnboardingState['loading']): void {
    this.store.dispatch(OnboardingActions.setLoading({ loading }));
  };


  onboardingRequest() {
    this.store.dispatch(OnboardingActions.onboardingRequest());
  }
  getLegalesRequest() {
    this.store.dispatch(OnboardingActions.getLegalesRequest());
  }
}

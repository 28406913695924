/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { BlogCategories, BlogDetail, BlogList } from '@shared/models/blog';
import { BlogActions } from './blog.actions';




export interface BlogState {
  loading: boolean;
  loadingCategory: boolean;
  blogDetail: BlogDetail | null;
  blogList: BlogList | null;
  blogCategories: BlogCategories | null;


}

const initialState: BlogState = {
  loading: false,
  loadingCategory: false,
  blogDetail:null,
  blogList:null,
  blogCategories:null,


};

export const BlogReducer = createReducer(
  initialState,
  on(BlogActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(BlogActions.setLoadingCategory, (state, { loadingCategory }) => ({
    ...state,
    loadingCategory,
  })),
  on(BlogActions.getBlogDetailSuccess, (state, { blogDetail }) => ({
    ...state,
    blogDetail
  })),
  on(BlogActions.getBlogListSuccess, (state, { blogList }) => ({
    ...state,
    blogList
  })),
  on(BlogActions.getBlogCategoriesSuccess, (state, { blogCategories }) => ({
    ...state,
    blogCategories
  })),

);

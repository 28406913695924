import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WASI } from '@shared/static/store.static';
import { WasiState } from './wasi.reducer';

export const selectWasi = createFeatureSelector<WasiState>(WASI);

export const loading = createSelector(
  selectWasi,
  (state: WasiState) => state.loading
);

export const wasiHomeData = createSelector(
  selectWasi,
  (state: WasiState) => state.wasiHomeData
);
export const goodNewsList = createSelector(
  selectWasi,
  (state: WasiState) => state.goodNewsList
);
export const goodNewsDetail = createSelector(
  selectWasi,
  (state: WasiState) => state.goodNewsDetail
);
export const newsLetterList = createSelector(
  selectWasi,
  (state: WasiState) => state.newsLetterList
);
export const newsLetterListOld = createSelector(
  selectWasi,
  (state: WasiState) => state.newsLetterListOld
);
export const callsList = createSelector(
  selectWasi,
  (state: WasiState) => state.callsList
);
export const talksList = createSelector(
  selectWasi,
  (state: WasiState) => state.talksList
);
export const selectedPropertyWasi = createSelector(
  selectWasi,
  (state: WasiState) => state.selectedPropertyWasi
);
export const imageB64 = createSelector(
  selectWasi,
  (state: WasiState) => state.imageB64
);

/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { CurrentProcess, ParamsCurrentProperty, ParamsProcess,
  RequestDeliveryHome, RequestFAQDelivery, RequestGuideDelivery,
  RequestLearnMoreDelivery, RequestLinkDelivery, RequestLocationDelivery,
  RequestStepsDelivery } from '@shared/models/properties';
import { PropertiesState } from './properties.reducer';


enum PropertiesActionTypes {
    SET_LOADING = '[PROPERTIES] Set loading',
    GET_PROPERTIES = '[PROPERTIES] Get Properties',
    GET_PROPERTIES_SUCCESS = '[PROPERTIES] Get Properties success',
    GET_PROPERTIES_FAIL = '[PROPERTIES] Get Properties fail',

    GET_DETAIL_PROPERTIES = '[PROPERTIES] Get Detail Properties',
    GET_DETAIL_PROPERTIES_SUCCESS = '[PROPERTIES] Get Detail Properties success',
    GET_DETAIL_PROPERTIES_FAIL = '[PROPERTIES] Get Detail Properties fail',

    GET_CURRENT_PROCESS = '[PROPERTIES] Get current process',
    GET_CURRENT_PROCESS_SUCCESS = '[PROPERTIES] Get current process success',
    GET_CURRENT_PROCESS_FAIL = '[PROPERTIES] Get current process fail',

    GET_CURRENT_PROPERTY_ONE = '[PROPERTIES] Get current property one',
    GET_CURRENT_PROPERTY_ONE_SUCCESS = '[PROPERTIES] Get current property one success',
    GET_CURRENT_PROPERTY_ONE_FAIL = '[PROPERTIES] Get current property one fail',

    GET_CURRENT_PROPERTY_TWO = '[PROPERTIES] Get current property two',
    GET_CURRENT_PROPERTY_TWO_SUCCESS = '[PROPERTIES] Get current property two success',
    GET_CURRENT_PROPERTY_TWO_FAIL = '[PROPERTIES] Get current property two fail',

    GET_HOME_DELIVERY = '[PROPERTIES] Get Home Delivery',
    GET_HOME_DELIVERY_SUCCESS = '[PROPERTIES] Get Home Delivery success',
    GET_HOME_DELIVERY_FAIL = '[PROPERTIES] Get Home Delivery fail',

    GET_LOCATION_DELIVERY = '[PROPERTIES] Get Location Delivery',
    GET_LOCATION_DELIVERY_SUCCESS = '[PROPERTIES] Get Location Delivery success',
    GET_LOCATION_DELIVERY_FAIL = '[PROPERTIES] Get Location Delivery fail',

    GET_GUIDE_DELIVERY = '[PROPERTIES] Get Guide Delivery',
    GET_GUIDE_DELIVERY_SUCCESS = '[PROPERTIES] Get Guide Delivery success',
    GET_GUIDE_DELIVERY_FAIL = '[PROPERTIES] Get Guide Delivery fail',

    GET_STEPS_DELIVERY = '[PROPERTIES] Get Steps Delivery',
    GET_STEPS_DELIVERY_SUCCESS = '[PROPERTIES] Get Steps Delivery success',
    GET_STEPS_DELIVERY_FAIL = '[PROPERTIES] Get Steps Delivery fail',

    GET_LEARN_MORE_DELIVERY = '[PROPERTIES] Get Learn More Delivery',
    GET_LEARN_MORE_DELIVERY_SUCCESS = '[PROPERTIES] Get Learn More success',
    GET_LEARN_MORE_DELIVERY_FAIL = '[PROPERTIES] Get Learn More fail',

    GET_FAQ_DELIVERY = '[PROPERTIES] Get Faq Delivery',
    GET_FAQ_DELIVERY_SUCCESS = '[PROPERTIES] Get Faq Delivery success',
    GET_FAQ_DELIVERY_FAIL = '[PROPERTIES] Get Faq Delivery fail',

    GET_LINKS_INTEREST = '[PROPERTIES] Get Links Interest',
    GET_LINKS_INTEREST_SUCCESS = '[PROPERTIES] Get Links Interest success',
    GET_LINKS_INTEREST_FAIL = '[PROPERTIES] Get Links Interest fail',

    SETUP_SELECTED_PROPERTY = '[PROPERTIES] Setup selected property',
    CLEAN_PROPERTY_DATA = '[PROPERTIES] clean property data',

    GET_PROPERTY_FAQ = '[PROPERTIES] Get Property Faq',
    GET_PROPERTY_FAQ_SUCCESS = '[PROPERTIES] Get Property Faq success',
    GET_PROPERTY_FAQ_FAIL = '[PROPERTIES] Get Property Faq fail',
}
export const PropertiesActions = {
    setLoading: createAction(
        PropertiesActionTypes.SET_LOADING,
        props<{ loading: PropertiesState['loading'] }>()
      ),


    getProperties: createAction(PropertiesActionTypes.GET_PROPERTIES),
    getPropertiesSuccess: createAction(
        PropertiesActionTypes.GET_PROPERTIES_SUCCESS,
        props<{ propertiesList: PropertiesState['propertiesList']}>()
    ),
    getPropertiesFail: createAction(PropertiesActionTypes.GET_PROPERTIES_FAIL),


    getDetailProperties: createAction(PropertiesActionTypes.GET_DETAIL_PROPERTIES,
      props<{ proformaCode: string}>()
    ),
    getDetailPropertiesSuccess: createAction(
        PropertiesActionTypes.GET_DETAIL_PROPERTIES_SUCCESS,
        props<{ propertiesDetail: PropertiesState['propertiesDetail']}>()
    ),
    getDetailPropertiesFail: createAction(PropertiesActionTypes.GET_DETAIL_PROPERTIES_FAIL),



    getCurrentProcess: createAction(PropertiesActionTypes.GET_CURRENT_PROCESS,
        props<{ paramsProcess: ParamsProcess}>()
    ),
    getCurrentProcessSuccess: createAction(
        PropertiesActionTypes.GET_CURRENT_PROCESS_SUCCESS,
        props<{ currentProcessList: PropertiesState['currentProcessList']}>()
    ),
    getCurrentProcessFail: createAction(PropertiesActionTypes.GET_CURRENT_PROCESS_FAIL),


    getCurrentPropertyOne: createAction(PropertiesActionTypes.GET_CURRENT_PROPERTY_ONE,
        props<{ paramsCurrentProperty: ParamsCurrentProperty}>()
    ),
    getCurrentPropertyOneSuccess: createAction(
        PropertiesActionTypes.GET_CURRENT_PROPERTY_ONE_SUCCESS,
        props<{ resultCurrentPropertyOne: PropertiesState['resultCurrentPropertyOne']}>()
    ),
    getCurrentPropertyOneFail: createAction(PropertiesActionTypes.GET_CURRENT_PROPERTY_ONE_FAIL),


    getCurrentPropertyTwo: createAction(PropertiesActionTypes.GET_CURRENT_PROPERTY_TWO,
        props<{ paramsCurrentProperty: ParamsCurrentProperty}>()
    ),
    getCurrentPropertyTwoSuccess: createAction(
        PropertiesActionTypes.GET_CURRENT_PROPERTY_TWO_SUCCESS,
        props<{ resultCurrentPropertyTwo: PropertiesState['resultCurrentPropertyTwo']}>()
    ),
    getCurrentPropertyTwoFail: createAction(PropertiesActionTypes.GET_CURRENT_PROPERTY_TWO_FAIL),

    setupSelectedProperty: createAction(PropertiesActionTypes.SETUP_SELECTED_PROPERTY,
        props<{ selectedProperty: PropertiesState['selectedProperty']}>()
    ),

    cleanPropertyData: createAction(PropertiesActionTypes.CLEAN_PROPERTY_DATA),

    // Process Delivery Actions

    getHomeDelivery: createAction(PropertiesActionTypes.GET_HOME_DELIVERY,
      props<{ requestDeliveryHome: RequestDeliveryHome }>()
    ),
    getHomeDeliverySuccess: createAction(
      PropertiesActionTypes.GET_HOME_DELIVERY_SUCCESS,
      props<{ deliveryHomeResponse: PropertiesState['deliveryHomeResponse']}>()
   ),
   getHomeDeliveryFail: createAction(PropertiesActionTypes.GET_HOME_DELIVERY_FAIL),


   getLocationDelivery: createAction(PropertiesActionTypes.GET_LOCATION_DELIVERY,
    props<{ requestLocationDelivery: RequestLocationDelivery }>()
  ),
  getLocationDeliverySuccess: createAction(
    PropertiesActionTypes.GET_LOCATION_DELIVERY_SUCCESS,
    props<{ locationDeliveryResponse: PropertiesState['locationDeliveryResponse']}>()
 ),
 getLocationDeliveryFail: createAction(PropertiesActionTypes.GET_LOCATION_DELIVERY_FAIL),


  getGuideDelivery: createAction(PropertiesActionTypes.GET_GUIDE_DELIVERY,
    props<{ requestGuideDelivery: RequestGuideDelivery }>()
  ),
  getGuideDeliverySuccess: createAction(
    PropertiesActionTypes.GET_GUIDE_DELIVERY_SUCCESS,
    props<{ guideDeliveryResponse: PropertiesState['guideDeliveryResponse']}>()
  ),
  getGuideDeliveryFail: createAction(PropertiesActionTypes.GET_GUIDE_DELIVERY_FAIL),


  getStepsDelivery: createAction(PropertiesActionTypes.GET_STEPS_DELIVERY,
    props<{ requestStepsDelivery: RequestStepsDelivery }>()
  ),
  getStepsDeliverySuccess: createAction(
    PropertiesActionTypes.GET_STEPS_DELIVERY_SUCCESS,
    props<{ stepsDeliveryResponse: PropertiesState['stepsDeliveryResponse']}>()
  ),
  getStepsDeliveryFail: createAction(PropertiesActionTypes.GET_STEPS_DELIVERY_FAIL),


  getLearnMoreDelivery: createAction(PropertiesActionTypes.GET_LEARN_MORE_DELIVERY,
    props<{ requestLearnMoreDelivery: RequestLearnMoreDelivery }>()
  ),
  getLearnMoreDeliverySuccess: createAction(
    PropertiesActionTypes.GET_LEARN_MORE_DELIVERY_SUCCESS,
    props<{ learnMoreDeliveryResponse: PropertiesState['learnMoreDeliveryResponse']}>()
  ),
  getLearnMoreDeliveryFail: createAction(PropertiesActionTypes.GET_LEARN_MORE_DELIVERY_FAIL),


  getFaqDelivery: createAction(PropertiesActionTypes.GET_FAQ_DELIVERY,
    props<{ requestFAQDelivery: RequestFAQDelivery }>()
  ),
  getFaqDeliverySuccess: createAction(
    PropertiesActionTypes.GET_FAQ_DELIVERY_SUCCESS,
    props<{ faqDeliveryResponse: PropertiesState['faqDeliveryResponse']}>()
  ),
  getFaqDeliveryFail: createAction(PropertiesActionTypes.GET_FAQ_DELIVERY_FAIL),

  getLinksInterest: createAction(PropertiesActionTypes.GET_LINKS_INTEREST,
    props<{ requestLinkDelivery: RequestLinkDelivery }>()
  ),
  getLinksInterestSuccess: createAction(
    PropertiesActionTypes.GET_LINKS_INTEREST_SUCCESS,
    props<{ linkInterestResponse: PropertiesState['linkInterestResponse']}>()
  ),
  getLinksInterestFail: createAction(PropertiesActionTypes.GET_LINKS_INTEREST_FAIL),

  getPropertyFaqRequest: createAction(PropertiesActionTypes.GET_PROPERTY_FAQ),
  getPropertyFaqRequestSuccess: createAction(
      PropertiesActionTypes.GET_PROPERTY_FAQ_SUCCESS,
      props<{ propertyfaqResponse: PropertiesState['propertyfaqResponse']}>()
  ),
  getPropertyFaqRequestFail: createAction(PropertiesActionTypes.GET_PROPERTY_FAQ_FAIL),


};

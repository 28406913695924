import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { WASI } from '@shared/static/store.static';
import { WasiEffects } from './wasi.effects';
import { WasiFacade } from './wasi.facade';
import { WasiReducer } from './wasi.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(WASI, WasiReducer),
    EffectsModule.forFeature([WasiEffects]),
  ],
  providers: [WasiFacade]
})
export class WasiModule { }

import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { HomeFacade } from '@store/home/home.facade';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
})
export class NotificationDetailComponent implements OnInit {

  constructor(
    private homeFacade: HomeFacade,
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {}
  ionViewWillEnter() {
    this.menuCtrl.close();
    // const dataHeader = {
    //   setHeader:true,
    //   backButton: true,
    //   titlePage: 'Notificaciones',
    //   bgColor: '#ffffff',
    //   textColor:'#000000',
    //   urlBack:'/notifications'
    // };
    // this.homeFacade.setDataHeader(dataHeader);
  }
}

import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DOCUMENTS } from '@shared/static/store.static';
import { DocumentsFacade } from './documents.facade';
import { DocumentsEffects } from './documents.effects';
import { DocumentsReducer } from './documents.reducer';



@NgModule({
  imports: [
    StoreModule.forFeature(DOCUMENTS, DocumentsReducer),
    EffectsModule.forFeature([DocumentsEffects]),
  ],
  providers: [DocumentsFacade]
})
export class DocumentsModule { }

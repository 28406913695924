import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MyInformationPageRoutingModule } from './my-information-routing.module';

import { MyInformationPage } from './my-information.page';
import { InformationListComponent } from './pages/information-list/information-list.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MyInformationPageRoutingModule,
    SharedModule
  ],
  declarations: [MyInformationPage, InformationListComponent]
})
export class MyInformationPageModule {}

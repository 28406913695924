import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetHeaderGuard } from 'src/app/guards/set-header.guard';

import { MyInformationPage } from './my-information.page';
import { ChangeMyPasswordComponent } from './pages/change-my-password/change-my-password.component';
import { DetailMyInformationComponent } from './pages/detail-my-information/detail-my-information.component';
import { InformationListComponent } from './pages/information-list/information-list.component';

const routes: Routes = [
  {
    path: '',
    component: MyInformationPage,
    children:[
      {
        path:'information-list',
        canActivate:[SetHeaderGuard],
        data:{
          header:{
            setHeader:true,
            backButton: false,
            titlePage: 'Mis Datos',
            bgColor: '#643CBE',
            setWing: true,
            textColor:'#ffffff'
          }
        },
        component:InformationListComponent
      },
      {
        path:'detail-information/:id',
        canActivate:[SetHeaderGuard],
        data:{
          header:{
            setHeader:true,
            backButton: true,
            titlePage: 'Mis Datos',
            bgColor: '#643CBE',
            setWing: true,
            textColor:'#ffffff',
            urlBack:'/my-information/information-list'
          }
        },
        component:DetailMyInformationComponent
      },
      {
        path:'change-my-password',
        canActivate:[SetHeaderGuard],
        data:{
          header:{
            setHeader:true,
            backButton: true,
            titlePage: 'Mis Datos',
            bgColor: '#643CBE',
            setWing: true,
            textColor:'#ffffff',
            urlBack:'/my-information/information-list'
          }
        },
        component:ChangeMyPasswordComponent
      },
      {
        path:'',
        redirectTo:'information-list',
        pathMatch:'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyInformationPageRoutingModule {}

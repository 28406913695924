import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataMyInfo, InfoItem } from '@shared/models/auth';
import { AuthFacade } from '@store/auth/auth.facade';
import { Subscription } from 'rxjs';
import { IonModal } from '@ionic/angular';

@Component({
  selector: 'app-detail-my-information',
  templateUrl: './detail-my-information.component.html',
  styleUrls: ['./detail-my-information.component.scss'],
})
export class DetailMyInformationComponent implements OnInit, OnDestroy {
  @ViewChild(IonModal) modal: IonModal;
  readonly #subscriptions$ = new Subscription();

  avatarList: string[];
  myInfoData: DataMyInfo | null = null;
  myInfoDataSingle: InfoItem | null = null;
  isTitular = false;
  isModalOpen = false;
  paramsData: string;
  avatarDefault = '/assets/default-avatar.png';
  readonly loading$ = this.authFacade.loading$;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private authFacade: AuthFacade,
  ) { }

  ngOnInit() {
    this.#subscriptions$.add(
      this.route.params.subscribe(params => {
        this.paramsData = params.id;
        // if(this.myInfoData && params){
        //   // const typeInfo = params.id.split('-');

        // }
      })
    );

    this.#subscriptions$.add(
      this.authFacade.myInfoData$.subscribe(result => {
        if(result){
          this.myInfoData = result.data;
          const typeInfo = this.paramsData.split('-');
          if(typeInfo[0] === 'titular'){
            this.myInfoDataSingle = this.myInfoData.titular;
            this.isTitular = true;
          } else{
              const dataCoOwner = this.myInfoData.co_owners.filter((item)=> item.id === typeInfo[1]);
              this.myInfoDataSingle = dataCoOwner[0];
              this.isTitular = false;
          }

        } else{
          this.router.navigate(['/my-information/information-list']);
        }
      })
    );

    this.#subscriptions$.add(
      this.authFacade.avatarList$.subscribe(result => {
        if(result){
          this.avatarList = result.data;
        };
      })
    );

  }
  ngOnDestroy(): void {
    // this.homeFacade.clearDataMyInformation();
    this.#subscriptions$.unsubscribe();
  }

  openModal(isOpen: boolean) {
    this.isModalOpen = isOpen;
    this.authFacade.getAvatarList();

  }


  cancel() {
    this.modal.dismiss(null, 'cancel');
    this.isModalOpen = false;
  }

  selectNewAvatar(avatar: string) {
    console.log('select new avatar', avatar);
    this.authFacade.changeAvatar(avatar);
  }

  onWillDismiss(event: Event) {

    // const ev = event as CustomEvent<OverlayEventDetail<string>>;
    // if (ev.detail.role === 'confirm') {
    //   this.message = `Hello, ${ev.detail.data}!`;
    //   console.log('this.message',this.message);
    // }
  }

}

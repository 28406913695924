import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BENEFITS } from '@shared/static/store.static';
import { BenefitsState } from './benefits.reducer';



export const selectBenefits = createFeatureSelector<BenefitsState>(BENEFITS);

export const loading = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.loading
);
export const loadingCategory = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.loadingCategory
);

export const benefitsList = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.benefitsList
);

export const benefitsDetail = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.benefitsDetail
);

export const benefitsCategories = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.benefitsCategories
);
export const favoriteRequest = createSelector(
  selectBenefits,
  (state: BenefitsState) => state.favoriteRequest
);




/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IHomeData, IMyContacts, ResponseDeleteNotification, ResultNotifications } from '@shared/models/home';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(
    private http: HttpClient,
  ) { }


  homeRequest() {
    return this.http.get<IHomeData>(`${API_URL}/api/home`);
  }
  myContactsRequest() {
    return this.http.get<IMyContacts>(`${API_URL}/api/contacts`);
  }
  getNotifications() {
    return this.http.get<ResultNotifications>(`${API_URL}/api/notifications`);
  }
  deleteNotification(idNotification) {
    return this.http.delete<ResponseDeleteNotification>(`${API_URL}/api/notifications/${idNotification}`);
  }

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SplashScreen } from '@capacitor/splash-screen';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';
import { OnboardingFacade } from './onboarding.facade';
import { OnboardingActions } from './onboarding.actions';
import { OnboardingService } from '@shared/services/onboarding/onboarding.service';

@Injectable()
export class OnboardingEffects {

  readonly onboardingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OnboardingActions.onboardingRequest),
      tap(() => this.onboardingFacade.setLoading(true)),
      switchMap(async () => {
        try {
          const onboarding = await lastValueFrom(
            this.onboardingService.onboardingRequest()
          );
          if(onboarding.status === 'success'){
            return OnboardingActions.onboardingRequestSuccess({ onboarding });
          }else{
            // const dataMessage = {
            //   header:onboarding.status,
            //   message:onboarding.status,
            // };
            // this.alertService.presentAlert(dataMessage);
            return OnboardingActions.onboardingRequestFail();
          }
        } catch (e) {
          console.error(e);
          return OnboardingActions.onboardingRequestFail();
        }
      }),
      tap(() => this.onboardingFacade.setLoading(false))
    )
  );

  readonly onboardingRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OnboardingActions.onboardingRequestSuccess),
        tap(() => {
          SplashScreen.hide();
        })
      ),
    { dispatch: false }
  );

  readonly getLegalesRequest$ = createEffect(() =>
  this.actions$.pipe(
    ofType(OnboardingActions.getLegalesRequest),
    tap(() => this.onboardingFacade.setLoading(true)),
    switchMap(async () => {
      try {
        const legalesData = await lastValueFrom(
          this.onboardingService.getLegalesRequest()
        );
        if(legalesData.status === 'success'){
          return OnboardingActions.getLegalesRequestSuccess({ legalesData });
        }else{
          return OnboardingActions.onboardingRequestFail();
        }
      } catch (e) {
        console.error(e);
        return OnboardingActions.onboardingRequestFail();
      }
    }),
    tap(() => this.onboardingFacade.setLoading(false))
  )
);

  constructor(
    private readonly actions$: Actions,
    private readonly onboardingFacade: OnboardingFacade,
    private readonly onboardingService: OnboardingService,
  ) {}
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseFormReferral, ResponseReferralPartner } from '@shared/models/referral-partner';
import { ResponseProjectsOnSale } from '../../models/referral-partner';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ReferralPartnerService {
  constructor(
    private http: HttpClient,
  ) { }

  getReferralPartner() {
    return this.http.get<ResponseReferralPartner>(`${API_URL}/api/referrals`);
  }
  getProjectsOnSale() {
    return this.http.get<ResponseProjectsOnSale>(`${API_URL}/api/referrals/projects`);
  }
  sentFormReferral(requestFormReferral) {
    const formData = new FormData();
      formData.append('owner_name', requestFormReferral.owner_name);
      formData.append('owner_lastname', requestFormReferral.owner_lastname);
      formData.append('owner_document', requestFormReferral.owner_document);
      formData.append('owner_phone', requestFormReferral.owner_phone);
      formData.append('owner_email', requestFormReferral.owner_email);
      formData.append('owner_project', requestFormReferral.owner_project);
      formData.append('referred_name', requestFormReferral.referred_name);
      formData.append('referred_lastname', requestFormReferral.referred_lastname);
      formData.append('referred_document', requestFormReferral.referred_document);
      formData.append('referred_phone', requestFormReferral.referred_phone);
      formData.append('referred_email', requestFormReferral.referred_email);
      formData.append('referred_project', requestFormReferral.referred_project);
      formData.append('project_slug', requestFormReferral.project_slug);
    return this.http.post<ResponseFormReferral>(`${API_URL}/api/referrals/form`,formData);
  }
}

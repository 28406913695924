import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { callsList } from '../../../store/wasi/wasi.selectors';
import { Router } from '@angular/router';
import { AuthFacade } from '@store/auth/auth.facade';


interface IAlertMessage {
  header: string;
  subHeader?: string;
  message: string;
  buttons?: string;
}
interface IToastMessage {
  header: string;
  message: string;
  icon?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private authFacade: AuthFacade,
    private readonly router: Router
    ) {}
    async presentAlert(props: IAlertMessage) {
        let messageSuccess = '';
        if(props.header === 'success'){
          messageSuccess = 'Éxito';
        }else if (props.header === 'warning'){
          messageSuccess ='Aviso Importante';
        }else{
          messageSuccess = props.header;
        }
        const alert = await this.alertController.create({
          header: messageSuccess,
          subHeader: props.subHeader,
          message: props.message,
          buttons: ['ACEPTAR'],
        });
        await alert.present();
    }
    async presentAlertErrorCode(props: IAlertMessage) {
      let messageSuccess = '';
      if(props.header === 'success'){
        messageSuccess = 'Éxito';
      }else if (props.header === 'warning'){
        messageSuccess ='Aviso Importante';
      }else{
        messageSuccess = props.header;
      }
      const alert = await this.alertController.create({
        header: messageSuccess,
        subHeader: props.subHeader,
        message: props.message,
        buttons: [{
          text: 'ACEPTAR',
          handler: () => {
              this.router.navigate(['/reset-forgotten-password'], { replaceUrl: true });
          }
        }],

      });
     await alert.present();
  }
    async presentToast(props: IToastMessage) {
      const messageSuccess = props.header === 'success' ? 'Éxito' : props.header;
      const toast = await this.toastController.create({
          header  : messageSuccess,
          message: props.message,
          duration: 5000,
          animated:true,
          position: 'bottom',
          cssClass: 'custom-toast',
      });
      await toast.present();
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@shared/services/storage/storage.service';
import { AUTH_TOKEN_KEY } from '@shared/static/storage.static';
import { Observable, throwError } from 'rxjs';
import { catchError, first, map, switchMap} from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AlertService } from '@shared/services/alert/alert.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { MenuController } from '@ionic/angular';
  @Injectable({
    providedIn: 'root',
  })
  export class AuthInterceptorService implements HttpInterceptor {
    constructor(
        private readonly storageService: StorageService,
        private readonly router: Router,
        private readonly alertService: AlertService,
        private menuCtrl: MenuController
        ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('interceptor 1');
        const authToken$ = of(this.storageService.get(AUTH_TOKEN_KEY));
        const messageToast = {
            header:'Su sesión ha expirado.',
            message:'Inicie sesión de nuevo, por favor.',
            icon:'close-circle-outline'

        };
        return authToken$.pipe(
        first(),
        map(token => (token ? req.clone({ setHeaders: { authorization: `Bearer ${token}` } }) : req)),
        switchMap(request =>
          next.handle(request).pipe(
            catchError(err => {
              const errorHttp = JSON.stringify(err);
              const messagError = {
                header:'Lo sentimos, ha ocurrido un error',
                message:errorHttp,
                icon:'close-circle-outline'
              };
              this.alertService.presentAlert(messagError);
              if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    console.log('error 401');
                    this.storageService.clear();
                    this.router.navigate(['/auth/login'],{replaceUrl:true});

                    this.alertService.presentToast(messageToast);

                } else if (err.status === 403) {
                    this.storageService.clear();
                    this.router.navigate(['/auth/login'],{replaceUrl:true});
                    console.log('error 403');
                    this.alertService.presentToast(messageToast);

                } else if (err.status === 404) {
                  const messageToast404 = {
                    header:'Lo sentimos, no encontró el recurso que esta solicitando',
                    message:'Inicie sesión de nuevo, por favor.',
                    icon:'close-circle-outline'
                  };
                  this.storageService.clear();
                  this.router.navigate(['/auth/login'],{replaceUrl:true});
                  console.log('error 404');
                  this.alertService.presentToast(messageToast404);
                }
                  else{
                  const messageToastError = {
                    header:'Ha ocurrido un error, y no podemos conectarnos con el servidor.',
                    message:'Inicie sesión de nuevo, por favor.',
                    icon:'close-circle-outline'
                  };
                  this.storageService.clear();
                  this.router.navigate(['/server-error'],{replaceUrl:true});
                  console.log('error general de servidor');
                  this.alertService.presentToast(messageToastError);
                }
                this.menuCtrl.close();
                SplashScreen.hide();
              }

              return throwError(() => err);
            })
          )
        )
      );
    }
  }

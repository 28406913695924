import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap } from 'rxjs/operators';

import { DocumentsFacade } from './documents.facade';
import { DocumentsService } from '@shared/services/documents/documents.service';
import { DocumentsActions } from './documents.actions';



@Injectable()
export class DocumentsEffects {



readonly getFilesByCategory$ = createEffect(() =>
this.actions$.pipe(
  ofType(DocumentsActions.getFilesByCategoryRequest),
  tap(() => this.documentsFacade.setLoading(true)),
  switchMap(async ({idsRequestFiles}) => {
    try {
      const filesByCategory = await lastValueFrom(
        this.documentsService.getFilesByCategoryRequest(idsRequestFiles)
      );
      if(filesByCategory.status === 'success'){
        return DocumentsActions.getFilesByCategoryRequestSuccess({ filesByCategory  });
      }else{
        return DocumentsActions.getFilesByCategoryRequestFail();
      }
    } catch (e) {
      console.error(e);
      return DocumentsActions.getFilesByCategoryRequestFail();
    }
  }),
  tap(() => this.documentsFacade.setLoading(false))
)
);

readonly getCategoryRequest$ = createEffect(() =>
this.actions$.pipe(
  ofType(DocumentsActions.getCategoryRequest),
  tap(() => this.documentsFacade.setLoading(true)),
  switchMap(async () => {
    try {
      const responseCategory = await lastValueFrom(
        this.documentsService.getCategoryRequest()
      );
      if(responseCategory.status === 'success'){
        return DocumentsActions.getCategoryRequestSuccess({ responseCategory  });
      }else{
        return DocumentsActions.getCategoryRequestFail();
      }
    } catch (e) {
      console.error(e);
      return DocumentsActions.getCategoryRequestFail();
    }
  }),
  tap(() => this.documentsFacade.setLoading(false))
)
);

  constructor(
    private readonly actions$: Actions,
    private readonly documentsFacade: DocumentsFacade,
    private readonly documentsService: DocumentsService

  ) {}
}

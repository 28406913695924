/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IlegalesResult, Ionboarding } from '@shared/models/onboarding';
import { DetailTutorialResult, HomeTutorialRequest, TutorialFaqResponse } from '@shared/models/tutorial';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  constructor(
    private http: HttpClient,
  ) { }

  getLegalesRequest() {
    return this.http.get<IlegalesResult>(`${API_URL}/api/legal`);
  }

  getHomeTutorialRequest() {
    return this.http.get<HomeTutorialRequest>(`${API_URL}/api/tutorials/home`);
  }

  getDetailTutorialRequest(idDetailTuto: string) {
    return this.http.get<DetailTutorialResult>(`${API_URL}/api/tutorials/tutorial/${idDetailTuto}`);
  }

  getTutorialFaqRequest() {
    return this.http.get<TutorialFaqResponse>(`${API_URL}/api/tutorials/faqs`);
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { IlegalesResult, Ionboarding } from '@shared/models/onboarding';
import { OnboardingActions } from './onboarding.actions';


export interface OnboardingState {
  loading: boolean;
  onboarding: Ionboarding | null;
  legalesData: IlegalesResult | null;

}

const initialState: OnboardingState = {
  loading: false,
  onboarding:null,
  legalesData:null,
};

export const OnboardingReducer = createReducer(
  initialState,
  on(OnboardingActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(OnboardingActions.onboardingRequestSuccess, (state, { onboarding }) => ({
    ...state,
    onboarding
  })),
  on(OnboardingActions.getLegalesRequestSuccess, (state, { legalesData }) => ({
    ...state,
    legalesData
  }))
);

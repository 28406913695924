import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TutorialActions } from './tutorial.actions';
import { TutorialState } from './tutorial.reducer';
import * as selectTutorial from './tutorial.selectors';





@Injectable()
export class TutorialFacade {
  readonly loading$ = this.store.select(selectTutorial.loading);
  readonly onboardingData$ = this.store.select(selectTutorial.onboardingData);
  readonly legalesData$ = this.store.select(selectTutorial.legalesData);
  readonly homeTutorialData$ = this.store.select(selectTutorial.homeTutorialData);
  readonly detailTutorial$ = this.store.select(selectTutorial.detailTutorial);
  readonly tutorialFaqData$ = this.store.select(selectTutorial.tutorialFaqData);

  constructor(
    private readonly store: Store<TutorialState>,
  ) { }

  setLoading(loading: TutorialState['loading']): void {
    this.store.dispatch(TutorialActions.setLoading({ loading }));
  };


  getLegalesRequest() {
    this.store.dispatch(TutorialActions.getLegalesRequest());
  }
  getHomeTutorialRequest(){
    this.store.dispatch(TutorialActions.getHomeTutorialRequest());
  }
  getDetailTutorialRequest(idDetailTuto: string){
    this.store.dispatch(TutorialActions.getDetailTutorialRequest({idDetailTuto}));
  }

  getTutorialFaqRequest(){
    this.store.dispatch(TutorialActions.getTutorialFaqRequest());
  }


  }


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataLearnMoreDelivery, FAQDelivery } from '@shared/models/properties';

@Component({
  selector: 'app-learn-more-faq',
  templateUrl: './learn-more-faq.component.html',
  styleUrls: ['./learn-more-faq.component.scss'],
})
export class LearnMoreFaqComponent implements OnInit {
  @Input() dataLearnMore: DataLearnMoreDelivery | null = null;
  @Input() dataFaq: FAQDelivery[] | null = null;
  @Output() setYoutubeUrl = new EventEmitter<string>();
  constructor() { }

  setUrlYoutube(url: string){
    this.setYoutubeUrl.emit(url);
  }
  ngOnInit() {}

}

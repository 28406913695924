import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ONBOARDING } from '@shared/static/store.static';
import { OnboardingState } from './onboarding.reducer';

export const selectOnboarding = createFeatureSelector<OnboardingState>(ONBOARDING);

export const loading = createSelector(
  selectOnboarding,
  (state: OnboardingState) => state.loading
);

export const onboardingData = createSelector(
  selectOnboarding,
  (state: OnboardingState) => state.onboarding
);


export const legalesData = createSelector(
  selectOnboarding,
  (state: OnboardingState) => state.legalesData
);

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectBenefits from './benefits.selectors';
import { BenefitsState } from './benefits.reducer';
import { BenefitsActions } from './benefits.actions';
import { BenefitsParams, SettingFavorite } from '@shared/models/benefits';




@Injectable()
export class BenefitsFacade {
  readonly loading$ = this.store.select(selectBenefits.loading);
  readonly loadingCategory$ = this.store.select(selectBenefits.loadingCategory);
  readonly benefitsDetail$ = this.store.select(selectBenefits.benefitsDetail);
  readonly benefitsList$ = this.store.select(selectBenefits.benefitsList);
  readonly benefitsCategories$ = this.store.select(selectBenefits.benefitsCategories);
  readonly favoriteRequest$ = this.store.select(selectBenefits.favoriteRequest);

  constructor(
    private readonly store: Store<BenefitsState>,
  ) { }

  setLoading(loading: BenefitsState['loading']): void {
    this.store.dispatch(BenefitsActions.setLoading({ loading }));
  };
  setLoadingCategory(loadingCategory: BenefitsState['loadingCategory']): void {
    this.store.dispatch(BenefitsActions.setLoadingCategory({ loadingCategory }));
  };


  getBenefitsList(params: BenefitsParams) {
    this.store.dispatch(BenefitsActions.getBenefitsList({params}) );
  }
  getBenefitsDetail(idPost: string) {
    this.store.dispatch(BenefitsActions.getBenefitsDetail({idPost}) );
  }
  setBenefitsFavorite(settingFavorite: SettingFavorite) {
    this.store.dispatch(BenefitsActions.setBenefitsFavorite({settingFavorite}) );
  }
  getBenefitsCategories() {
    this.store.dispatch(BenefitsActions.getBenefitsCategories() );
  }
  cleanBenefitsData(): void {
    this.store.dispatch(BenefitsActions.cleanBenefitsData());
  }
}

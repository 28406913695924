/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { DocumentsState } from './documents.reducer';
import { IdsRequestFiles } from '@shared/models/documents';

enum DocumentsActionTypes {
  SET_LOADING = '[DCOUMENTS] Set loading',

  DETAIL_TUTORIAL_REQUEST = '[DCOUMENTS] Detail Tutorial request',
  DETAIL_TUTORIAL_REQUEST_SUCCESS = '[DCOUMENTS] Detail Tutorial request success',
  DETAIL_TUTORIAL_REQUEST_FAIL = '[DCOUMENTS] Detail Tutorial request fail',

  GET_FILES_BY_CATEGORY = '[DCOUMENTS] Detail Tutorial request',
  GET_FILES_BY_CATEGORY_SUCCESS = '[DCOUMENTS] Detail Tutorial request success',
  GET_FILES_BY_CATEGORY_FAIL = '[DCOUMENTS] Detail Tutorial request fail',

  GET_CATEGORY = '[DCOUMENTS] Get Category request',
  GET_CATEGORY_SUCCESS = '[DCOUMENTS] Get Category request success',
  GET_CATEGORY_FAIL = '[DCOUMENTS] Get Category request fail',

  // CLEAR_AUTH_DATA = '[AUTH] Clear auth data',
}
export const DocumentsActions = {
  setLoading: createAction(
    DocumentsActionTypes.SET_LOADING,
    props<{ loading: DocumentsState['loading'] }>()
  ),

    getFilesByCategoryRequest: createAction(DocumentsActionTypes.GET_FILES_BY_CATEGORY,
      props<{ idsRequestFiles: IdsRequestFiles }>()
      ),
    getFilesByCategoryRequestSuccess: createAction(
        DocumentsActionTypes.GET_FILES_BY_CATEGORY_SUCCESS,
          props<{filesByCategory: DocumentsState['filesByCategory']}>()
    ),
    getFilesByCategoryRequestFail: createAction(DocumentsActionTypes.GET_FILES_BY_CATEGORY_FAIL),


    getCategoryRequest: createAction(DocumentsActionTypes.GET_CATEGORY),
    getCategoryRequestSuccess: createAction(
        DocumentsActionTypes.GET_CATEGORY_SUCCESS,
          props<{responseCategory: DocumentsState['responseCategory']}>()
    ),
    getCategoryRequestFail: createAction(DocumentsActionTypes.GET_CATEGORY_FAIL),

};

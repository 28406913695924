import { Component, OnInit } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';
@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

  async  dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const s = /data:(image\/[a-zA-Z]+);/;
    const type = s.exec(dataUrl)?.[1] || 'image/png';
    const response: Response = await fetch(dataUrl);
    const blob: Blob = await response.blob();
    return new File([blob], fileName, { type });
}

  takePicture = async () => {
    try{
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        // resultType: CameraResultType.DataUrl
        resultType: CameraResultType.Uri
        // resultType: CameraResultType.Base64
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      // const imageUrl = image.dataUrl; //base 64 listo sin data:image ...
      const imageUrl = image.webPath;
      // const imageUrl = 'data:image/png;base64,' + image.base64String;
      console.log('imageUrl',imageUrl);
      // console.log('imageUrl', this.dataUrlToFile(imageUrl,'testxx1'));
      // imageElement.src = imageUrl;
      // Can be set to the src of an image now
    }catch (e){
      console.log('catch', e);
    }

  };

}

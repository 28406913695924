import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppStoreModule } from '@store/store.module';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PagesModule } from './pages/pages.module';
import { ConnectedGuard } from './guards/connected.guard';
import { SharedModule } from '@shared/shared.module';
import { AuthGuard } from './guards/auth.guard';
import { SetHeaderGuard } from './guards/set-header.guard';
import { AuthInterceptorService } from './interceptor/auth-interceptor.service';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { VideoEditor } from '@awesome-cordova-plugins/video-editor/ngx';
// import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    AppStoreModule,
    PagesModule,
    SharedModule
  ],
  providers: [
    ConnectedGuard,
    AuthGuard,
    SocialSharing,
    SetHeaderGuard,
    MediaCapture,
    VideoEditor,
    // OneSignal,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { BlogParams } from '@shared/models/blog';
import { BlogState } from './blog.reducer';



enum BlogActionTypes {
    SET_LOADING = '[BLOG] Set loading',
    SET_LOADING_CATEGORY = '[BLOG] Set loading category',

    GET_BLOG_LIST = '[BLOG] Get blog list',
    GET_BLOG_LIST_SUCCESS = '[BLOG] Get blog list success',
    GET_BLOG_LIST_FAIL = '[BLOG] Get blog list fail',

    GET_BLOG_DETAIL = '[BLOG] Get blog detail',
    GET_BLOG_DETAIL_SUCCESS = '[BLOG] Get blog detail success',
    GET_BLOG_DETAIL_FAIL = '[BLOG] Get blog detail fail',

    GET_BLOG_CATEGORIES = '[BLOG] Get blog categories',
    GET_BLOG_CATEGORIES_SUCCESS = '[BLOG] Get blog categories success',
    GET_BLOG_CATEGORIES_FAIL = '[BLOG] Get blog categories fail',

}
export const BlogActions = {
    setLoading: createAction(
        BlogActionTypes.SET_LOADING,
        props<{ loading: BlogState['loading'] }>()
      ),
    setLoadingCategory: createAction(
        BlogActionTypes.SET_LOADING_CATEGORY,
        props<{ loadingCategory: BlogState['loadingCategory'] }>()
      ),

    getBlogList: createAction(BlogActionTypes.GET_BLOG_LIST,
        props<{ params: BlogParams}>()
    ),
    getBlogListSuccess: createAction(
        BlogActionTypes.GET_BLOG_LIST_SUCCESS,
        props<{ blogList: BlogState['blogList']}>()
    ),
    getBlogListFail: createAction(BlogActionTypes.GET_BLOG_LIST_FAIL),


    getBlogDetail: createAction(BlogActionTypes.GET_BLOG_DETAIL,
        props<{ idPost: string}>()
    ),

    getBlogDetailSuccess: createAction(
        BlogActionTypes.GET_BLOG_DETAIL_SUCCESS,
        props<{ blogDetail: BlogState['blogDetail']}>()
    ),
    getBlogDetailFail: createAction(BlogActionTypes.GET_BLOG_DETAIL_FAIL),


    getBlogCategories: createAction(BlogActionTypes.GET_BLOG_CATEGORIES),
    getBlogCategoriesSuccess: createAction(
        BlogActionTypes.GET_BLOG_CATEGORIES_SUCCESS,
        props<{ blogCategories: BlogState['blogCategories']}>()
    ),
    getBlogCategoriesFail: createAction(BlogActionTypes.GET_BLOG_CATEGORIES_FAIL),
};

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectSac from './sac.selectors';
import { SacState } from './sac.reducer';
import { SacActions } from './sac.actions';
import { CreateNoteForm } from '@shared/models/sac';



@Injectable()
export class SacFacade {
  readonly loading$ = this.store.select(selectSac.loading);
  readonly loadingState$ = this.store.select(selectSac.loadingState);
  readonly sacInfo$ = this.store.select(selectSac.sacInfo);
  readonly sacFormList$ = this.store.select(selectSac.sacFormList);
  readonly sacStates$ = this.store.select(selectSac.sacStates);
  readonly sacAttetions$ = this.store.select(selectSac.sacAttetions);
  readonly newAttention$ = this.store.select(selectSac.newAttention);
  readonly detailAttetion$ = this.store.select(selectSac.detailAttetion);
  readonly notesFiles$ = this.store.select(selectSac.notesFiles);
  readonly newNote$ = this.store.select(selectSac.newNote);


  constructor(
    private readonly store: Store<SacState>,
  ) { }

  setLoading(loading: SacState['loading']): void {
    this.store.dispatch(SacActions.setLoading({ loading }));
  };
  setLoadingState(loadingState: SacState['loadingState']): void {
    this.store.dispatch(SacActions.setLoadingState({ loadingState }));
  };
  getSac() {
    this.store.dispatch(SacActions.getSac());
  }
  getProjectUnit() {
    this.store.dispatch(SacActions.getProjectUnit());
  }
  getStatesSac() {
    this.store.dispatch(SacActions.getStatesSac());
  }
  getAttetionsSac() {
    this.store.dispatch(SacActions.getAttetionsSac());
  }
  createAttetions(dataFormSac) {
    this.store.dispatch(SacActions.createAttetions({dataFormSac}));
  }
  getdetailAttetions(idAttetion: string) {
    this.store.dispatch(SacActions.getDetailAttention({idAttetion}));
  }
  getNotesFiles(idNotesFiles: string) {
    this.store.dispatch(SacActions.getNotesFiles({idNotesFiles}));
  }
  createNote(createNote: CreateNoteForm) {
    this.store.dispatch(SacActions.createNote({createNote}));
  }
}

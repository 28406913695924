/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { BenefitsParams, SettingFavorite } from '@shared/models/benefits';
import { BenefitsState } from './benefits.reducer';



enum BenefitsActionTypes {
    SET_LOADING = '[BENEFITS] Set loading',
    SET_LOADING_CATEGORY = '[BENEFITS] Set loading category',

    GET_BENEFITS_LIST = '[BENEFITS] Get benefits list',
    GET_BENEFITS_LIST_SUCCESS = '[BENEFITS] Get benefits list success',
    GET_BENEFITS_LIST_FAIL = '[BENEFITS] Get benefits list fail',

    GET_BENEFITS_DETAIL = '[BENEFITS] Get benefits detail',
    GET_BENEFITS_DETAIL_SUCCESS = '[BENEFITS] Get benefits detail success',
    GET_BENEFITS_DETAIL_FAIL = '[BENEFITS] Get benefits detail fail',

    GET_BENEFITS_CATEGORIES = '[BENEFITS] Get benefits categories',
    GET_BENEFITS_CATEGORIES_SUCCESS = '[BENEFITS] Get benefits categories success',
    GET_BENEFITS_CATEGORIES_FAIL = '[BENEFITS] Get benefits categories fail',

    SET_BENEFITS_FAVORITE = '[BENEFITS] Set benefits favorite',
    SET_BENEFITS_FAVORITE_SUCCESS = '[BENEFITS] Set benefits favorite success',
    SET_BENEFITS_FAVORITE_FAIL = '[BENEFITS] Set benefits favorite fail',

    CLEAN_BENEFITS_DATA = '[BENEFITS] clean benefits data',

}
export const BenefitsActions = {
    setLoading: createAction(
        BenefitsActionTypes.SET_LOADING,
        props<{ loading: BenefitsState['loading'] }>()
      ),
    setLoadingCategory: createAction(
        BenefitsActionTypes.SET_LOADING_CATEGORY,
        props<{ loadingCategory: BenefitsState['loadingCategory'] }>()
      ),

    getBenefitsList: createAction(BenefitsActionTypes.GET_BENEFITS_LIST,
        props<{ params: BenefitsParams}>()
    ),
    getBenefitsListSuccess: createAction(
        BenefitsActionTypes.GET_BENEFITS_LIST_SUCCESS,
        props<{ benefitsList: BenefitsState['benefitsList']}>()
    ),
    getBenefitsListFail: createAction(BenefitsActionTypes.GET_BENEFITS_LIST_FAIL),

    getBenefitsDetail: createAction(BenefitsActionTypes.GET_BENEFITS_DETAIL,
        props<{ idPost: string}>()
    ),
    getBenefitsDetailSuccess: createAction(
        BenefitsActionTypes.GET_BENEFITS_DETAIL_SUCCESS,
        props<{ benefitsDetail: BenefitsState['benefitsDetail']}>()
    ),
    getBenefitsDetailFail: createAction(BenefitsActionTypes.GET_BENEFITS_DETAIL_FAIL),

    getBenefitsCategories: createAction(BenefitsActionTypes.GET_BENEFITS_CATEGORIES),
    getBenefitsCategoriesSuccess: createAction(
        BenefitsActionTypes.GET_BENEFITS_CATEGORIES_SUCCESS,
        props<{ benefitsCategories: BenefitsState['benefitsCategories']}>()
    ),
    getBenefitsCategoriesFail: createAction(BenefitsActionTypes.GET_BENEFITS_CATEGORIES_FAIL),


    setBenefitsFavorite: createAction(BenefitsActionTypes.SET_BENEFITS_FAVORITE,
        props<{ settingFavorite: SettingFavorite}>()
    ),
    setBenefitsFavoriteSuccess: createAction(
        BenefitsActionTypes.SET_BENEFITS_FAVORITE_SUCCESS,
        props<{ favoriteRequest: BenefitsState['favoriteRequest']}>()
    ),
    setBenefitsFavoriteFail: createAction(BenefitsActionTypes.SET_BENEFITS_FAVORITE_FAIL),

    cleanBenefitsData: createAction(BenefitsActionTypes.CLEAN_BENEFITS_DATA),
};

import { Component, OnInit } from '@angular/core';
import { DataOnboarding , OptionsOmboarding } from '@shared/models/onboarding';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit {
  readonly #subscriptions$ = new Subscription();
  onboardingData: DataOnboarding | null = null;
  constructor(
    private readonly onboardingFacade: OnboardingFacade,


  ) { }

  ngOnInit() {
    this.#subscriptions$.add(
      this.onboardingFacade.onboardingData$.subscribe(result => {
        if (result) {
          this.onboardingData = result.data;
        }
      })
    );

  }


}

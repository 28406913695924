
import { Component, OnInit, Input, Output, EventEmitter,Inject } from '@angular/core';
import {
  DataCurrentPropertyTwo,
  DataPropertyProcess,
  PropertyItem,
  PropertyProcessItems,
  SelectedProperty } from '@shared/models/properties';
import { DomSanitizer, SafeUrl, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-property-detail-two',
  templateUrl: './property-detail-two.component.html',
  styleUrls: ['./property-detail-two.component.scss'],
})
export class PropertyDetailTwoComponent implements OnInit {
  @Input() propertyInfoTwo: PropertyProcessItems;
  @Input() allDataProcess: DataPropertyProcess;
  @Input() removeIframeYt: boolean;
  @Input() currentProperty: PropertyItem;
  @Input() showButtonDelivery: boolean;
  @Output() getRemoveYoutube = new EventEmitter();

  currentUrlYoutube: SafeUrl | null = null;
  iframeHtml: SafeHtml | null = null;

  constructor(
    private readonly router: Router,
    protected sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
      console.log('Properties' , this.propertyInfoTwo);
  }
  youTubeGetID(url){
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
 };
  openVideoModal(urlYoutube: string){
    const idYoutube = this.youTubeGetID(urlYoutube);
    const urlBase = 'https://www.youtube.com/embed/';
    const urlparams = '?rel=0&enablejsapi=1&version=3&playerapiid=ytplayer&controls=1&loop=1&showinfo=0&amp;autoplay=0';
    const urlBuild = `${urlBase}${idYoutube}${urlparams}`;
     this.currentUrlYoutube = urlBuild;
     // this.currentUrlYoutube = this.sanitizer.bypassSecurityTrustResourceUrl(urlBuild);
     const buildHtml = `
     <iframe
     data-hj-allow-iframe=""
     width="560" height="315"
     src="${this.currentUrlYoutube}"
     class="iframe-video"
     frameborder="0"
     allowfullscreen></iframe>
     `;
     this.iframeHtml = this.sanitizer.bypassSecurityTrustHtml(buildHtml);
     this.getRemoveYoutube.emit(true);
   };
   closeCustomModal() {
     this.currentUrlYoutube = null;
     this.iframeHtml = null;
     this.getRemoveYoutube.emit(false);
   }
}

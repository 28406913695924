/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { OnboardingState } from './onboarding.reducer';

enum OnboardingActionTypes {
    SET_LOADING = '[ONBOARDING] Set loading',
    ONBOARDING_REQUEST = '[ONBOARDING] Onboarding request',
    ONBOARDING_RECEIVE_SUCCESS = '[ONBOARDING] Onboarding receive success',
    ONBOARDING_REQUEST_FAIL = '[ONBOARDING] Onboarding request fail',

    LEGALES_REQUEST = '[ONBOARDING] Legales request',
    LEGALES_REQUEST_SUCCESS = '[ONBOARDING] Legales request success',
    LEGALES_REQUEST_FAIL = '[ONBOARDING] Legales request fail',
}
export const OnboardingActions = {
    setLoading: createAction(
        OnboardingActionTypes.SET_LOADING,
        props<{ loading: OnboardingState['loading'] }>()
      ),
    onboardingRequest: createAction(OnboardingActionTypes.ONBOARDING_REQUEST),
    onboardingRequestSuccess: createAction(
        OnboardingActionTypes.ONBOARDING_RECEIVE_SUCCESS,
        props<{ onboarding: OnboardingState['onboarding']}>()
    ),
    onboardingRequestFail: createAction(OnboardingActionTypes.ONBOARDING_REQUEST_FAIL),

    getLegalesRequest: createAction(OnboardingActionTypes.LEGALES_REQUEST),
    getLegalesRequestSuccess: createAction(
        OnboardingActionTypes.LEGALES_REQUEST_SUCCESS,
        props<{ legalesData: OnboardingState['legalesData']}>()
    ),
    getLegalesRequestFail: createAction(OnboardingActionTypes.LEGALES_REQUEST_FAIL),
};

export const AUTH = 'AUTH';
export const ONBOARDING = 'ONBOARDING';
export const HOME = 'HOME';
export const BLOG = 'BLOG';
export const BENEFITS = 'BENEFITS';
export const WASI = 'WASI';
export const PROPERTIES = 'PROPERTIES';
export const REFERRAL_PARTNER = 'REFERRAL_PARTNER';
export const SAC = 'SAC';
export const TUTORIAL = 'TUTORIAL';
export const DOCUMENTS = 'DOCUMENTS';

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { BLOG } from '@shared/static/store.static';
import { BlogEffects } from './blog.effects';
import { BlogFacade } from './blog.facade';
import { BlogReducer } from './blog.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(BLOG, BlogReducer),
    EffectsModule.forFeature([BlogEffects]),
  ],
  providers: [BlogFacade]
})
export class BlogModule { }

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectAuth from './auth.selectors';
import { AuthActions } from './auth.actions';
import { AuthState } from './auth.reducer';
import { AvatarMenu, IlogOut, InfoUserReset, IrequestChangePwd, ISignin } from '@shared/models/auth';


@Injectable()
export class AuthFacade {
  readonly loading$ = this.store.select(selectAuth.loading);
  readonly user$ = this.store.select(selectAuth.user);
  readonly myInfoData$ = this.store.select(selectAuth.myInfoData);
  readonly confirmNewPwd$ = this.store.select(selectAuth.confirmNewPwd);
  readonly avatarList$ = this.store.select(selectAuth.avatarList);
  readonly resultNewAvatar$ = this.store.select(selectAuth.resultNewAvatar);
  readonly setDataAvatar$ = this.store.select(selectAuth.setDataAvatar);
  readonly responseResetPwd$ = this.store.select(selectAuth.responseResetPwd);
  readonly confirmResetPwd$ = this.store.select(selectAuth.confirmResetPwd);
  readonly deviceId$ = this.store.select(selectAuth.deviceId);
  readonly errorGetCode$ = this.store.select(selectAuth.errorGetCode);
  constructor(
    private readonly store: Store<AuthState>,
  ) { }

  setLoading(loading: AuthState['loading']): void {
    this.store.dispatch(AuthActions.setLoading({ loading }));
  };
  signin(signIn: ISignin) {
    this.store.dispatch(AuthActions.signIn({ signIn }));
  }
  myInfoRequest() {
    this.store.dispatch(AuthActions.myInfoRequest());
  }
  getAvatarList() {
    this.store.dispatch(AuthActions.getAvatarList());
  }
  getChangePassword(newPassword: IrequestChangePwd) {
    this.store.dispatch(AuthActions.getChangePassword({ newPassword }));
  }
  getLogOut(logOut: IlogOut) {
    this.store.dispatch(AuthActions.getLogOut({ logOut }));
  }
  setAvatarData(avatarData: AvatarMenu) {
    this.store.dispatch(AuthActions.setAvatarData({ avatarData }));
  }
  setDeviceId(deviceId: string) {
    this.store.dispatch(AuthActions.setDeviceId({ deviceId }));
  }
  changeAvatar(newAvatar: string) {
    this.store.dispatch(AuthActions.changeAvatar({ newAvatar }));
  }
  clearDataMyPwd() {
    this.store.dispatch(AuthActions.getChangePasswordSuccess({ confirmNewPwd: null }));
  }
  clearAuthData() {
    this.store.dispatch(AuthActions.clearAuthData());
  }

  getCodeReset(email: string) {
    this.store.dispatch(AuthActions.getCodeReset({ email }));
  }
  getResetPassword(infoUserReset: InfoUserReset) {
    this.store.dispatch(AuthActions.getResetPassword({ infoUserReset }));
  }
}

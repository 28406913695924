import { Component, OnInit } from '@angular/core';
import { DataLegales } from '@shared/models/onboarding';
import { HomeFacade } from '@store/home/home.facade';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { Subscription } from 'rxjs';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  readonly #subscriptions$ = new Subscription();
  legalesData: DataLegales | null = null;
  constructor(
    private homeFacade: HomeFacade,
    private readonly onboardingFacade: OnboardingFacade,
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {
    this.onboardingFacade.getLegalesRequest();
    this.#subscriptions$.add(
      this.onboardingFacade.legalesData$.subscribe(result => {
        if( result ){
          this.legalesData = result.data;
        }
      })
    );
  }
  ionViewWillEnter() {
    this.menuCtrl.close();
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectDocuments from './documents.selectors';
import { DocumentsState } from './documents.reducer';
import { DocumentsActions } from './documents.actions';
import { IdsRequestFiles } from '@shared/models/documents';





@Injectable()
export class DocumentsFacade {
  readonly loading$ = this.store.select(selectDocuments.loading);
  readonly filesByCategory$ = this.store.select(selectDocuments.filesByCategory);
  readonly responseCategory$ = this.store.select(selectDocuments.responseCategory);

  constructor(
    private readonly store: Store<DocumentsState>,
  ) { }

  setLoading(loading: DocumentsState['loading']): void {
    this.store.dispatch(DocumentsActions.setLoading({ loading }));
  };

  getFilesByCategory(idsRequestFiles: IdsRequestFiles) {
    this.store.dispatch(DocumentsActions.getFilesByCategoryRequest({idsRequestFiles}));
  }

  getCategoryRequest() {
    this.store.dispatch(DocumentsActions.getCategoryRequest());
  }

  }


/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { CreateNoteForm, DataFormSac } from '@shared/models/sac';
import { SacState } from './sac.reducer';

enum SacActionTypes {
    SET_LOADING = '[SAC] Set loading loading',
    SET_LOADING_STATES = '[SAC] Set loading states loading',
    GET_SAC = '[SAC] Get Sac',
    GET_SAC_SUCCESS = '[SAC] Get Sac success',
    GET_SAC_FAIL = '[SAC] Get Sac fail',

    GET_PROJECT_UNIT = '[SAC] Get Project Unit',
    GET_PROJECT_UNIT_SUCCESS = '[SAC] Get Project Unit success',
    GET_PROJECT_UNIT_FAIL = '[SAC] Get Project Unit fail',

    GET_STATES_SAC = '[SAC] Get States Sac',
    GET_STATES_SAC_SUCCESS = '[SAC] Get States Sac success',
    GET_STATES_SAC_FAIL = '[SAC] Get States Sac fail',

    GET_ATTETIONS_SAC = '[SAC] Get Attetions Sac',
    GET_ATTETIONS_SAC_SUCCESS = '[SAC] Get Attetions Sac success',
    GET_ATTETIONS_SAC_FAIL = '[SAC] Get Attetions Sac fail',

    CREATE_ATTETION = '[SAC] Create Attetion',
    CREATE_ATTETION_SUCCESS = '[SAC] Create Attetion success',
    CREATE_ATTETION_FAIL = '[SAC] Create Attetion fail',

    CREATE_NOTE = '[SAC] Create note',
    CREATE_NOTE_SUCCESS = '[SAC] Create note success',
    CREATE_NOTE_FAIL = '[SAC] Create note fail',

    GET_DETAIL_ATTETION = '[SAC] Get Detail Attetion',
    GET_DETAIL_ATTETION_SUCCESS = '[SAC] Get Detail Attetion success',
    GET_DETAIL_ATTETION_FAIL = '[SAC] Get Detail Attetion fail',

    GET_NOTES_FILES = '[SAC] Get Notes Files',
    GET_NOTES_FILES_SUCCESS = '[SAC] Get Notes Files success',
    GET_NOTES_FILES_FAIL = '[SAC] Get Notes Files fail',

}
export const SacActions = {
    setLoading: createAction(
      SacActionTypes.SET_LOADING,
        props<{ loading: SacState['loading'] }>()
    ),

    setLoadingState: createAction(
      SacActionTypes.SET_LOADING_STATES,
        props<{ loadingState: SacState['loadingState'] }>()
    ),

    getSac: createAction(SacActionTypes.GET_SAC),
    getSacSuccess: createAction(
      SacActionTypes.GET_SAC_SUCCESS,
        props<{ sacInfo: SacState['sacInfo']}>()
    ),
    getSacFail: createAction(SacActionTypes.GET_SAC_FAIL),

    getProjectUnit: createAction(SacActionTypes.GET_PROJECT_UNIT),
    getProjectUnitSuccess: createAction(
      SacActionTypes.GET_PROJECT_UNIT_SUCCESS,
        props<{ sacFormList: SacState['sacFormList']}>()
    ),
    getProjectUnitFail: createAction(SacActionTypes.GET_PROJECT_UNIT_FAIL),

    getStatesSac: createAction(SacActionTypes.GET_STATES_SAC),
    getStatesSacSuccess: createAction(
      SacActionTypes.GET_STATES_SAC_SUCCESS,
        props<{ sacStates: SacState['sacStates']}>()
    ),
    getStatesSacFail: createAction(SacActionTypes.GET_STATES_SAC_FAIL),

    getAttetionsSac: createAction(SacActionTypes.GET_ATTETIONS_SAC),
    getAttetionsSacSuccess: createAction(
      SacActionTypes.GET_ATTETIONS_SAC_SUCCESS,
        props<{ sacAttetions: SacState['sacAttetions']}>()
    ),
    getAttetionsSacFail: createAction(SacActionTypes.GET_ATTETIONS_SAC_FAIL),



    createAttetions: createAction(SacActionTypes.CREATE_ATTETION,
        props<{ dataFormSac: DataFormSac }>()
      ),
    createAttetionsSuccess: createAction(
      SacActionTypes.CREATE_ATTETION_SUCCESS,
        props<{ newAttention: SacState['newAttention']}>()
    ),
    createAttetionsFail: createAction(SacActionTypes.CREATE_ATTETION_FAIL),


    createNote: createAction(SacActionTypes.CREATE_NOTE,
        props<{ createNote: CreateNoteForm }>()
      ),
    createNoteSuccess: createAction(
      SacActionTypes.CREATE_NOTE_SUCCESS,
        props<{ newNote: SacState['newNote']}>()
    ),
    createNoteFail: createAction(SacActionTypes.CREATE_NOTE_FAIL),




    getDetailAttention: createAction(SacActionTypes.GET_DETAIL_ATTETION,
    props<{idAttetion: string}>()),

   getDetailAttentionSuccess: createAction(
      SacActionTypes.GET_DETAIL_ATTETION_SUCCESS,
        props<{ detailAttetion: SacState['detailAttetion']}>()
    ),
    getDetailAttentionFail: createAction(SacActionTypes.GET_DETAIL_ATTETION_FAIL),

    getNotesFiles: createAction(SacActionTypes.GET_NOTES_FILES,
      props<{ idNotesFiles: string }>()
    ),
    getNotesFilesSuccess: createAction(
    SacActionTypes.GET_NOTES_FILES_SUCCESS,
      props<{ notesFiles: SacState['notesFiles']}>()
   ),
   getNotesFilesFail: createAction(SacActionTypes.GET_NOTES_FILES_FAIL),
};

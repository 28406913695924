/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { SacActions } from './sac.actions';
import { ResponseAttetionsDetail, ResponseAttetionsSac, ResponseCreateAttention, ResponseNotesFiles,
ResponseSac, ResponseSacForm, ResponseStatesSac } from '@shared/models/sac';


export interface SacState {
  loading: boolean;
  loadingState: boolean;
  sacInfo: ResponseSac | null;
  sacFormList: ResponseSacForm | null;
  sacStates: ResponseStatesSac | null;
  sacAttetions: ResponseAttetionsSac | null;
  detailAttetion: ResponseAttetionsDetail | null;
  newAttention: ResponseCreateAttention | null;
  newNote: ResponseCreateAttention | null;
  notesFiles: ResponseNotesFiles | null;

}

const initialState: SacState = {
  loading: false,
  loadingState: false,
  sacInfo:null,
  sacFormList: null,
  detailAttetion:null,
  sacStates: null,
  sacAttetions: null,
  newAttention: null,
  newNote: null,
  notesFiles: null,
};

export const SacReducer = createReducer(
  initialState,
  on(SacActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(SacActions.setLoadingState, (state, { loadingState }) => ({
    ...state,
    loadingState,
  })),
  on(SacActions.getSacSuccess, (state, { sacInfo }) => ({
    ...state,
    sacInfo
  })),
  on(SacActions.getProjectUnitSuccess, (state, { sacFormList }) => ({
    ...state,
    sacFormList
  })),
  on(SacActions.getStatesSacSuccess, (state, { sacStates }) => ({
    ...state,
    sacStates
  })),
  on(SacActions.getAttetionsSacSuccess, (state, { sacAttetions }) => ({
    ...state,
    sacAttetions
  })),
  on(SacActions.createAttetionsSuccess, (state, { newAttention }) => ({
    ...state,
    newAttention
  })),
  on(SacActions.createNoteSuccess, (state, { newNote }) => ({
    ...state,
    newNote
  })),
  on(SacActions.getDetailAttentionSuccess, (state, { detailAttetion }) => ({
    ...state,
    detailAttetion
  })),
  on(SacActions.getNotesFilesSuccess, (state, { notesFiles }) => ({
    ...state,
    notesFiles
  })),
);

/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';
import { TutorialState } from './tutorial.reducer';


enum TutorialActionTypes {
    SET_LOADING = '[TUTORIAL] Set loading',
    ONBOARDING_REQUEST = '[ONBOARDING] Onboarding request',
    ONBOARDING_RECEIVE_SUCCESS = '[ONBOARDING] Onboarding receive success',
    ONBOARDING_REQUEST_FAIL = '[ONBOARDING] Onboarding request fail',

    LEGALES_REQUEST = '[ONBOARDING] Onboarding request',
    LEGALES_REQUEST_SUCCESS = '[ONBOARDING] Onboarding request success',
    LEGALES_REQUEST_FAIL = '[ONBOARDING] Onboarding request fail',

    HOME_TUTORIAL_REQUEST = '[TUTORIAL] Home Tutorial request',
    HOME_TUTORIAL_REQUEST_SUCCESS = '[TUTORIAL] Home Tutorial request success',
    HOME_TUTORIAL_REQUEST_FAIL = '[TUTORIAL] Home Tutorial request fail',

    DETAIL_TUTORIAL_REQUEST = '[TUTORIAL] Detail Tutorial request',
    DETAIL_TUTORIAL_REQUEST_SUCCESS = '[TUTORIAL] Detail Tutorial request success',
    DETAIL_TUTORIAL_REQUEST_FAIL = '[TUTORIAL] Detail Tutorial request fail',

    TUTORIAL_FAQ_REQUEST = '[TUTORIAL] Tutorial Faq request',
    TUTORIAL_FAQ_REQUEST_SUCCESS = '[TUTORIAL] Tutorial Faq request success',
    TUTORIAL_FAQ_REQUEST_FAIL = '[TUTORIAL] Tutorial Faq request fail',
}
export const TutorialActions = {
    setLoading: createAction(
        TutorialActionTypes.SET_LOADING,
        props<{ loading: TutorialState['loading'] }>()
      ),
    onboardingRequest: createAction(TutorialActionTypes.ONBOARDING_REQUEST),
    onboardingRequestSuccess: createAction(
        TutorialActionTypes.ONBOARDING_RECEIVE_SUCCESS,
        props<{ onboarding: TutorialState['onboarding']}>()
    ),
    onboardingRequestFail: createAction(TutorialActionTypes.ONBOARDING_REQUEST_FAIL),

    getLegalesRequest: createAction(TutorialActionTypes.LEGALES_REQUEST),
    getLegalesRequestSuccess: createAction(
        TutorialActionTypes.LEGALES_REQUEST_SUCCESS,
        props<{ legalesData: TutorialState['legalesData']}>()
    ),
    getLegalesRequestFail: createAction(TutorialActionTypes.LEGALES_REQUEST_FAIL),

    /// Home Tutorial
    getHomeTutorialRequest: createAction(TutorialActionTypes.HOME_TUTORIAL_REQUEST),
    getHomeTutorialRequestSuccess: createAction(
        TutorialActionTypes.HOME_TUTORIAL_REQUEST_SUCCESS,
        props<{ homeTutorialData: TutorialState['homeTutorialData']}>()
    ),
    getHomeTutorialRequestFail: createAction(TutorialActionTypes.HOME_TUTORIAL_REQUEST_FAIL),


      //// Detail Tutorial
      getDetailTutorialRequest: createAction(TutorialActionTypes.DETAIL_TUTORIAL_REQUEST,
        props<{idDetailTuto: string}>()),

        getDetailTutorialRequestSuccess: createAction(
        TutorialActionTypes.DETAIL_TUTORIAL_REQUEST_SUCCESS,
            props<{ detailTutorial: TutorialState['detailTutorial']}>()
        ),
        getDetailTutorialRequestFail: createAction(TutorialActionTypes.DETAIL_TUTORIAL_REQUEST_FAIL),

        // Tutorial Faq
        getTutorialFaqRequest: createAction(TutorialActionTypes.TUTORIAL_FAQ_REQUEST),
        getTutorialFaqRequestSuccess: createAction(
            TutorialActionTypes.TUTORIAL_FAQ_REQUEST_SUCCESS,
            props<{ tutorialFaqData: TutorialState['tutorialFaqData']}>()
        ),
        getTutorialFaqRequestFail: createAction(TutorialActionTypes.TUTORIAL_FAQ_REQUEST_FAIL),
};

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectHome from './home.selectors';
import { HomeState } from './home.reducer';
import { HomeActions } from './home.actions';
import { IdataHeader } from '@shared/models/home';




@Injectable()
export class HomeFacade {
  readonly loading$ = this.store.select(selectHome.loading);
  readonly homeData$ = this.store.select(selectHome.homeData);
  readonly dataHeader$ = this.store.select(selectHome.dataHeader);
  readonly myContactList$ = this.store.select(selectHome.myContactList);
  readonly notificationsList$ = this.store.select(selectHome.notificationsList);
  readonly deleteNotification$ = this.store.select(selectHome.deleteNotification);
  readonly loadingNotifications$ = this.store.select(selectHome.loadingNotifications);

  constructor(
    private readonly store: Store<HomeState>,
  ) { }

  setLoading(loading: HomeState['loading']): void {
    this.store.dispatch(HomeActions.setLoading({ loading }));
  };
  setLoadingNotifications(loadingNotifications: HomeState['loadingNotifications']): void {
    this.store.dispatch(HomeActions.setLoadingNotifications({ loadingNotifications }));
  };


  homeRequest() {
    this.store.dispatch(HomeActions.homeRequest() );
  }
  getNotifications() {
    this.store.dispatch(HomeActions.getNotifications() );
  }
  deleteNotification(idNotification) {
    this.store.dispatch(HomeActions.deleteNotification({idNotification}) );
  }
  myContactsRequest() {
    this.store.dispatch(HomeActions.myContactsRequest() );
  }
  setDataHeader(dataHeader: IdataHeader) {
    this.store.dispatch(HomeActions.setDataHeader({dataHeader}) );
  }
  clearDataHeader(): void {
    this.store.dispatch(HomeActions.setDataHeader({ dataHeader: null }));
  }
  clearDataMyInformation(): void {
    this.store.dispatch(HomeActions.homeRequestSuccess({ homeData: null }));
  }
}

/* eslint-disable max-len */
import { Component, OnInit, Input } from '@angular/core';
import { PropertyItem, PropertyProcessItems } from '@shared/models/properties';
import { ActionSheetController } from '@ionic/angular';
import { IdataHeader } from '@shared/models/home';
import { HomeFacade } from '@store/home/home.facade';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { WasiFacade } from '@store/wasi/wasi.facade';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { OnboardingFacade } from '@store/onboarding/onboarding.facade';
import { DataOnboarding } from '@shared/models/onboarding';
@Component({
  selector: 'app-property-detail-one',
  templateUrl: './property-detail-one.component.html',
  styleUrls: ['./property-detail-one.component.scss'],
})
export class PropertyDetailOneComponent implements OnInit {
  @Input() propertyInfoOne: PropertyProcessItems;
  @Input() currentProperty: PropertyItem;
  setHeaderData: IdataHeader | null = null;
  readonly loadingB64$ = this.wasiFacade.loading$;
  readonly #subscriptions$ = new Subscription();
  onboardingData: DataOnboarding | null = null;
  constructor(
    private actionSheetCtrl: ActionSheetController,
    private homeFacade: HomeFacade,
    private socialSharing: SocialSharing,
    private wasiFacade: WasiFacade,
    private platform: Platform,
    private readonly onboardingFacade: OnboardingFacade,
  ) { }
  openZoomImage(image){
    const dataHeader = {
      ...this.setHeaderData,
      imageZoom:image
    };
    this.homeFacade.setDataHeader(dataHeader);
  }
  socialShare(image){
    const isIOS = this.platform.is('ios');
    const urlApp =  isIOS ? this.onboardingData.link_app_ios : this.onboardingData.link_app_android;
    const textShare = `${this.currentProperty.title} \r\n \r\n ${this.onboardingData.share_text}`;
    this.socialSharing.share(
      textShare,
      null,
      image,
      urlApp
    ).then(()=> {
      console.log('Successful share');
    }).catch((error)=> {
      console.log('Error sharing:', error);
    });
  }
  ngOnInit() {
    this.#subscriptions$.add(
      this.onboardingFacade.onboardingData$.subscribe(result => {
        if( result ){
          this.onboardingData = result.data;
        }
      })
    );
    this.homeFacade.dataHeader$.subscribe(result => {
      if(result){
        this.setHeaderData = result;
      }
    });

    this.#subscriptions$.add(
      this.wasiFacade.imageB64$.subscribe((result)=> {
        if(result){
          this.socialShare(result.data.image_base64);
        }else{

        }
      })
    );

  }

  getImageAndShare(url){
    this.wasiFacade.getImageB64(url);

  };
  async presentActionSheet(imageUrl) {
    const actionSheet = await this.actionSheetCtrl.create({
      header: 'Seleccione un opción',
      buttons: [
        {
          text: 'Ampliar plano',
          icon: 'resize-outline',
          handler: () => { this.openZoomImage(imageUrl);},
        },
        {
          text: 'Descargar plano',
          icon: 'download-outline',
          handler: () => { window.location.assign(imageUrl);},
        },
        {
          text: 'Compartir plano',
          icon: 'mail-outline',
          handler: () => { this.getImageAndShare(imageUrl);},
        },
      ],
    });

    await actionSheet.present();

  }

}

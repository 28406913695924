import { createFeatureSelector, createSelector } from '@ngrx/store';
import {  DOCUMENTS } from '@shared/static/store.static';
import { DocumentsState } from './documents.reducer';


export const selectDocuments = createFeatureSelector<DocumentsState>(DOCUMENTS);

export const loading = createSelector(
  selectDocuments,
  (state: DocumentsState) => state.loading
);

export const filesByCategory = createSelector(
  selectDocuments,
  (state: DocumentsState) => state.filesByCategory
);

export const responseCategory = createSelector(
  selectDocuments,
  (state: DocumentsState) => state.responseCategory
);





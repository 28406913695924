/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { IdataHeader } from '@shared/models/home';
import { HomeFacade } from '@store/home/home.facade';
@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss'],
})
export class ImageZoomComponent implements OnInit {
  @Input() dataHeader: IdataHeader | null = null;
  rotateFactor = 0;
  constructor(
    private homeFacade: HomeFacade,
  ) { }

  ngOnInit() {}
  closeZoom(){
    const dataHeader = {
      ...this.dataHeader,
      imageZoom:''
    };
    this.homeFacade.setDataHeader(dataHeader);
  }
}

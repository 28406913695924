import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { REFERRAL_PARTNER } from '@shared/static/store.static';
import { ReferralPartnerEffects } from './referral-partner.effects';
import { ReferralPartnerFacade } from './referral-partner.facade';
import { ReferralPartnerReducer } from './referral-partner.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(REFERRAL_PARTNER, ReferralPartnerReducer),
    EffectsModule.forFeature([ReferralPartnerEffects]),
  ],
  providers: [ReferralPartnerFacade]
})
export class ReferralPartnerModule { }

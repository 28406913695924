import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { SharedModule } from '@shared/shared.module';
import {RouterModule} from '@angular/router';
import { MyInformationPageModule } from './my-information/my-information.module';
import { DetailMyInformationComponent } from './my-information/pages/detail-my-information/detail-my-information.component';
import { ChangeMyPasswordComponent } from './my-information/pages/change-my-password/change-my-password.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ServerErrorComponent } from './server-error/server-error.component';

// import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// export const MaterialModules = [
//   MatFormFieldModule,
//   MatInputModule,
//   MatRadioModule,
//   MatButtonModule,
//   MatSelectModule,
//   MatDatepickerModule,
//   MatNativeDateModule,
//   MatCheckboxModule,
//   MatIconModule,
//   MatDialogModule,
//   MatSliderModule
// ];

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationDetailComponent,
    DetailMyInformationComponent,
    ChangeMyPasswordComponent,
    TermsAndConditionsComponent,
    ServerErrorComponent
  ],
  exports:[
    FormsModule,
    ReactiveFormsModule,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    MyInformationPageModule,
  ]
})
export class PagesModule { }

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { REFERRAL_PARTNER } from '@shared/static/store.static';
import { ReferralPartnerState } from './referral-partner.reducer';

export const selectReferralpartner = createFeatureSelector<ReferralPartnerState>(REFERRAL_PARTNER);

export const loading = createSelector(
  selectReferralpartner,
  (state: ReferralPartnerState) => state.loading
);

export const infoReferralPartner = createSelector(
  selectReferralpartner,
  (state: ReferralPartnerState) => state.infoReferralPartner
);

export const projectsOnSaleList = createSelector(
  selectReferralpartner,
  (state: ReferralPartnerState) => state.projectsOnSaleList
);

export const responseFormReferral = createSelector(
  selectReferralpartner,
  (state: ReferralPartnerState) => state.responseFormReferral
);

/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IlegalesResult, Ionboarding } from '@shared/models/onboarding';
import { environment } from 'src/environments/environment';


const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  constructor(
    private http: HttpClient,
  ) { }


  onboardingRequest() {
    return this.http.get<Ionboarding>(`${API_URL}/api/onboarding`);
  }
  getLegalesRequest() {
    return this.http.get<IlegalesResult>(`${API_URL}/api/legal`);
  }

}

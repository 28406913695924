/* eslint-disable @typescript-eslint/naming-convention */
import { createReducer, on } from '@ngrx/store';
import { IdataHeader, IHomeData, IMyContacts, ResponseDeleteNotification, ResultNotifications } from '@shared/models/home';
import { HomeActions } from './home.actions';



export interface HomeState {
  loading: boolean;
  loadingNotifications: boolean;
  homeData: IHomeData | null;
  dataHeader: IdataHeader | null;
  myContactList: IMyContacts | null;
  notificationsList: ResultNotifications | null;
  deleteNotification: ResponseDeleteNotification | null;

}

const initialState: HomeState = {
  loading: false,
  loadingNotifications: false,
  deleteNotification: null,
  homeData:null,
  dataHeader: null,
  myContactList: null,
  notificationsList: null
};

export const HomeReducer = createReducer(
  initialState,
  on(HomeActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
  on(HomeActions.setLoadingNotifications, (state, { loadingNotifications }) => ({
    ...state,
    loadingNotifications,
  })),
  on(HomeActions.homeRequestSuccess, (state, { homeData }) => ({
    ...state,
    homeData
  })),
  on(HomeActions.myContactsRequestSuccess, (state, { myContactList }) => ({
    ...state,
    myContactList
  })),
  on(HomeActions.getNotificationsSuccess, (state, { notificationsList }) => ({
    ...state,
    notificationsList
  })),
  on(HomeActions.deleteNotificationSuccess, (state, { deleteNotification }) => ({
    ...state,
    deleteNotification
  })),
  on(HomeActions.setDataHeader, (state, { dataHeader }) => ({
    ...state,
    dataHeader
  }))
);

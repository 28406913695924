import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as selectProperties from './properties.selectors';
import { PropertiesState } from './properties.reducer';
import { PropertiesActions } from './properties.actions';
import { ParamsCurrentProperty, ParamsProcess,
  RequestDeliveryHome, RequestFAQDelivery, RequestGuideDelivery, RequestLearnMoreDelivery, RequestLinkDelivery, RequestLocationDelivery,
   RequestStepsDelivery,
   SelectedProperty } from '@shared/models/properties';



@Injectable()
export class PropertiesFacade {
  readonly loading$ = this.store.select(selectProperties.loading);
  readonly propertiesList$ = this.store.select(selectProperties.propertiesList);

  readonly resultCurrentPropertyOne$ = this.store.select(selectProperties.resultCurrentPropertyOne);
  readonly resultCurrentPropertyTwo$ = this.store.select(selectProperties.resultCurrentPropertyTwo);


  readonly currentProcessList$ = this.store.select(selectProperties.currentProcessList);
  readonly selectedProperty$ = this.store.select(selectProperties.selectedProperty);
  readonly deliveryHomeResponse$ = this.store.select(selectProperties.deliveryHomeResponse);
  readonly locationDeliveryResponse$ = this.store.select(selectProperties.locationDeliveryResponse);
  readonly guideDeliveryResponse$ = this.store.select(selectProperties.guideDeliveryResponse);
  readonly stepsDeliveryResponse$ = this.store.select(selectProperties.stepsDeliveryResponse);
  readonly learnMoreDeliveryResponse$ = this.store.select(selectProperties.learnMoreDeliveryResponse);
  readonly faqDeliveryResponse$ = this.store.select(selectProperties.faqDeliveryResponse);
  readonly linkInterestResponse$ = this.store.select(selectProperties.linkInterestResponse);
  readonly propertiesDetail$ = this.store.select(selectProperties.propertiesDetail);
  readonly propertyfaqResponse$ = this.store.select(selectProperties.propertyfaqResponse);


  constructor(
    private readonly store: Store<PropertiesState>,
  ) { }

  setLoading(loading: PropertiesState['loading']): void {
    this.store.dispatch(PropertiesActions.setLoading({ loading }));
  };


  getProperties() {
    this.store.dispatch(PropertiesActions.getProperties());
  }

  getCurrentProcess(paramsProcess: ParamsProcess) {
    this.store.dispatch(PropertiesActions.getCurrentProcess({paramsProcess}));
  }
  getCurrentPropertyOne(paramsCurrentProperty: ParamsCurrentProperty) {
    this.store.dispatch(PropertiesActions.getCurrentPropertyOne({paramsCurrentProperty}));
  }
  getCurrentPropertyTwo(paramsCurrentProperty: ParamsCurrentProperty) {
    this.store.dispatch(PropertiesActions.getCurrentPropertyTwo({paramsCurrentProperty}));
  }
  setupSelectedProperty(selectedProperty: SelectedProperty) {
    this.store.dispatch(PropertiesActions.setupSelectedProperty({selectedProperty}));
  }
  cleanPropertyData(): void {
    this.store.dispatch(PropertiesActions.cleanPropertyData());
  }
  cleanSelectedProperty(): void {
    this.store.dispatch(PropertiesActions.setupSelectedProperty({ selectedProperty: null }));
  }
  getHomeDelivery(requestDeliveryHome: RequestDeliveryHome) {
    this.store.dispatch(PropertiesActions.getHomeDelivery({requestDeliveryHome}));
  }

  getLocationDelivery(requestLocationDelivery: RequestLocationDelivery) {
    this.store.dispatch(PropertiesActions.getLocationDelivery({requestLocationDelivery}));
  }

  getGuideDelivery(requestGuideDelivery: RequestGuideDelivery) {
    this.store.dispatch(PropertiesActions.getGuideDelivery({requestGuideDelivery}));
  }
  getStepsDelivery(requestStepsDelivery: RequestStepsDelivery) {
    this.store.dispatch(PropertiesActions.getStepsDelivery({requestStepsDelivery}));
  }
  getLearnMoreDelivery(requestLearnMoreDelivery: RequestLearnMoreDelivery) {
    this.store.dispatch(PropertiesActions.getLearnMoreDelivery({requestLearnMoreDelivery}));
  }
  getFaqDelivery(requestFAQDelivery: RequestFAQDelivery) {
    this.store.dispatch(PropertiesActions.getFaqDelivery({requestFAQDelivery}));
  }
  getLinksInterest(requestLinkDelivery: RequestLinkDelivery) {
    this.store.dispatch(PropertiesActions.getLinksInterest({requestLinkDelivery}));
  }
  getDetailProperties(proformaCode: string) {
    this.store.dispatch(PropertiesActions.getDetailProperties({proformaCode}));
  }
  getPropertyFaqRequest(){
    this.store.dispatch(PropertiesActions.getPropertyFaqRequest());
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataPropertyProcess, IResponsePropertyProcess, ProcessItem, StepsList } from '@shared/models/properties';

@Component({
  selector: 'app-steps-properties',
  templateUrl: './steps-properties.component.html',
  styleUrls: ['./steps-properties.component.scss'],
})
export class StepsPropertiesComponent implements OnInit {
  @Input() allDataProcess: DataPropertyProcess;
  @Input() processSelected: number;
  @Output() getInfoProcess = new EventEmitter();
  @Input() showButtonDelivery: boolean;
  processList: ProcessItem[];
  constructor() { }

  ngOnInit() {

  }

}

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';


import { TUTORIAL } from '@shared/static/store.static';
import { TutorialReducer } from './tutorial.reducer';
import { TutorialEffects } from './tutorial.effects';
import { TutorialFacade } from './tutorial.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(TUTORIAL, TutorialReducer),
    EffectsModule.forFeature([TutorialEffects]),
  ],
  providers: [TutorialFacade]
})
export class TutorialModule { }

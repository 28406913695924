import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SAC } from '@shared/static/store.static';
import { SacEffects } from './sac.effects';
import { SacFacade } from './sac.facade';
import { SacReducer } from './sac.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(SAC, SacReducer),
    EffectsModule.forFeature([SacEffects]),
  ],
  providers: [SacFacade]
})
export class SacModule { }

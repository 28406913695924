import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import { lastValueFrom,  } from 'rxjs';
import { switchMap,  tap} from 'rxjs/operators';
import { BenefitsFacade } from './benefits.facade';
import { BenefitsActions } from './benefits.actions';
import { BenefitsService } from '@shared/services/benefits/benefits.service';
import { AlertService } from '@shared/services/alert/alert.service';

@Injectable()
export class BenefitsEffects {

  readonly getBenefitsDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BenefitsActions.getBenefitsDetail),
      tap(() => this.benefitsFacade.setLoading(true)),
      switchMap(async ({idPost}) => {
        try {
          const benefitsDetail = await lastValueFrom(
            this.benefitsService.getBenefitsDetail(idPost)
          );
          if(benefitsDetail.status === 'success'){
            return BenefitsActions.getBenefitsDetailSuccess({ benefitsDetail });
          }else{
            return BenefitsActions.getBenefitsDetailFail();
          }
        } catch (e) {
          console.error(e);
          return BenefitsActions.getBenefitsDetailFail();
        }
      }),
      tap(() => this.benefitsFacade.setLoading(false))
    )
  );
  readonly setBenefitsFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BenefitsActions.setBenefitsFavorite),
      tap(() => this.benefitsFacade.setLoading(true)),
      switchMap(async ({settingFavorite}) => {
        try {
          const favoriteRequest = await lastValueFrom(
            this.benefitsService.setBenefitsFavorite(settingFavorite)
          );
          if(favoriteRequest.status === 'success'){
            const messageAdd = 'Se agregó con éxito a sus favoritos';
            const messageRemove = 'Se eliminó con éxito de sus favoritos';
            const dataMessage = {
              header:favoriteRequest.status,
              message: settingFavorite.state === 1 ? messageAdd : messageRemove,
            };
            this.alertService.presentToast(dataMessage);
            return BenefitsActions.setBenefitsFavoriteSuccess({ favoriteRequest });
          }else{
            return BenefitsActions.setBenefitsFavoriteFail();
          }
        } catch (e) {
          console.error(e);
          return BenefitsActions.setBenefitsFavoriteFail();
        }
      }),
      tap(() => this.benefitsFacade.setLoading(false))
    )
  );


  readonly getBenefitsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BenefitsActions.getBenefitsList),
      tap(() => this.benefitsFacade.setLoading(true)),
      switchMap(async ({params}) => {
        try {
          const benefitsList = await lastValueFrom(
            this.benefitsService.getBenefitsList(params)
          );
          if(benefitsList.status === 'success'){
            return BenefitsActions.getBenefitsListSuccess({ benefitsList });
          }else{
            return BenefitsActions.getBenefitsListFail();
          }
        } catch (e) {
          console.error(e);
          return BenefitsActions.getBenefitsListFail();
        }
      }),
      tap(() => this.benefitsFacade.setLoading(false))
    )
  );

  readonly getBenefitsCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BenefitsActions.getBenefitsCategories),
      tap(() => this.benefitsFacade.setLoadingCategory(true)),
      switchMap(async () => {
        try {
          const benefitsCategories = await lastValueFrom(
            this.benefitsService.getBenefitsCategories()
          );
          if(benefitsCategories.status === 'success'){
            return BenefitsActions.getBenefitsCategoriesSuccess({ benefitsCategories });
          }else{
            return BenefitsActions.getBenefitsCategoriesFail();
          }
        } catch (e) {
          console.error(e);
          return BenefitsActions.getBenefitsCategoriesFail();
        }
      }),
      tap(() => this.benefitsFacade.setLoadingCategory(false))
    )
  );



  constructor(
    private readonly actions$: Actions,
    private readonly benefitsFacade: BenefitsFacade,
    private readonly benefitsService: BenefitsService,
    private readonly alertService: AlertService,

  ) {}
}
